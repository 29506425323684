import React, {Component} from "react";
import {Padding} from "../components/Components";
import '../editor/barq/BarqEditor.css';
import Loading from "../components/Loading";
import BarqItem from "./BarqItem";
import ajax from "superagent";

class BarqList extends Component {


    constructor(props, context) {
        super(props, context);
        if (this.props.onEditBarq)
            this.editBarq = this.props.onEditBarq.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
        this.reloadCache = this.reloadCache.bind(this);
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    filterChanged(event) {
        const target = event.target;
        const value = target.value;
        this.props.onFilterChanged(value);
    }


    reloadCache() {
        ajax
            .post(this.props.app.server_url + '/admin/system/reloadCache')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("full CACHE újratöltve!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        const result = this.props.barqsResult;
        let contentView;
        if (result.barqs == null) {
            contentView =  <Loading loading={true}/>
        } else if (result.barqs.length === 0) {
            contentView = <Padding>Nincsenek ilyen események</Padding>
        } else {
            const barqsContent = result.barqs.map((barq, index) => {
                    return <BarqItem key={'barq-' + barq.barqId} barq={barq}
                                             app={this.props.app} index={index}
                                             editorMode={this.props.editorMode} onEditBarq={this.editBarq}
                                             selectionMode={this.props.selectionMode} selection={this.props.selection}
                                             onSelectionChanged={this.props.onSelectionChanged}
                    />
                }
            );
            contentView = <div className="ViewBarqCollection">{barqsContent}</div>
        }

        let filtersView = null;
        if (this.props.filters) {

            const options = this.props.filters.map((opt) => {
                return <option value={opt.key}>{opt.value}</option>
            })
            filtersView =
                <select name="filter" value={this.props.filter || ""} onChange={this.filterChanged}>{options}</select>
        }

        let batchesView = null;
        if (this.props.batches) {

            const options = this.props.batches.map((opt) => {
                return <option value={opt.key}>{opt.value}</option>
            })
            batchesView =
                <select name="batch" value={this.props.batch || ""} onChange={this.batchChanged}>{options}</select>
        }


        return <div>
            {(this.props.title || this.props.info) && <Padding>
                {this.props.title && <h1>{this.props.title}</h1>}
                <div className="BarqListMenu">
                    {this.props.onNewBarq &&
                    <span><button className="NewEventButton HighlightBackground" onClick={this.props.onNewBarq}>Új
                        esemény</button><button className="NewEventButton HighlightBackground" onClick={this.props.onBatchUploadBarq}>
                        Események tömeges feltöltése
                    </button><button className="NewEventButton HighlightBackground" onClick={this.props.onUploadImages}>
                        Kapcsolódó képek feltöltése
                    </button><button className="NewEventButton HighlightBackground" onClick={this.reloadCache}>
                        CACHE RELOAD
                    </button></span>}
                    {filtersView && <span>Szűrés: {filtersView}</span>}
                    {this.props.editorMode &&
                    <button className="NewEventButton HighlightBackground"
                            onClick={this.props.onSelectionModeChanged}>Kijelölés</button>}
                    {this.props.selectionMode && <span>Feladat: {batchesView}</span>}

                </div>
                {this.props.info && <p>{this.props.info}</p>}

            </Padding>}

            {contentView}
        </div>
    }
}

export default BarqList;