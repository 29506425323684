import React, {Component} from 'react';
import Container from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import {Link} from "react-router-dom";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import {Helmet} from "react-helmet";
import {translateMessage, TranslateMessage} from "../i18n/TranslateMessage";
import './PageChangePassword.css';
import ajax from "superagent";

class PageChangePassword extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {password: "", passwordRetry: "", errorMessage: null};
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }

    submit() {
        if (this.state.password.length < 6) this.setState({errorMessage: translateMessage("registration_failed_passsword_short")});
        else if (this.state.password !== this.state.passwordRetry) this.setState({errorMessage: translateMessage("registration_failed_passsword_missmatch")});
        else {
            //Submit password change
            this.changePassword();
        }
    }

    changePassword() {
        ajax
            .post(this.props.app.server_url + '/user/changePassword')
            .query({
                "client_build": "100", "platform": "react",
                "password": this.state.password,
                "lang": this.props.app.lang
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                window.location = this.props.app.app_url + "/login";
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Jelszóváltoztatás</title>
                <meta name="description" lang="hu" content={'barq.me jelszóváltoztatás'}/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <div className="PaddingDependsOnWidth PageChangePassword">
                    <h1><Link className="ColorLink" to={"/my-profile"}>Profilom</Link> &gt; <TranslateMessage
                        id="change_password_title"/></h1>
                    <p><TranslateMessage id="change_password_text"/></p>

                    <div className="ThemeBackground PageChangePasswordForm">
                        {this.state.errorMessage && <p style={{color: "red"}}>
                            {this.state.errorMessage}
                        </p>}
                        <input className="field ThemeSolidBackground" type="password" name="password"
                               value={this.state.password}
                               placeholder="Jelszó" onChange={this.onPropertyChanged}/>
                        <input className="field ThemeSolidBackground" type="password" name="passwordRetry"
                               value={this.state.passwordRetry}
                               placeholder="Jelszó ismét" onChange={this.onPropertyChanged}/>

                        <button className="button" onClick={this.submit}><TranslateMessage id="change_password_button"/>
                        </button>
                    </div>


                </div>
            </Container>
        </div>
    }
}

export default PageChangePassword;