import React, {Component} from "react";
import {Helmet} from "react-helmet";
import BarqMeCookies from "../BarqMeCookies";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import BarqMeTopbar from "../topbar/Topbar";
import Container from "../components/Components";
import "./PageShare.css";
class PageShare extends Component {

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Megosztás</title>
                <meta name="description" lang="hu" content={'barq.me megosztás'}/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <ArticlePageShare/>
            </Container>
        </div>


    }
}

class ArticlePageShare extends Component {
    render() {
        return <div className="PaddingDependsOnWidth">
            <h1>Üdvözöl a barq.me</h1>
            <p>
                A Barq.me egy új mobiltelefonos applikáció iphone-ra és android-ra, melyben ingyenesen kereshet bárki
                budapesti eseményeket. A koncepció természetesen nem új, de mégis van néhány fontos dolog, ami
                megkülönböztet bennünket a többiektől:
                <ul>
                    <li>nálunk ingyenes és helyszíni jegyvásárlásos események is fent vannak</li>
                    <li>mi csak elszalasztható programokat gyűjtünk…</li>
                    <li>sőt az egyik legfontosabb célunk, hogy a felhasználóink nem maradjanak le az őket érdeklő
                        koncertekről, előadásokról, kiállításokról stb.
                    </li>
                    <li>vagyis nem csak keresni lehet az események között, de a Barq vakkant, ha érdekes program jelenik
                        meg.
                    </li>
                </ul>
                Még csak most indultunk, de már most is folyamatosan van közel 3000 egyedi program az adatbázisunkban.
                Azt szeretnénk, hogy ez tovább nőjön és olyan rétegeknek is nyújthassunk információt, akikre a nagy
                jegyárusítók vagy weboldalak nem figyelnek.
            </p>
            <h1>Próbáld ki a Barq.me-t</h1>
            <p>
                A barq.me elérhető az Apple Store és Google Play áruházakban vagy látogasd meg weboldalunkat:
            </p>
            <p className="ArticlePageShareImages">
                <a href="https://apps.apple.com/us/app/barq-me/id1577921290">
                    <div className="ArticlePageShareImage ArticlePageShareImageIos" title="Töltsd le ios-re"/>
                </a>
                <br/>
                <a href="https://play.google.com/store/apps/details?id=me.barq.android">
                    <div className="ArticlePageShareImage ArticlePageShareImageAndroid" title="Töltsd le androidra"/>
                </a>
                <br/>
                <a href="https://barq.me">
                    <div className="ArticlePageShareImage ArticlePageShareImageWebpage"
                         title="Böngészd a weboldalunkat"/>
                </a>
            </p>
        </div>
    }
}

export default PageShare;