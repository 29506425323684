import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import './LocationData.css';
import {Padding} from "../../components/Components";
import ajax from "superagent";
import PageArticleEditor from "../../article/ArticleEditor";
import {PromoterTabFooter, PromoterTabTitle} from "../EditorComponent";
import {MediaSelectorComponent} from "../media/MediaEditor";
import queryString from "query-string";
import Loading from "../../components/Loading";
import Cancellations from "./Cancellations";

class LocationDataAdmin extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const locationId = values["location"] || null;

        if (locationId) {
            this.state = {tab: "editor", location: {locationId: locationId}};
        } else {
            this.state = {tab: "list", location: null};
        }
        this.changeTab = this.changeTab.bind(this);
        this.newLocation = this.newLocation.bind(this);
        this.editLocation = this.editLocation.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeTab(tab, location) {

        this.setState({tab: "loading"}, () => {
            let hash = '#tab=location_admin';
            if (location) {
                hash = '#tab=location_admin&location=' + location.locationId;
            }

            if (window.history.pushState) {
                window.history.pushState(null, null, hash);
            } else {
                window.location.hash = '#' + hash;
            }
            this.setState({tab: tab}, () => {
            });
        });


        this.setState({tab: tab, location: location}, () => {
        });
    }

    closeEditor() {
        this.changeTab("list");
    }


    editLocation(location) {
        this.changeTab("editor", location)
    }

    newLocation() {
        this.setState({tab: "editor", location: {locationId: null}}, () => {
            this.changeTab("editor");
        })

    }


    render() {
        if (this.state.tab === "list") {
            return <LocationDataAdminList onNewLocation={this.newLocation} onEditLocation={this.editLocation}
                                          editorMode={this.props.editorMode} user={this.props.user}
                                          app={this.props.app}/>
        } else if (this.state.tab === "editor") {
            return <LocationDataAdminEditor location={this.state.location} onCancel={this.closeEditor}
                                            editorMode={this.props.editorMode} user={this.props.user}
                                            app={this.props.app}/>
        }
    }
}


class LocationDataAdminList extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {groups: null, searchText: "", searchResult: null};
        this.newLocation = this.props.onNewLocation.bind(this);
        this.editLocation = this.props.onEditLocation.bind(this);
        this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
    }


    componentDidMount() {
        this.downloadLocations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    downloadLocations() {
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/list')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({groups: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    searchLocations(searchText) {
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/search')
            .query({"platform": "react", search: searchText})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({searchResult: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onSearchTextChanged(event) {
        const target = event.target;
        const value = target.value;
        this.setState({searchText: value}, () => {
            if (this.state.searchText.length >= 3) {
                this.searchLocations(this.state.searchText);
            }
        })
    }

    render() {
        const result = this.state.searchResult;
        const groups = this.state.groups;
        if (groups != null) {
            return <div>
                <PromoterTabTitle title="Helyszín adminisztrátori felület"/>
                <div className="LocationDataAdminListGroup">
                    <Padding>
                        <button onClick={this.newLocation} className="grayButton">Új helyszín hozzáadása</button>
                        <h2>Keresés</h2>
                        <input className="Field" type="text" placeholder="Írj be legalább 3 karaktert!"
                               onChange={this.onSearchTextChanged} value={this.state.searchText}/>
                        {result == null && <div>Keresés itt.</div>}
                        {(result != null && result.length === 0) && <div>Nincs eredmény.</div>}
                        {(result != null && result.length > 0) &&
                        <LocationGroupItems locations={result} onEditLocation={this.editLocation}
                                            app={this.props.app}/>}
                    </Padding>
                </div>

                {this.state.groups.map((group) => {
                    return <div key={group.name} className="LocationDataAdminListGroup">
                        <Padding>
                            <h2>{group.name}</h2>
                            <LocationGroupItems locations={group.locations} onEditLocation={this.editLocation}
                                                app={this.props.app}/>
                        </Padding>
                    </div>
                })}

            </div>
        } else {
            return <div>Nincsenek ilyen helyszínek</div>
        }

    }


}

class LocationGroupItems extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {groups: null};
        this.editLocation = this.props.onEditLocation.bind(this);
    }

    render() {
        const locations = this.props.locations;
        return <div>
            {locations.map((location, i) => {
                return <div key={"location_elem" + i + "-" + location.locationId}
                            className={"Row " + ((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}>
                    <div className="Name">{location.name}</div>
                    <button className="EditButton barqButton"
                            onClick={() => this.editLocation(location)}>Szerk.
                    </button>
                </div>
            })}
        </div>
    }
}


class LocationDataAdminEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {location: null};
        this.updateArticle = this.updateArticle.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.onAlternativeNameChanged = this.onAlternativeNameChanged.bind(this);
        this.deleteAlternativeName = this.deleteAlternativeName.bind(this);
        this.addAlternativeName = this.addAlternativeName.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.downloadLocation();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadLocation() {
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/get')
            .query({"platform": "react", locationId: this.props.location.locationId})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({location: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    save(event) {
        const location = this.state.location;
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/save')
            .query({...{"platform": "react"}})
            .send(location)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({location: res.body});
                alert("MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        event.preventDefault();
    }

    updateArticle(article) {
        const location = this.state.location;
        location.article = article;
        this.setState({location: location})
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const location = {...this.state.location, ...{[name]: value}}
        this.setState({location: location});
    }

    onAlternativeNameChanged(index, event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const location = this.state.location;
        location.alternativeNames[index][name] = value;
        this.setState({location: location});
    }

    deleteAlternativeName(index) {
        const location = this.state.location;
        let alternatives = location.alternativeNames;
        alternatives.splice(index, 1)
        location.alternativeNames = alternatives;
        this.setState({location: location});
    }

    addAlternativeName() {
        const location = this.state.location;
        let alternatives = location.alternativeNames;
        alternatives.splice(alternatives.length, 0, {name: "", visible: false, room: false, add: true})
        location.alternativeNames = alternatives;
        this.setState({location: location});
    }


    render() {
        const location = this.state.location;
        if (location != null) {
            return <div>
                <Helmet>
                    <title>barq.me | {location?.name}</title>
                    <meta name="description" lang="hu" content="Promóter oldal"/>
                </Helmet>
                <PromoterTabTitle title={"Helyszín: " + (location?.name || "Névtelen")}/>

                {!this.props.editorMode && <div>
                    {location.imageDoubleHeaderUrl && <div className="PromoterImageDoubleHeader"
                                                           style={{backgroundImage: 'url(' + location.imageDoubleHeaderUrl + ")"}}>
                    </div>}
                    {location.imageHeaderUrl &&
                    <div className="PromoterImageHeader"
                         style={{backgroundImage: 'url(' + location.imageHeaderUrl + ")"}}>
                    </div>}


                    <div style={{clear: 'both'}}/>

                    {location.imageUrl1 &&
                    <img className="LocationImage" src={location.imageUrl1} alt={location.name + " kép 1"}/>}
                    {location.imageUrl2 &&
                    <img className="LocationImage" src={location.imageUrl2} alt={location.name + " kép 2"}/>}
                    {location.imageUrl3 &&
                    <img className="LocationImage" src={location.imageUrl3} alt={location.name + " kép 3"}/>}
                    {location.imageUrl4 &&
                    <img className="LocationImage" src={location.imageUrl4} alt={location.name + " kép 4"}/>}

                    <Padding>
                        <div style={{clear: 'both'}}/>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Elérhetőség:</div>
                            <div
                                className="DisplayRowValue">{location.postalCode}, {location.city} {location.address}</div>
                        </div>
                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">GPS:</div>
                            <div className="DisplayRowValue">({location.latitude}, {location.longitude})</div>
                        </div>


                        <div style={{clear: 'both'}}/>


                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Weboldal:</div>
                            <div className="DisplayRowValue">{location.web || "----"}</div>
                        </div>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Facebook:</div>
                            <div className="DisplayRowValue">{location.facebook || "----"}</div>
                        </div>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Telefonszám:</div>
                            <div className="DisplayRowValue">{location.phone || "----"}</div>
                        </div>
                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Email:</div>
                            <div className="DisplayRowValue">{location.email || "----"}</div>
                        </div>
                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Google webcím:</div>
                            <div className="DisplayRowValue">{location.googleUrl || "----"}</div>
                        </div>
                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Google Helyek azonosító:</div>
                            <div className="DisplayRowValue">{location.googlePlaceId || "----"}</div>
                        </div>

                    </Padding>
                </div>}


                <Padding>
                    {this.props.editorMode && <div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_name">Megnevezés</label> <input
                            id="form_name" type="text" name="name"
                            value={location.name || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div style={{clear: 'both'}}/>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (desktop):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderDesktop"
                                                    value={location.imageHeaderDesktop} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (tablet):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderTablet"
                                                    value={location.imageHeaderTablet} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (mobile):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderMobile"
                                                    value={location.imageHeaderMobile} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_title_on_offer">Cím megjelenítése ajánlásoknál:</label>

                            <select id="form_title_on_offer" name="titleOnOffer" value={location.titleOnOffer}
                                    onChange={this.onPropertyChanged}>
                                <option value={false}>Nem</option>
                                <option value={true}>Igen</option>
                            </select>
                        </div>
                        <div style={{clear: 'both'}}/>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image1">Kép 1:</label>
                            <MediaSelectorComponent id="form_image1" name="imageUrl1" value={location.imageUrl1}
                                                    onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image2">Kép 2:</label>
                            <MediaSelectorComponent id="form_image2" name="imageUrl2" value={location.imageUrl2}
                                                    onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image3">Kép 3:</label>
                            <MediaSelectorComponent id="form_image3" name="imageUrl3" value={location.imageUrl3}
                                                    onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image4">Kép 4:</label>
                            <MediaSelectorComponent id="form_image4" name="imageUrl4" value={location.imageUrl4}
                                                    onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div style={{clear: 'both'}}/>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_zip">Irányítószám:</label> <input
                            id="form_zip" type="text" name="postalCode"
                            value={location.postalCode || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_city">Város:</label> <input
                            id="form_city" type="text" name="city"
                            value={location.city || ""} onChange={this.onPropertyChanged}/>
                        </div>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_address">Cím:</label> <input
                            id="form_address" type="text" name="address"
                            value={location.address || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_latitude">GPS Latitude:</label> <input
                            id="form_latitude" type="text" name="latitude"
                            value={location.latitude || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_longitude">GPS Longitude:</label> <input
                            id="form_longitude" type="text" name="longitude"
                            value={location.longitude || ""} onChange={this.onPropertyChanged}/>
                        </div>

                        <div style={{clear: 'both'}}/>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_web">Weboldal:</label> <input
                            id="form_web" type="text" name="web"
                            value={location.web || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_facebook">Facebook:</label> <input
                            id="form_facebook" type="text" name="facebook"
                            value={location.facebook || ""} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_phone">Telefonszám:</label> <input
                            id="form_phone" type="text" name="phone"
                            value={location.phone} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_email">Email:</label> <input
                            id="form_email" type="text" name="email"
                            value={location.email || ""} onChange={this.onPropertyChanged}/>
                        </div>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_google_url">Google webcím:</label> <input
                            id="form_google_url" type="text" name="googleUrl"
                            value={location.googleUrl || ""} onChange={this.onPropertyChanged}/>
                        </div>

                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_google_place_id">Google Helyek azonosító:</label> <input
                            id="form_google_place_id" type="text" name="googlePlaceId"
                            value={location.googlePlaceId || ""} onChange={this.onPropertyChanged}/>
                        </div>
                    </div>}
                    <h2>Alternatív elnevezések</h2>
                    <p>Ay alternatív elnevezések fontosak amikor a rendszer különbböző forrásból gyűjti össze az
                        eseményeket.
                        Különböző források esetében a helyszín megnevezése eltérhet. A megnevezés lehet helyes
                        alternatíva, de
                        a más rendszerek által helytelenül kezelt megnevezés is.</p>
                    <p>A már mentett nevek nem módosíthatók, a rendszer által létrehozott nevek nem törölhetők.</p>
                    {location.alternativeNames.map((alt, i) => {
                        return <div
                            className={"AlternativeName " + ((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}>
                            <div className="Col Col1">{(!this.props.editorMode || !alt.add) && alt.name}
                                {(this.props.editorMode && alt.add) &&
                                <input className="Field" type="text" name="name" value={alt.name}
                                       onChange={(e) => this.onAlternativeNameChanged(i, e)}/>}
                            </div>
                            <div className="Col Col2">{this.props.editorMode &&
                            <input type="checkbox" name="visible" checked={alt.visible}
                                   onChange={(e) => this.onAlternativeNameChanged(i, e)}/>}&nbsp;
                            </div>
                            <div className="Col Col3">{this.props.editorMode &&
                            <input type="checkbox" name="room" checked={alt.room}
                                   onChange={(e) => this.onAlternativeNameChanged(i, e)}/>}&nbsp;
                            </div>
                            <div className="Col Col4">
                                {(this.props.editorMode && !alt.protect) &&
                                <button onClick={() => this.deleteAlternativeName(i)}>Töröl</button>}
                                &nbsp;
                            </div>
                        </div>
                    })}
                    {this.props.editorMode && <div style={{paddingTop: "20px"}}>
                        <button className="grayButton" onClick={this.addAlternativeName}>Új név hozzáadása</button>
                    </div>}





                    <Cancellations app={this.props.app} user={this.props.user} location={location}/>







                    <h2>Bemutatkozás</h2>
                </Padding>

                <PageArticleEditor article={location.article} onArticleChanged={this.updateArticle}
                                   app={this.props.app} user={this.props.user} editorMode={this.props.editorMode}
                                   hideArticleData={true}/>
                <Padding>
                    <PromoterTabFooter onSave={this.save} editorMode={this.props.editorMode}/>
                </Padding>

            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

export default LocationDataAdmin;