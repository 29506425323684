import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import {Link} from "react-router-dom";

class TagsSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {data: null}
    }

    componentDidMount() {
        this.downloadTags();
    }


    downloadTags() {
        ajax
            .post(this.props.app.server_url + '/section/get')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": this.props.section.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                // alert(JSON.stringify(res.body));
                this.setState({data: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        if (this.state.data != null) {
            const tags = this.state.data.tags.tags.map((tag) =>
                <TagsSectionItem key={'tag' + tag.tagId} tag={tag.tag} display={tag.display}
                                 app={this.props.app}/>
            );
            return (
                <div className="PaddingDependsOnWidth">
                    <div className="Section ModernSection SectionWidthContainer BarqboardTagListContainer">
                        <h2 className="SectionTitle">{this.state.data.title}</h2>
                        <div className="BarqboardTagList ThemeBackground">
                            {tags}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null
        }

    }
}


class TagsSectionItem extends Component {

    render() {
        return <Link className="BarqboardTag ThemeSolidBackground" to={'/search/SEARCH:' + this.props.tag}>
            {this.props.display}
        </Link>
    }

}


export default TagsSection;
export {TagsSectionItem};