import React, {Component} from "react";
import './Search.css';
import {Padding} from "../components/Components";
import ajax from "superagent";

class DownloadRecommendations extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {recommendations: {groups: []}}
    }

    componentDidMount() {
        this.downloadRecommendations();
    }

    downloadRecommendations() {
        ajax
            .post(this.props.app.server_url + '/search/'+this.props.action)
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({recommendations: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }

    render() {
        if (this.state.recommendations.groups.length > 0) {
            return <ViewRecommendationGroups groups={this.state.recommendations.groups}
                                             onTagSubmit={this.props.onTagSubmit}
                                             onSearchTextChanged={this.handleSearchTextChange}/>
        } else {
            return null;
        }
    }
}

class DownloadLocationRecommendations extends Component {

    render() {
        return <DownloadRecommendations action="searchLocationRecommendations" app={this.props.app} user={this.props.user}
                                        onTagSubmit={this.props.onTagSubmit}/>
    }
}

class DownloadCategoryRecommendations extends Component {

    render() {
        return <DownloadRecommendations action="searchCategoryRecommendations" app={this.props.app} user={this.props.user}
                                        onTagSubmit={this.props.onTagSubmit}/>
    }
}

class DownloadTagRecommendations extends Component {

    render() {
        return <DownloadRecommendations action="searchRecommendations" app={this.props.app} user={this.props.user}
                                                onTagSubmit={this.props.onTagSubmit}/>
    }
}

class ViewRecommendationGroups extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {}
    }


    render() {
        const groups = this.props.groups;
        const groupsContent = groups.map((group, i) =>
            <div key={'group-' + i + "-" + group.name} className="ViewRecommendationGroup">
                <h2>{group.name}</h2>
                <div><ViewRecommendationItems group={group} onTagSubmit={this.props.onTagSubmit}
                                              onSearchTextChanged={this.props.onSearchTextChanged}/></div>
            </div>
        );
        return <Padding>
            {groupsContent}
        </Padding>
    }
}

class ViewRecommendationItems extends Component {

    constructor(props, context) {
        super(props, context);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {}
    }

    handleSearch(searchText, e) {
        if (this.props.onTagSubmit) this.props.onTagSubmit(searchText, e)
        if (this.props.onSearchTextChanged) this.props.onSearchTextChanged("SEARCH:" + searchText);
        e.preventDefault();
    }

    render() {
        const group = this.props.group;
        const items = group.items;
        const itemsContent = items.map((item) =>
            <div key={'item-' + group.name + '-' + item.text} className="ViewRecommendationItem">
                <button
                    onClick={(e) => this.handleSearch(item.text, e)}>{item.name}{(item.numberOfBarqs != null && " (" + item.numberOfBarqs + ")") || ""}</button>
            </div>
        );
        return <div className="ViewRecommendationItems">
            {itemsContent}
        </div>
    }
}

export {DownloadLocationRecommendations, DownloadCategoryRecommendations, DownloadTagRecommendations};