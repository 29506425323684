//TranslateMessage: completed

import React, {Component} from "react";
import ajax from "superagent";
import "./HistoryDialog.css"
import ModernDialog from "../popup/ModernDialog";
import {TranslateMessage, translateMessage} from "../i18n/TranslateMessage";

class HistoryDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {groups: null, history: null};
        this.selectHistory = this.selectHistory.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.display != null && prevProps.display !== this.props.display) {
            this.downloadHistory();
        }
    }


    downloadHistory() {
        ajax
            .post(this.props.app.server_url + '/search/searchRecommendations')
            .query({"client_build": "100", "platform": "react", "lang": this.props.app.lang, "limit": 100})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({
                    groups: res.body.groups
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    selectHistory(history) {
        this.setState({history: history});
    }

    render() {

        if (this.props.display) {

            if(this.state.groups?.length>0) {
                const items = this.state.groups[0].items;
                const historyView = items.map((history, index) => {
                            return <HistoryElement key={'history-' + index} history={history} onSelect={this.props.selectHistory}
                                                 app={this.props.app} index={index}/>
                        }
                    );
                return <ModernDialog title={translateMessage("profile_myprofile_history_search")} level={1}
                                          onClose={this.props.closePopup}
                                          extraClass="ModernDialogSizeWide ThemeBackground">
                    <div className="HistoryItemContainer">
                        {historyView}
                    </div>

                </ModernDialog>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

class HistoryElement extends Component {

    render() {
        const history = this.props.history;
        return <div className="HistoryItem ThemeBackground">
                <button onClick={(e) => this.props.onSelect(history)}
                        className="HistoryButton ThemeBackground ThemeColor">{history.name}</button>
            </div>
    }


}

export default HistoryDialog;