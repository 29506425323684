import React, {Component} from "react";
import ajax from "superagent";
import PubSub from "pubsub-js";
import { toast } from 'react-toastify';

class SubscriptionAction extends Component {


    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
    }

    componentDidMount() {
        this.subscribe();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    subscribe() {
        const name = null; //this.props.name; //Hide name to auto regenerate. This is necessary to prevent saving subscription with name "No result found ..."
        const text = this.props.text;
        ajax
            .post(this.props.app.server_url + '/subscription/subscribe')
            .query({"platform": "react", name: name,
                "lang": this.props.app.lang,
                text: text})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Sikeresen feliratkoztál. Jó szórakozást a programokhoz!", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                PubSub.publish("SUBSCRIPTION_SUBSCRIBED", res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        return null
    }


}

export default SubscriptionAction;