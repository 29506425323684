import React, {Component} from "react";
import ajax from "superagent";
import queryString from "query-string";
import {ViewArticlePanel, ViewContentArticle, ViewHeaderPanel} from "../article/Article";
import Loading from "../components/Loading";
import PubSub from "pubsub-js";
import "./ContentTabbed.css";
import SearchPanel from "../search/SearchPanel";
import {Link} from "react-router-dom";
import TabBarqs from "./TabBarqs";

class ContentTabbed extends Component {


    constructor(props, context) {
        super(props, context);
        this.showMoreButtonTapped = this.showMoreButtonTapped.bind(this);
        this.subscriptionChanged = this.subscriptionChanged.bind(this);
        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        const values = queryString.parse(window.location.hash);
        const tab = values["tab"] || "0";
        const link = window.location.href.split('#')[0];
        const baseLink = "/search" + link.split("/search")[1];

        const q = this.props.query;
        const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
        this.state = {
            searchText: searchText,
            tab: parseInt(tab),
            loading: false,
            latestPage: -1,
            showSearch: true,
            baseLink: baseLink,
            content: this.props.content
        }
    }


    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        PubSub.subscribe('SUBSCRIPTION_SUBSCRIBED', this.subscriptionChanged);
        PubSub.subscribe('SUBSCRIPTION_UNSUBSCRIBED', this.subscriptionChanged);
        if (this.props.content == null)
            this.downloadSearchResult();

    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
        PubSub.unsubscribe('SUBSCRIPTION_SUBSCRIBED');
        PubSub.unsubscribe('SUBSCRIPTION_UNSUBSCRIBED');
    }


    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }


    trackScrolling = () => {
        const wrappedElement = document.getElementById('BarqMeContentContainer');
        if (this.isBottom(wrappedElement)) {
            this.showMoreButtonTapped();
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        const values = queryString.parse(window.location.hash);
        const tab = parseInt(values["tab"] || "0");
        const link = window.location.href.split('#')[0];
        const baseLink = "/search" + link.split("/search")[1];
        if (prevProps.query !== this.props.query) {
            const q = this.props.query;
            const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
            this.handleSearchChange(searchText);
        } else if (prevState.tab !== tab || prevState.baseLink !== baseLink) {
            this.setState({tab: tab, baseLink: baseLink});
        }
    }


    subscriptionChanged(msg, action) {
        if (this.state.content.settings.searchData.fullText === action.settings.searchData.fullText) {
            const content = this.state.content;
            content.settings = action.settings;
            this.setState({content: content})
        }
    }


    downloadSearchResult(page) {
        const limit = 50;
        const latestPage = this.state.latestPage || -1;
        if (page == null || page > latestPage) {
            this.setState({loading: true, latestPage: page}, (e) => {
                const q = this.props.query;
                ajax
                    .post(this.props.app.server_url + '/barqboard/content')
                    .query({
                        client_build: "100",
                        platform: "react",
                        lang: this.props.app.lang,
                        page: page,
                        limit: limit,
                        text: q,
                        sid: this.state.content?.sid
                    })
                    .set('Authorization', 'Bearer ' + this.props.app.access_token)
                    .accept('application/json')
                    .then(res => {
                        console.log(res.body);
                        if (page == null || page === 0) {
                            this.setState({content: res.body, loading: false}, () => {
                                window.scrollTo(0, 0);
                            });
                        } else {
                            const actualContent = this.state.content;
                            const newContent = res.body;
                            if (newContent.result.page !== 0 && newContent.result.barqs.length > 0) {
                                let barqs = [];
                                barqs = barqs.concat(actualContent.result.barqs);
                                barqs = barqs.concat(newContent.result.barqs);
                                newContent.result.barqs = barqs;
                                newContent.result.count = actualContent.result.count + newContent.result.count;
                                newContent.result.fullCount = actualContent.result.fullCount + newContent.result.fullCount;
                                this.setState({content: newContent, loading: false});
                            }
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                        throw err;
                    });
            });
        }
    }

    handleSearchChange(searchText) {
        const q = "SEARCH:" + searchText;
        this.props.history.push("/search/" + q);
        this.setState({q: q, searchText: null, content: null}, () => {
            this.downloadSearchResult();
        });
    }

    handleSearchTextChangedEvent(msg, searchText) {
        this.handleSearchTextChange(searchText);
    }

    showMoreButtonTapped(event) {
        const content = this.state.content;
        if (content && content.settings.singleSection) {
            const page = content?.result?.page + 1;
            if (page < content.result.pageAll) {
                this.downloadSearchResult(page);
                if (event) event.preventDefault();
            }
        }
    }

    linkRef = React.createRef();

    onSubmitSearch(text) {
        this.handleSearchChange(text);
    }

    render() {
        const content = this.state.content;
        if (content) {

            const searchPanel = ((content.settings.mode === "search" || content.settings.mode === "subscription" || content.settings.mode === "festival") && this.state.showSearch) &&
                <SearchPanel mode={!content.result.fullCount > 0 && content.settings.singleSection ? "full" : "compact"}
                             app={this.props.app}
                             user={this.props.user}
                             form={content.form}
                             text={content.settings.searchData.text}
                             dateAfter={content.settings.searchData.filter.dateAfter}
                             dateBefore={content.settings.searchData.filter.dateBefore}
                             onSearchTextChanged={this.props.onSearchTextChanged}
                             onSubmitSearch={this.onSubmitSearch}/>


            let article = content.details.article;
            if (this.state.tab > 0) article = content.details.tabs[this.state.tab - 1];

            let filterPlace = "header";

            return <div>
                {searchPanel}
                <ViewContentArticle app={this.props.app} user={this.props.user} article={article} filterPlace={filterPlace}
                                    tabBaseLink={this.state.baseLink} tabSelected={this.state.tab}/>


                {this.state.tab === 0 && <TabBarqs app={this.props.app} user={this.props.user}
                                                   query={this.props.query}
                                                   content={content}
                                                   settings={content.settings}
                                                   showMoreButtonTapped={this.showMoreButtonTapped}/>}
            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

export default ContentTabbed;
