import React, {Component} from 'react';
import './PageEditor.css';
import {Padding} from "../components/Components";


class PageEditorNotAvailable extends Component {

    render() {
        return <Padding>
            <h1>Promóter csak regisztrált felhasználó lehet.</h1>
            <p>Promóter csak regisztrált felhasználó lehet. Ha még nem regisztráltál, itt megteheted. Ha már
                regisztráltál,
                itt beléphetsz.
            </p>
        </Padding>
    }
}

export default PageEditorNotAvailable;