import React, {Component} from 'react';
import './PageEditor.css';
import {Padding} from "../components/Components";
import ajax from "superagent";


class PageEditorRegistration extends Component {

    constructor(props, context) {
        super(props, context);
        this.register = this.register.bind(this);
        this.state = {promoter: {name: "", type: "events"}};
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    register(event) {
        const promoter = this.state.promoter;
        ajax
            .post(this.props.app.server_url + '/editor/promoter/register')
            .query({...{"platform": "react"}, ...promoter})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({promoter: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const promoter = this.state.promoter;
        if (name === "name") promoter.name = value
        if (name === "type" && target.checked) promoter.type = value
        this.setState({promoter: promoter}, () => {

        });
        // event.preventDefault();
    }


    render() {
        return <Padding>
            <h1>Promóter regisztráció</h1>

            <p>Promóterként eseményeket tölthetsz fel a barq.me rendszerbe.</p>
            <p>Promóterként saját oldallal rendelkezhetsz, ahova tartalmat tölthtsz fel.</p>
            <p>Az általad feltöltött eseményeket csoportokba szervezheted.</p>
            <p>Az általad feltöltött eseményekből fesztivált hozhatsz létre.</p>
            <p>Látod, hogy az általad feltöltött események után hányan érdeklődnek, hányan akarnak elmenni,
                szeretik vagy nem szeretik.</p>

            <h2>Első lépés</h2>
            <p>Első lépésként válaszd ki, hogy milyen típusú promóter szeretnél lenni.</p>
            <p>Az első lépés végeként értesülünk a regisztrációs szándékodról és ha nincs akadélya, akkor
                megkapod a promóter jogokat.</p>


            <input name="name" value={this.state.promoter.name} onChange={this.handleInputChange}/>
            <ul>
                <li><input id="radio_events" name="type" type="radio" value="events"
                           checked={this.state.promoter.type === 'events'} onChange={this.handleInputChange}/> <label
                    htmlFor="radio_events">Eseményeket szeretnék megosztani - Lehetőséged van esményeket
                    feltölteni a barq.me
                    rendszerbe. A feltöltött eseményekből listákat és fesztiválokat készíthetsz.</label>
                </li>
                <li><input id="radio_location" name="type" type="radio" value="location"
                           checked={this.state.promoter.type === 'location'} onChange={this.handleInputChange}/> <label
                    htmlFor="radio_location">Egy helyszín képviselője vagyok - Az események megosztásán túl
                    tartalommal töltheted fel a
                    hozzád tartozó helyszín adatlapját.</label>
                </li>
            </ul>

            <input id="checkbox_terms" type="checkbox" name="terms_of_service" value="accept"/> <label
            htmlFor="checkbox_terms">Elfogadom a promóterekre vonatkozó felhasználói szerződéseket.</label>

            <button onClick={this.register}>Promóter regisztráció</button>
        </Padding>
    }
}

export default PageEditorRegistration;
