import React, {Component} from "react";
import {DownloadArticle, ViewContentPanel} from "../article/Article";
import Loading from "../components/Loading";
import BarqItem from "../barq/BarqItem";
import queryString from "query-string";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from 'react-cookie';
import SubscriptionAction from "./SubscriptionAction";
import UnsubscriptionAction from "./UnsubscriptionAction";
import {Padding} from "../components/Components";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TranslateMessage} from "../i18n/TranslateMessage";
import "./TabBarqs.css";

const cookies_settings = {path: '/search', maxAge: 60 * 24 * 60 * 60};

class TabBarqs extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props, context) {
        super(props, context);

        const {cookies} = this.props;
        const viewFromCookies = cookies.get("view_mode", cookies_settings) || null;
        const values = queryString.parse(window.location.hash);
        const viewMode = this.props.forceViewMode || values["view"] || viewFromCookies || "card";

        this.changeViewMode = this.changeViewMode.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.state = {
            viewMode: viewMode,
            pdfLink: null,
            calendarLink: null
        }
    }

    changeViewMode() {
        const mode = this.state.viewMode;
        if (mode === "card") {
            this.setState({viewMode: "list"}, () => {
                const {cookies} = this.props;
                cookies.set('view_mode', "list", cookies_settings);
            })
        } else if (mode === "tile") {
            this.setState({viewMode: "list"}, () => {
                const {cookies} = this.props;
                cookies.set('view_mode', "list", cookies_settings);
            });

        } else if (mode === "list") {
            this.setState({viewMode: "newspaper"}, () => {
                const {cookies} = this.props;
                cookies.set('view_mode', "newspaper", cookies_settings);
            });
        } else {
            this.setState({viewMode: "card"}, () => {
                const {cookies} = this.props;
                cookies.set('view_mode', "card", cookies_settings);
            });
        }
    }


    subscribe() {
        this.setState({subscribe: true, unsubscribe: false});
    }

    unsubscribe() {
        this.setState({unsubscribe: true, subscribe: false});
    }

    render() {
        const content = this.props.content;
        const barqs = content.result.barqs;
        const paged = this.props.paged;
        const showMoreButton = !paged && content.result.fullCount < content.result.all;
        const page = content.result.page;
        const pageAll = content.result.pageAll;
        const showPageButtons = paged && pageAll > 1;


        let collectionClass = "ViewBarqCollectionOriginal";
        if (this.state.viewMode === "card")
            collectionClass = "ViewBarqItemCardCollection";
        else if (this.state.viewMode === "newspaper")
            collectionClass = "ViewBarqItemTileCollection"

        const toolbarButtons = <Padding>


            {(content.settings &&
                content.settings.subscriptionAvailable &&
                !content.settings.subscribed) &&
            <button className="ButtonSubscription redButton" onClick={this.subscribe}><TranslateMessage id="content_button_subscribe"/></button>}
            {(content.settings &&
                content.settings.removable) &&
            <button className="ButtonSubscription grayButton" onClick={this.unsubscribe}><TranslateMessage id="content_button_unsubscribe"/></button>}

            <button className="grayButton oneLineButton"
                    onClick={this.changeViewMode}><TranslateMessage id="content_button_changeview"/>
            </button>
            {this.state.subscribe && <SubscriptionAction app={this.props.app} name={null}
                                                         text={content.settings.searchData.fullText}/>}
            {this.state.unsubscribe &&
            <UnsubscriptionAction app={this.props.app} text={content.settings.searchData.fullText}/>}
            <ToastContainer />

        </Padding>

        if (content.settings.singleSection) {
            console.log(content.result);
            let options = [];
            for(var i = 0; i < pageAll ; i++) {
                let count = content.result.limit;
                if (i+1===pageAll)
                    count = content.result.all - (i*content.result.limit);

                options.push(<option value={i}>{i+1}/{pageAll}. oldal ({count} db.)</option> )
            }

            return <div className="">
                <div className="ContentToolbar ThemeBorder">
                    {toolbarButtons}
                </div>
                <div className="PaddingDependsOnWidth">
                    {(barqs.length > 0) && <div className={collectionClass}>{barqs.map((barq, index) =>
                        <BarqItem key={'barq-' + barq.barqId} barq={barq} sid={content.sid}
                                  index={index} mode={this.state.viewMode} sizeClass="BarqItemCardContentSize"
                                  app={this.props.app}/>
                    )}</div>}</div>


                {showMoreButton &&
                <button className="grayButton oneLineButton" onClick={this.props.showMoreButtonTapped}>Több
                    mutatása</button>}

                {showPageButtons && <div style={{textAlign: "center"}}>
                    <button className="PagedButton ThemeBackground" onClick={(e)=>this.props.showPage(0)}>&laquo;</button>
                    <button className="PagedButton ThemeBackground" disabled={page<=0} onClick={(e)=>this.props.showPage(page-1)}>&lt;</button>

                    <select className="PagedSelect ThemeBackground" value={page} onChange={(e)=> this.props.showPage(e.target.value)}>
                        {options}
                    </select>

                    <button className="PagedButton ThemeBackground" disabled={page+1>=pageAll} onClick={(e)=>this.props.showPage(page+1)}>&gt;</button>
                    <button className="PagedButton ThemeBackground" onClick={(e)=>this.props.showPage(pageAll-1)}>&raquo;</button>

                </div>}

                <Loading loading={this.state.loading}/>
            </div>
        } else if (content.settings.multipleSection) {
            return <div className="">
                <div>
                    <div className="PaddingDependsOnWidth ViewBarqCollectionInfoTop">
                        <div className="ViewBarqCollectionInfo">
                            {toolbarButtons}
                        </div>
                    </div>
                </div>

                <div>
                    <ViewContentPanel app={this.props.app} user={this.props.user} article={content.details.article}
                                      settings={content.settings} collectionClass={collectionClass}
                                      viewMode={this.state.viewMode}/>
                </div>


                <Loading loading={this.state.loading}/>
            </div>
        }
    }
}

export default withCookies(TabBarqs);