import React, {Component} from "react";
import SelectTags from "./SelectTags";
import ajax from "superagent";


class BarqTags extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {barq: this.props.barq, askAddTags: false};
        this.askTags = this.askTags.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.addTags = this.addTags.bind(this);
        this.hideTagsDialog = this.hideTagsDialog.bind(this);
        this.addRecommendation = this.addRecommendation.bind(this);
        this.tagsChanged = this.props.onTagsChanged.bind(this);
    }

    askTags() {
        this.setState({askAddTags: true});
    }


    removeTag(tag) {
        const barq = this.state.barq;
        const index = barq.tags.findIndex((t) => t.tagId === tag.tagId);
        let tags = [...barq.tags];
        tags.splice(index, 1)
        this.tagsChanged(tags);
    }

    addTags(text) {
        const barq = this.state.barq;
        ajax
            .post(this.props.app.server_url + '/editor/barq/query-tags')
            .query({...{"platform": "react", text: text}})
            .send(barq)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                const newTags = res.body;
                const tags = [...barq.tags, ...newTags];
                this.setState({askAddTags: false}, () => {
                    this.tagsChanged(tags);
                })
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    addRecommendation(newTag) {
        const barq = this.state.barq;
        const index = barq.tagsRecommendation.findIndex((t) => t.tagId === newTag.tagId);
        let tagsRecommendation = [...barq.tagsRecommendation];
        tagsRecommendation.splice(index, 1)
        barq.tagsRecommendation = tagsRecommendation;

        const tags = [...barq.tags, ...[newTag]];
        this.setState({askAddTags: false}, () => {
            this.tagsChanged(tags);
        })
    }

    hideTagsDialog() {
        this.setState({askAddTags: false});
    }


    render() {
        const barq = this.props.barq;


        const categoriesView = barq.categories.map((cat) => {
            return <div key={"category" + cat.tagId} style={{marginLeft: -40}}>
                <BarqCategory category={cat} key={"category--" + cat.tagId} level={0}
                              selectedCategories={barq.selectedCategories}
                              onCategoryChanged={this.props.onCategoryChanged}/>
            </div>
        });

        const tagsReadOnlyView = barq.tagsReadOnly.map((tag) => {
            return <div key={"barq-tag-hidden-" + tag.tagId}
                        className="BarqTag BarqCategorySelected">{tag.displaySpiced}</div>
        })
        const tagsRecommendationsView = barq.tagsRecommendation.map((tag) => {
            return <div key={"barq-tag-recommendation-" + tag.tagId} onClick={(e) => this.addRecommendation(tag)}
                        className="BarqTag ThemeBackground">{tag.displaySpiced}</div>
        })
        const tagsView = barq.tags.map((tag) => {
            return <div key={"barq-tag-" + tag.tagId} onClick={(e) => this.removeTag(tag)}
                        className="BarqTag BarqCategorySelected">{tag.displaySpiced} <span className="DeleteTag">x</span>
            </div>
        })

        return <div>
            <h2>Besorolás</h2>
            <p>
                Válaszd ki azokat a kategóriákat, amelyek a barqodra jellemzőek. <b>Legalább egy kategóriát ki kell
                választanod!</b>
            </p>
            {barq.barqId != null && <div>{categoriesView}</div>}

            <h2>Nem módosítható címkék</h2>
            <p>
                Ezeket a címkéket a rendszer automatikusan rendeli a barqodhoz, ezeket nem módosíthatod és nem
                távolíthatod el.
            </p>
            {barq.barqId != null && <div style={{width: "100%"}}>{tagsReadOnlyView}</div>}
            <h2>A rendszer ajánlásai</h2>
            <p>A rendszer próbál számodra lehetséges címkéket ajánlani. Ha egy címkével egyetértessz,
                akkor
                kattints rá, hogy bekerüljön a barqod címkéi közé!</p>
            {barq.barqId != null && <div style={{width: "100%"}}>{tagsRecommendationsView}</div>}
            <h2>Címkék</h2>
            <p>Itt találhatók azok a címkék, amelyek a barqodhoz tartoznak. <b>Legalább három címkét ki
                kell választanod!</b></p>

            {barq.barqId != null &&
            <button className="barqButton ButtonFull" onClick={this.askTags}>Címkék
                hozzáadása</button>}

            {this.state.askAddTags &&
            <SelectTags onSave={this.addTags} onCancel={this.hideTagsDialog} app={this.props.app}/>}


            {barq.barqId != null && <div style={{width: "100%"}}>{tagsView}</div>}
        </div>
    }
}


class BarqCategory extends Component {

    constructor(props, context) {
        super(props, context);
        this.onCategoryChanged = this.props.onCategoryChanged.bind(this);
    }

    render() {
        const selectedCategories = this.props.selectedCategories;
        const category = this.props.category;

        const emptyChildren = category.children.filter((c) => c.children.length === 0);
        const notEmptyChildren = category.children.filter((c) => c.children.length > 0);

        /////////////////////////////////////////////////////
        const emptyChildrenView = emptyChildren.map((c) => {
            return <BarqCategory key={"category-child-" + c.tagId} category={c}
                                 selectedCategories={selectedCategories} level={this.props.level + 1}
                                 onCategoryChanged={this.onCategoryChanged}/>
        })


        const notEmptyChildrenView = notEmptyChildren.map((child) => {
            return <BarqCategory key={"category-child-" + child.tagId} category={child}
                                 selectedCategories={selectedCategories} level={this.props.level + 1}
                                 onCategoryChanged={this.onCategoryChanged}/>
        });
        /////////////////////////////////////////////////////


        const index = selectedCategories.findIndex((s) => s.tagId === category.tagId);
        let className = "BarqCategoryNotSelected ThemeBackground";
        if (index >= 0) className = "BarqCategorySelected";
        return <div className="BarqCategory">
            {this.props.level > 0 && <div className={"BarqCategoryElement " + className}
                                          onClick={(e) => this.onCategoryChanged(category)}>{category.display}</div>}
            {notEmptyChildren.length > 0 && <div className="children"> {notEmptyChildrenView}</div>}
            {emptyChildren.length > 0 && <div className="emptyChildren"> {emptyChildrenView}</div>}
        </div>


        ////////////////////////////////////

    }

}

export default BarqTags;
export {BarqCategory};