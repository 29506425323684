import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import "./PromotersSection.css"
import {Link} from "react-router-dom";
import SectionHeader from "./SectionHeader";
import {translateMessage} from "../../i18n/TranslateMessage";


class PromotersSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {content: {title: "Promóterek", posts: null}}
    }

    componentDidMount() {
        this.downloadPromoters();
    }

    downloadPromoters() {
        ajax
            .post(this.props.app.server_url + '/section/get')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": this.props.section.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({content: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        const promoters = this.state.content?.promoters?.promoters;
        if (promoters != null) {
            return (
                <div className="PaddingDependsOnWidth">
                    <div
                        className="Section ModernSection SectionWidthContainer BarqboardBarqListContainer ThemeBackground">
                        <SectionHeader title={this.state.content.title} button={null} link={null}/>
                        <div className="BarqboardBarqList">
                            {promoters.map((promoter) =>
                                <PromoterSectionItem key={'promoter-' + promoter.storageDir} promoter={promoter}
                                                     app={this.props.app}/>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div
                className="Section SectionWidthContainer BarqboardBarqListContainer BarqboardBarqListContainerLoading"/>
        }

    }
}


class PromoterSectionItem extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {text: ""};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    render() {
        const promoter = this.props.promoter;

        return <div className="PromoterSectionItem ThemeBackground">
            <div className={'OfferGroupSection'}
                 style={{backgroundImage: "url(" + promoter.imageHeader + ")"}}>
            </div>
            <div className="ShowPostImage">

                <div className="MyPostProfilePicture"
                     style={{backgroundImage: 'url(' + promoter.imageLogo + ")"}}>&nbsp;</div>
            </div>

            <div className="ShowPostContent">
                {/*{type === "image" && <div><img src={post.imageUrl}/></div>}*/}
                <div>
                    <Link to={"/search/SEARCH:" + promoter.tag.tag + "#tab=posts"}><b>{promoter.name}</b></Link>
                </div>
            </div>
        </div>
    }


}


export default PromotersSection;
