import React, {Component} from 'react';
import './ArticleEditor.css';

import {ViewArticlePanel} from "./Article";
import {InfoMessage, Padding} from "../components/Components";
import EditorPopupDialog from "./paragraph/EditorPopupDialog";
import {MediaSelectorComponent} from "../editor/media/MediaEditor";
import ajax from "superagent";

const PubSub = require('pubsub-js');

class PageArticleEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            article: this.props.article,
            groups: [
                {key: "promoter", title: "Felhasználói cikk"},
                {key: "webpage", title: "Weboldal"},
                {key: "article", title: "Cikk"},
                {key: "fragment", title: "Weboldal fragment"},
                {key: "web-fragment-home", title: "Főoldal fragment"},
                {key: "web-content-home", title: "Főoldal tartalom"}
            ],
            categories: [],
            add: {index: 0, type: "text", ask: false}
        };
        this.updateParagraph = this.updateParagraph.bind(this);
        this.removeParagraph = this.removeParagraph.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.firstParagraph = this.firstParagraph.bind(this);
        this.addNewParagraph = this.addNewParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);
        this.cancelNewParagraph = this.cancelNewParagraph.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.postArticle = this.postArticle.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        PubSub.subscribe('UPDATE_PARAGRAPH', this.updateParagraph);
        PubSub.subscribe('REMOVE_PARAGRAPH', this.removeParagraph);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const article = this.props.article;
        const oldArticle = this.state.article;
        if (article!=oldArticle)
            this.setState({article: article});
    }

        componentDidMount() {
        this.downloadCategories();
    }

    downloadCategories() {
        ajax
            .post(this.props.app.server_url + '/editor/article/categories')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({categories: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    save(event) {
        const article = this.state.article;
        this.props.onArticleChanged(article);
    }

    delete() {
        var result = window.confirm("Biztosan törölni szeretnéd?\r\n" + this.state.article.title);
        if (result) {
            ajax
                .post(this.props.app.server_url + '/editor/article/delete')
                .query({"platform": "react", "code": this.state.article.code})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    if (this.props.onCancel) this.props.onCancel();
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        }
    }

    updateParagraph(msg, paragraph) {
        const article = this.state.article;
        const index = article.paragraphs.findIndex((p) => p.code === paragraph.code);
        let paragraphs = [...article.paragraphs];
        paragraphs[index] = paragraph
        article.paragraphs = paragraphs;
        this.setState({article: article});
    }

    removeParagraph(msg, paragraph) {
        const article = this.state.article;
        const index = article.paragraphs.findIndex((p) => p.code === paragraph.code);
        let paragraphs = [...article.paragraphs];
        paragraphs.splice(index, 1)
        article.paragraphs = paragraphs;
        this.setState({article: article});
    }

    moveParagraph(whereTo, paragraph) {
        const article = this.state.article;
        const index = article.paragraphs.findIndex((p) => p.code === paragraph.code);
        let paragraphs = [...article.paragraphs];
        paragraphs.splice(index, 1)

        let insertHere = index;
        if (whereTo === 'before') {
            insertHere = index - 1;
        } else if (whereTo === 'after') {
            insertHere = index + 1;
        } else if (whereTo === 'first') {
            insertHere = 0;
        } else if (whereTo === 'last') {
            insertHere = paragraphs.length;
        }
        paragraphs.splice(insertHere, 0, paragraph)
        article.paragraphs = paragraphs;
        this.setState({article: article});
    }


    addParagraph(whereTo, paragraph) {
        const article = this.state.article;
        const index = article.paragraphs.findIndex((p) => p.code === paragraph.code);
        let insertHere = index;
        if (whereTo === 'after') {
            insertHere = index + 1;
        }

        this.setState({add: {index: insertHere, type: "text", ask: true}})
    }

    firstParagraph() {
        this.setState({add: {index: 0, type: "text", ask: true}})
    }


    addNewParagraph(type) {
        const article = this.state.article;
        const insertHere = this.state.add.index;
        const code = article.code + "-" + Date.now();
        let newParagraph = {type: type, code: code, newbie: true};
        if (type === 'title') {
            newParagraph.title = "";
        } else if (type === 'title') {
            newParagraph.text = "";
        } else if (type === 'search') {
            newParagraph.title = "";
            newParagraph.search = "SEARCH:";
        } else if (type === 'youtube') {
            newParagraph.title = "";
            newParagraph.embedded = "";
        } else if (type === 'actions') {
            newParagraph.title = "";
            newParagraph.actions = [];
        } else if (type === 'pairs') {
            newParagraph.title = "";
            newParagraph.text = "";
            newParagraph.actions = [];
        } else if (type === 'button') {
            newParagraph.title = "";
            newParagraph.action = "";
            newParagraph.decoration = "";
        } else if (type === 'card') {
            newParagraph.title = "";
            newParagraph.text = "";
            newParagraph.decoration = "";
            newParagraph.backgroundColor = "";
            newParagraph.color = "";
            newParagraph.textAlign = "";
            newParagraph.image = {
                imageSmall: {imageUrl: null},
                imageMedium: {imageUrl: null},
                imageLarge: {imageUrl: null}
            };
        } else if (type === 'media_image') {
            newParagraph.image = {
                imageSmall: {imageUrl: null},
                imageMedium: {imageUrl: null},
                imageLarge: {imageUrl: null}
            };
        }
        let paragraphs = [...article.paragraphs];
        paragraphs.splice(insertHere, 0, newParagraph)
        article.paragraphs = paragraphs;
        this.setState({article: article, add: {index: insertHere, type: type, ask: false}});
    }

    cancelNewParagraph() {
        this.setState({add: {index: null, type: null, ask: false}});
    }

    postArticle() {
        ajax
            .post(this.props.app.server_url + '/editor/post/post_article')
            .query({"platform": "react", "code": this.state.article.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("A Cikked posztolása megtörtént!\r\nA poszt elérhető a posztjaid között.")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({article: {...this.state.article, ...{[name]: value}}});
    }

    render() {
        const article = this.state.article;
        const group = this.state.groups.find((p) => p.key === article.group) || {title: "ismeretlen"};
        const category = this.state.categories.find((p) => p.code === article.category) || {title: "ismeretlen"};

        return <div>
            {(!this.props.editorMode && this.props.hideArticleData !== true) && <Padding>
                <div style={{clear: 'both'}}/>


                <div className="DisplayRow ThemeBackground">
                    <div className="DisplayRowKey">Cikk azonosítója:</div>
                    <div className="DisplayRowValue"><a
                        href={this.props.app.app_url + "/page/" + article.code}> {this.props.app.app_url}/page/{article.code}</a>
                    </div>
                </div>
                <div className="DisplayRow ThemeBackground">
                    <div className="DisplayRowKey">Cikk címe:</div>
                    <div className="DisplayRowValue">{article.title}</div>
                </div>

                <div className="DisplayRow ThemeBackground">
                    <div className="DisplayRowKey">Csoport:</div>
                    <div className="DisplayRowValue">{group.title}</div>
                </div>

                <div className="DisplayRow ThemeBackground">
                    <div className="DisplayRowKey">Kategória:</div>
                    <div className="DisplayRowValue">{category.name}</div>
                </div>

                <div className="DisplayRow ThemeBackground">
                    <div className="DisplayRowKey">Cikk összefoglalója:</div>
                    <div className="DisplayRowValue">{article.excerpt}</div>
                </div>

                <div style={{clear: 'both'}}/>

                {article.imageHeader &&
                <div className="PromoterImageHeader" style={{backgroundImage: 'url(' + article.imageHeader + ")"}}/>}
                {article.imageHeaderTablet &&
                <div className="PromoterImageHeader" style={{backgroundImage: 'url(' + article.imageHeaderTablet + ")"}}/>}
                {article.imageHeaderMobile &&
                <div className="PromoterImageHeader" style={{backgroundImage: 'url(' + article.imageHeaderMobile + ")"}}/>}
                <h2>{article.title}</h2>

            </Padding>}


            {(this.props.editorMode && this.props.hideArticleData !== true) &&
            <Padding>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="article_code">Cikk azonosítója ({this.props.app.app_url}/page/):</label><input
                    id="article_code" type="text" name="code"
                    value={article.code} onChange={this.onPropertyChanged}/>
                </div>
                <div style={{clear: 'both'}}/>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="article_title">Cikk címe:</label><input id="article_title" type="text"
                                                                            name="title"
                                                                            value={article.title}
                                                                            onChange={this.onPropertyChanged}/>
                </div>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="article_group">Csoport:</label>

                    <select id="article_group" name="group" value={article.group}
                            onChange={this.onPropertyChanged}>
                        {this.state.groups.map((group) => <option key={"group" + group.key}
                                                                  value={group.key}>{group.title}</option>)}
                    </select>
                </div>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="article_category">Kategória:</label>

                    <select id="article_category" name="category" value={article.category}
                            onChange={this.onPropertyChanged}>
                        <option value="">-- Nincs megadva --</option>
                        {this.state.categories.map((category) => <option key={"category" + category.code}
                                                                  value={category.code}>{category.name}</option>)}
                    </select>
                </div>
                <div style={{clear: 'both'}}/>

                <div className="EditorRow ThemeBackground">
                    <label htmlFor="form_imageHeader">Fejléc kép:</label>
                    <MediaSelectorComponent id="form_form_imageHeader" name="imageHeader"
                                            value={article.imageHeader}
                                            onChange={this.onPropertyChanged}
                                            app={this.props.app} user={this.props.user}/>
                </div>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="form_imageHeaderTablet">Fejléc kép tablet:</label>
                    <MediaSelectorComponent id="form_form_imageHeaderTablet" name="imageHeaderTablet"
                                            value={article.imageHeaderTablet}
                                            onChange={this.onPropertyChanged}
                                            app={this.props.app} user={this.props.user}/>
                </div>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="form_imageHeaderMobile">Fejléc kép mobile:</label>
                    <MediaSelectorComponent id="form_imageHeaderMobile" name="imageHeaderMobile"
                                            value={article.imageHeaderMobile}
                                            onChange={this.onPropertyChanged}
                                            app={this.props.app} user={this.props.user}/>
                </div>

                <div className="EditorRow ThemeBackground">
                    <label htmlFor="form_title_on_offer">Cím megjelenítése ajánlásoknál:</label>

                    <select id="form_title_on_offer" name="titleOnOffer" value={article.titleOnOffer}
                            onChange={this.onPropertyChanged}>
                        <option value={false}>Nem</option>
                        <option value={true}>Igen</option>
                    </select>
                </div>
                <div className="EditorRow ThemeBackground">
                    <label htmlFor="article_excerpt">Cikk összefoglalója:</label><textarea id="article_excerpt"
                                                                                           name="excerpt"
                                                                                           onChange={this.onPropertyChanged}
                                                                                           value={article.excerpt}/>
                </div>

                {/*{this.props.editorMode && <button onClick={this.save}>Mentés</button>}*/}

                {article.paragraphs.length === 0 &&
                <InfoMessage>A cikknek még nincsenek bekezdései. Az első bekezdés hozzáadásához kattints
                    ide: <button className="barqButton" onClick={this.firstParagraph}>Első bekezdés
                        hozzáadása</button></InfoMessage>}
            </Padding>}

            <ViewArticlePanel noPadding={this.props.editorMode} article={article} app={this.props.app}
                              user={this.props.user}
                              editorMode={this.props.editorMode} addParagraph={this.addParagraph}
                              moveParagraph={this.moveParagraph}/>


            <Padding>

                {(this.props.hideArticleData !== true && article.articleId != null && article.publish == null) &&
                <InfoMessage>
                    <div className="InfoMessageSwitch">
                        <input type="checkbox" className="apple-switch" checked={article.publishMe}
                               name="publishMe"
                               onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="InfoMessageContent">
                        <span className="Title">Cikk publikálása</span> - Jelöld be ezt a kapcsolót, hogy a
                        cikked a
                        felhasználók számára is olvashatók legyenek.
                        A publikált cikkek bekerülhetnek a barq.me által ajánlott cikkek közé, ha megfelelnek
                        annak
                        kritériumainak.
                    </div>
                </InfoMessage>}

                {(article.publish != null) && <InfoMessage>
                    <span className="Title">Cikk posztolása</span> - Cikk posztolása, hogy az megjelenjen az olvasóid
                    hírfolyamában.
                    <button className="barqButton" onClick={this.postArticle}>Cikk posztolása</button>
                </InfoMessage>}


                {(this.props.editorMode && this.props.hideArticleData !== true) &&
                <button className="largeRedButton" style={{marginRight: "20px"}} onClick={this.delete}>Törlés</button>}

                {(this.props.editorMode && this.props.hideArticleData !== true) && <span>
                        <button className="grayButton" onClick={this.props.onCancel}>Mégsem</button>
                        <button className="barqButton"
                                onClick={this.save}>Mentés</button></span>}
            </Padding>

            {this.state.add.ask &&
            <AskParagraphType onSelect={this.addNewParagraph} onCancel={this.cancelNewParagraph}/>}
        </div>
    }

}


class AskParagraphType extends Component {

    constructor(props, context) {
        super(props, context);
        this.onSelect = this.props.onSelect.bind(this);
        this.onCancel = this.props.onCancel.bind(this);
    }

    render() {

        return <EditorPopupDialog width={490} height={400} onCancel={this.onCancel}
                                  classes="ArticleSelectParagraphType">
            <div className="HeadingTitle">Válasszon bekezdéstípust</div>
            <button className="ArticleSelectParagraphTypeButton" onClick={(e) => this.onSelect("title", e)}>Cím
            </button>
            <button className="ArticleSelectParagraphTypeButton" onClick={(e) => this.onSelect("text", e)}>Szöveg
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("media_image", e)}>Eszközfüggő kép
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("search", e)}>Eseményblokk
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("button", e)}>Gomb, link
            </button>

            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("actions", e)}>Gombcsoport
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("pairs", e)}>Kulcs-érték párok
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("youtube", e)}>Youtube
            </button>
            <button className="ArticleSelectParagraphTypeButton"
                    onClick={(e) => this.onSelect("card", e)}>Bekezdés
            </button>
        </EditorPopupDialog>

    }

}


export default PageArticleEditor;