import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import {Padding, PaddingEditor} from "../../components/Components";
import {NewParagraphButtons} from "./ParagraphBlock";
import SubscriptionAction from "../../content/SubscriptionAction";
import UnsubscriptionAction from "../../content/UnsubscriptionAction";

const PubSub = require('pubsub-js');


class ArticleParagraphTitle extends Component {

    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <ArticleParagraphTitleEditMode paragraph={paragraph} addParagraph={this.props.addParagraph}
                                                  moveParagraph={this.props.moveParagraph}/>
        } else {
            return <ArticleParagraphTitleSubscriptionView app={this.props.app} paragraph={paragraph}
                                                          settings={this.props.settings}
                                                          noPadding={this.props.noPadding}/>
        }
    }
}

class ArticleParagraphTitleView extends Component {
    render() {
        const paragraph = this.props.paragraph;
        if (this.props.noPadding !== true)
            return <h2>{paragraph.title}</h2>
        else
            return <div><h2>{paragraph.title}</h2></div>
    }
}


class ArticleParagraphTitleSubscriptionView extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {subscribe: false, unsubscribed: true};
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
    }


    subscribe() {
        this.setState({subscribe: true, unsubscribe: false});
    }

    unsubscribe() {
        this.setState({unsubscribe: true, subscribe: false});
    }

    render() {
        const paragraph = this.props.paragraph;
        const content = <div className="ArticleParagraphTitleSubscriptionView">
            <div className="ArticleParagraphTitleSubscriptionViewTitle">
                {paragraph.title}
            </div>

            {/*{(this.props.settings &&*/}
            {/*    this.props.settings.subscriptionAvailable &&*/}
            {/*    !this.props.settings.subscribed) && <div className="ArticleParagraphTitleSubscriptionViewButton">*/}
            {/*    <button className="ButtonSubscription redButton" onClick={this.subscribe}>Feliratkozás</button>*/}
            {/*</div>}*/}
            {/*{(this.props.settings &&*/}
            {/*    this.props.settings.removable) && <div className="ArticleParagraphTitleSubscriptionViewButton">*/}
            {/*    <button className="ButtonSubscription grayButton" onClick={this.unsubscribe}>Leiratkozás</button>*/}
            {/*</div>}*/}
            {/*/!*<div style={{clear: 'both'}}/>*!/*/}

            {/*{this.state.subscribe && <SubscriptionAction app={this.props.app} name={paragraph.title}*/}
            {/*                                             text={this.props.settings.searchData.fullText}/>}*/}
            {/*{this.state.unsubscribe &&*/}
            {/*<UnsubscriptionAction app={this.props.app} text={this.props.settings.searchData.fullText}/>}*/}
        </div>

        if (paragraph.title?.length > 0) {
            if (this.props.noPadding !== true)
                return <Padding>{content}</Padding>
            else
                return <div>{content}</div>
        } else {
            return null;
        }
    }
}


class ArticleParagraphTitleEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);


    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }

    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(title) {
        this.setState({paragraph: {...this.state.paragraph, ...{title: title}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });

    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <PaddingEditor>
            {!showEditor && <div><h2>{paragraph.title}</h2>
                <button onClick={this.showEditor}>...</button>
            </div>}
            {showEditor &&
            <ArticleParagraphTitleEditor title={paragraph.title} buttons={<NewParagraphButtons onRemove={this.remove}
                                                                                               onAddParagraph={this.addParagraph}/>}
                                         onCancel={this.hideEditor} onSave={this.save}/>}
        </PaddingEditor>
    }
}

class ArticleParagraphTitleEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {title: this.props.title};
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.title);
    }

    onTitleChanged(event) {
        const target = event.target;
        const value = target.value;
        this.setState({title: value})
    }

    render() {
        return <div>

            <input id="form_title" className="ArticleParagraphTitleEditorField" type="text" name="title"
                   value={this.state.title} onChange={this.onTitleChanged}/>
            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


export default ArticleParagraphTitle;
export {ArticleParagraphTitleView};