import React, {Component} from "react";
import './Loading.css';

class Loading extends Component {

    render() {
        if (this.props.loading === true) {
            return <div>
                {/*<BarqMeCookies/>*/}
                <div className="LoadingOverlay ThemeBackground">

                </div>
            </div>
        } else return null;
    }
}

export default Loading;