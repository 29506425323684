import React, {Component} from "react";
import '../Articles.css';
import '../ArticleEditor.css';
import './ArticleParagraphSearch.css';
import {Padding, PaddingEditor} from "../../components/Components";
import {NewParagraphButtons} from "./ParagraphBlock";
import BarqsSection from "../../barqboard/sections/BarqsSectionStatic";
import BarqItem, {BarqItemShowAll} from "../../barq/BarqItem";
import {TranslateMessage} from "../../i18n/TranslateMessage";

const PubSub = require('pubsub-js');


class ArticleParagraphSearch extends Component {

    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        const settings = this.props.settings;
        if (editorMode) {
            return <ArticleParagraphSearchEditMode paragraph={paragraph} addParagraph={this.props.addParagraph}
                                                   app={this.props.app} user={this.props.user}/>
        } else if (settings?.displayMultipleBarqsExtended) {
            return <ArticleParagraphExtendedView paragraph={paragraph} app={this.props.app} user={this.props.user}
                                                 collectionClass={this.props.collectionClass}
                                                 viewMode={this.props.viewMode}/>
        } else {
            return <ArticleParagraphSearchView paragraph={paragraph} app={this.props.app} user={this.props.user}/>
        }
    }
}

class ArticleParagraphExtendedView extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {collectionClass: this.props.collectionClass, viewMode: this.props.viewMode};
    }

    render() {
        const paragraph = this.props.paragraph;
        const data = paragraph.barqs;
        const barqs = data.barqs;
        return <div className="PaddingDependsOnWidth">
            {(barqs.length > 0) && <div>
                <Padding><h2>{paragraph.title}</h2></Padding>
                <div className={this.state.collectionClass}>{barqs.map((barq, index) =>
                <BarqItem key={'barq-' + barq.barqId} barq={barq}
                          index={index} mode={this.state.viewMode} sizeClass="BarqItemCardContentSize"
                          app={this.props.app}/>
            )}
                {(data.barqs.length < data.all) &&<BarqItemShowAll mode={this.props.viewMode} to={"/search/" + paragraph.search}>Összes ({data.all}) esemény...</BarqItemShowAll>}
            </div>
                </div>}
            {(barqs.length === 0) &&
            <div className="ThemeSolidBackground ThemeSolidColor ArticleParagraphSearchEmpty">Nincs több program ebben a csoportban: {paragraph.title}</div>}
        </div>
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.collectionClass !== this.props.collectionClass || this.state.viewMode !== this.props.viewMode) {
            this.setState({collectionClass: this.props.collectionClass, viewMode: this.props.viewMode});
        }
    }


}

class ArticleParagraphSearchView extends Component {
    render() {
        const paragraph = this.props.paragraph;
        if (paragraph.section) {
            return <BarqsSection section={paragraph.section} app={this.props.app}/>;
        } else {
            return <BarqsSection section={{code: paragraph.code, title: paragraph.title, place: paragraph.place, barqs: paragraph.barqs}} app={this.props.app}/>;
        }
    }
}

class ArticleParagraphSearchEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.state.paragraph.barqs = null;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);


    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }

    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(title, search) {
        this.setState({paragraph: {...this.state.paragraph, ...{title: title, search: search, barqs: null}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });

    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <PaddingEditor>
            {!showEditor &&
            <div><h1>Eseménylista: {paragraph.title}</h1>
                <button onClick={this.showEditor}>...</button>
            </div>}

            {showEditor && <ArticleParagraphSearchEditor title={paragraph.title} search={paragraph.search}
                                                         buttons={<NewParagraphButtons onRemove={this.remove}
                                                                                       onAddParagraph={this.addParagraph}/>}
                                                         onCancel={this.hideEditor} onSave={this.save}/>}


        </PaddingEditor>
    }
}

class ArticleParagraphSearchEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {title: this.props.title, search: this.props.search};
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.title, this.state.search);
    }

    onTitleChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value})
    }

    render() {
        return <div>
            <input type="text" name="title" value={this.state.title} onChange={this.onTitleChanged}/>
            <input type="text" name="search" value={this.state.search} onChange={this.onTitleChanged}/>
            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


export default ArticleParagraphSearch;