import React, {Component} from 'react';
import './PageEditor.css';
import {Padding} from "../components/Components";


class PageEditorWaiting extends Component {

    render() {
        return <Padding>
            <h1>Promóter jóváhagyás folyamatban</h1>
            <p>A <b>{this.props.promoter.name}</b> nevű promóter regisztrációdat megkaptuk, jóváhagyása folyamatban van.
            </p>
        </Padding>
    }
}

export default PageEditorWaiting;