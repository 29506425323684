import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import {NewParagraphButtons} from "./ParagraphBlock";
import {Padding, PaddingEditor} from "../../components/Components";
import BarqMeLink from "../../components/BarqMeLink";
import  "./ArticleParagraphButton.css";

const PubSub = require('pubsub-js');

class ArticleParagraphButton extends Component {

    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <ArticleParagraphButtonEditMode paragraph={paragraph} addParagraph={this.props.addParagraph}
                                                   moveParagraph={this.props.moveParagraph}/>
        } else {
            return <ArticleParagraphButtonView paragraph={paragraph} noPadding={this.props.noPadding}/>
        }
    }
}

class ArticleParagraphButtonView extends Component {
    render() {
        const paragraph = this.props.paragraph;

        if (this.props.noPadding !== true)
            return <Padding><BarqMeLink action={paragraph.action} className={paragraph.decoration}>{paragraph.title}</BarqMeLink></Padding>
        else
            return <div className="ThemeBackground"><BarqMeLink action={paragraph.action} className={paragraph.decoration}>{paragraph.title}</BarqMeLink></div>
    }
}

class ArticleParagraphButtonEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);

    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }


    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(action, title, decoration) {
        this.setState({
            paragraph: {
                ...this.state.paragraph, ...{
                    action: action,
                    title: title,
                    decoration: decoration
                }
            }
        }, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });
    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <PaddingEditor>
            {!showEditor && <div><ArticleParagraphButtonView paragraph={this.props.paragraph}/>
                <button onClick={this.showEditor}>...</button>
            </div>}

            {showEditor &&
            <ArticleParagraphButtonEditor action={paragraph.action} title={paragraph.title}
                                          buttons={<NewParagraphButtons onRemove={this.remove}
                                                                        onAddParagraph={this.addParagraph}/>}
                                          decoration={paragraph.decoration} onCancel={this.hideEditor}
                                          onSave={this.save}/>}
        </PaddingEditor>
    }
}

class ArticleParagraphButtonEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            action: this.props.action, title: this.props.title, decorations: [
                {code: "", name: "Egyszerű felirat"},
                {code: "grayButton", name: "Egyszerű gomb"},
                {code: "largeRedButton", name: "Kiemelt gomb"},
                {code: "transparentButton", name: "Átlátszó, kicsi, középen"},
                {code: "infoButton", name: "Kiemelt, széles, középen szöveg"},
                {code: "web_link", name: "Web link"}],
            decoration: this.props.decoration || ""
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDataChanged = this.onDataChanged.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.action, this.state.title, this.state.decoration);
    }

    onDataChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value})
    }

    render() {


        const decorationOptions = this.state.decorations.map((decoration) => {
            return <option key={decoration.code} value={decoration.code}>{decoration.name}</option>
        });

        return <div>

            <div className="ThemeSolidBackground BlockEditorTitle">
                {/*<label htmlFor="form_decoration">Szöveg megjelenítése:</label>*/}
                <select id="form_decoration" name="decoration" value={this.state.decoration}
                        onChange={this.onDataChanged}>
                    {decorationOptions}
                </select>
            </div>

            <label htmlFor="form_title">Gomb felirata:</label>
            <input id="form_title" className="ArticleParagraphEditorField" type="text" name="title"
                   value={this.state.title} onChange={this.onDataChanged}/>

            <label htmlFor="form_action">Gomb funkciója:</label>
            <input id="form_action" className="ArticleParagraphEditorField" type="text" name="action"
                   value={this.state.action} onChange={this.onDataChanged}/>
            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


export default ArticleParagraphButton;