import React, {Component} from "react";
import ajax from "superagent";
import './Articles.css';
import './ArticlePreviewItem.css';
import {Helmet} from "react-helmet";
import BarqMeCookies from "../BarqMeCookies";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import BarqMeTopbar from "../topbar/Topbar";
import Container, {Padding} from "../components/Components";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import {DownloadArticle} from "./Article";

var moment = require('moment-timezone');

class PageArticles extends Component {


    constructor(props, context) {
        super(props, context);
        const promoterCode = this.props.match.params.promoter || "";
        const categoryCode = this.props.match.params.category || "";
        this.state = {
            title: "Cikkek", promoter: promoterCode, category: categoryCode, articles: null, categories: []
        };
    }

    componentDidMount() {
        this.downloadArticles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.promoter !== this.props.match.params.promoter || prevProps.match.params.category !== this.props.match.params.category) {
            const promoterCode = this.props.match.params.promoter || "";
            const categoryCode = this.props.match.params.category || "";
            this.setState({promoter: promoterCode, category: categoryCode, articles: null, categories: []}, () => {
                this.downloadArticles();
            });

        }
    }


    downloadArticles() {
        ajax
            .post(this.props.app.server_url + '/article/download')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                promoter: this.state.promoter, category: this.state.category})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                console.log(res.body);
                this.setState({title: res.body.title, articles: res.body.articles, categories: res.body.categories});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        const articles = this.state.articles;
        return <div>
            <Helmet>
                <title>barq.me | {this.state.title}</title>
                <meta name="description" lang="hu" content={'barq.me cikkek'}/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>

                <div className="ArticlesContainer">
                    <DownloadArticle app={this.props.app} user={this.props.user} article="web-blog"/>
                    <ListArticles articles={articles}/>
                </div>

                {articles == null && <Loading loading={true}/>}

            </Container>
        </div>
    }

}

class ListArticles extends Component {

    render() {
        const articles = this.props.articles;
        return <Padding>
            <div className="ArticlePreviewItemContainer">

                {articles != null && articles.map((article) => {
                    return <ArticlePreviewItem article={article}/>
                })}
                {(articles != null && articles.length === 0) && <div>Nincsenek cikkek!</div>}
            </div>
        </Padding>

    }
}

class ArticlePreviewItem extends Component {

    render() {
        const article = this.props.article;
        return <div className="ArticlePreviewItem ThemeBackground">
            <div className={'OfferGroupSection'} style={{backgroundImage: `url(${article.imageHeader})`}}>
            </div>
            <h2>{article.title}</h2>
            <div className="ArticleListItemInfo">Publikálva: {moment(article.publishStamp).tz("Europe/Budapest").format("YYYY.MM.DD. HH:mm")}, írta: <Link
                    to={"/blog/" + article.promoter.storageDir}>{article.promoter.name}</Link></div>
            <div className="ArticleListItemExcerpt">{article.excerpt}</div>
            <div style={{textAlign: "right"}}>
                <Link className="barqButton" to={"/page/" + article.code}>Cikk elolvasása</Link>
            </div>
        </div>
    }
}

class DownloadArticleGroup extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {articles: null};
    }

    componentDidMount() {
        this.downloadArticles();
    }

    downloadArticles() {
        ajax
            .post(this.props.app.server_url + '/article/list')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "group": this.props.group})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                const log = JSON.stringify(res.body);
                console.log(log);
                this.setState({articles: res.body.articles});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        if (this.state.articles != null) {
            const articles = this.state.articles;
            const articleViews = articles.map((article) => {
                return <ArticleExcerpt key={'article' + article.code} article={article}/>
            });


            return <Padding>
                {articleViews}
            </Padding>
        } else {
            return <Loading loading={true}/>
        }
    }


}

class ArticleListElement extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            groups: [{key: "promoter", title: "Egyszerű cikk"}, {
                key: "webpage",
                title: "Weboldal"
            }, {key: "article", title: "Cikk"}, {key: "fragment", title: "Weboldal fragment"}]
        };
        this.editArticle = this.editArticle.bind(this);
    }

    editArticle(article) {
        this.props.onEditArticle(article)
    }

    render() {
        const article = this.props.article;
        const group = this.state.groups.find((p) => p.key === article.group) || {title: "ismeretlen"};

        return <div className={"ArticleListElement " + this.props.className}>
            <div className="Col Col0">{this.props.hideImage ||
            <img src={article.imageHeaderDesktop} alt={article.title}/>}</div>
            <div className="Col Col1"><a href={'/page/' + article.code}>{article.code}</a></div>
            <div className="Col Col2"><a href={'/page/' + article.code}>{article.title}</a></div>
            <div className="Col Col3">{group.title}</div>
            <div className="Col Col4">{article.publish ? article.publish : "nincs publikálva"}</div>
            <div className="Col Col5">{article.excerpt}</div>
            <div className="Col Col6">
                <button className="barqButton" onClick={() => this.editArticle(article)}>Szerkeszt</button>
            </div>
        </div>
    }

}

class ArticleExcerpt extends Component {

    constructor(props, context) {
        super(props, context);
        this.editArticle = this.editArticle.bind(this);
    }

    editArticle(article) {
        this.props.onEditArticle(article)
    }

    render() {
        const article = this.props.article;
        return <div className="ArticleExcerpt ThemeBackground">
            <div className="ArticleExcerptImage">
                <a href={'/page/' + article.code}>
                    <div className="Image"
                         style={{backgroundImage: `url(${article.imageHeader})`}}/>
                </a>
            </div>
            <div className="ArticleExcerptTitle">
                <a href={'/page/' + article.code}> {article.title} &raquo;</a>
                {this.props.editorMode && <button onClick={() => this.editArticle(article)}>Módosít</button>}

            </div>
            <div className="ArticleExcerptText">
                {this.props.editorMode && <span>Csoport: {article.group}, publikálva: {article.publish}<br/></span>}

                {article.excerpt}
            </div>
        </div>
    }

}

export default PageArticles;
export {DownloadArticleGroup, ArticleExcerpt, ArticleListElement, ListArticles};