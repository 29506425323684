import React, {Component} from 'react';
import packageJson from '../package.json';

import {instanceOf} from 'prop-types';
import './media-size.css';
import './font-size.css';
import './theme.css';
import './apple-switch.css';
import PageBarqboard from './barqboard/Barqboard'
import ajax from "superagent";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import PageLogin from "./auth/Login";
import PageMyProfile from "./auth/MyProfile";
import PageRegister from "./auth/Register";
import PageViewArticle, {PageViewPost} from "./article/Article";
import {Cookies, CookiesProvider, withCookies} from 'react-cookie';
import PageArticles from "./article/Articles";
import PagePromoter from "./editor/PageEditor";
import PageContent from "./content/Content";
import PageContactUs from "./auth/ContactUs";
import ErrorPage from "./ErrorPage";
import PageSearchboard from "./barqboard/Searchboard";
import PageCategories from "./barqboard/Categories";
import PageCommunityboard from "./community/Community";
import PageBoard from "./barqboard/Board";
import PageLoginForm from "./auth/LoginForm";
import ModernViewBarq from "./barq/ModernViewBarq";
import PageUserStart from "./auth/UserStart";
import PageFeatures from "./article/PageFeatures";
import PageMyInterests from "./auth/MyInterests";
import PageMyMessages from "./auth/MyMessages";
import {Link} from "react-router-dom"

import {IntlProvider} from 'react-intl';
import Hungarian from './i18n/hu.json';
import English from './i18n/hu.json';
import PageAbout from "./about/PageAbout";
import PageChangePassword from "./auth/PageChangePassword";
import PageShare from "./article/PageShare";
import PageBetaAccess from "./beta/PageBetaAccess";

const PubSub = require('pubsub-js');

// Choose between: dev  beta    live
const target_name = packageJson.build_target;
const target = packageJson.targets[target_name];
const app_url = target.app_url;
const server_url = target.server_url;
const server_type = target.server_type;
const version = packageJson.version;
const build = packageJson.build;

const react_user = "barqme-react";
const react_password = "barqme-react-2019";

const cookies_path = '/';
const cookies_age = 60 * 24 * 60 * 60; //60 days
const cookies_settings = {path: cookies_path, maxAge: cookies_age};

class App extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props, context) {
        super(props, context);

        let lang = "hu";
        const {cookies} = this.props;
        if (cookies != null && cookies.get("app_lang") != null)
            lang = cookies.get("app_lang");
        window.barqLang = lang;

        this.state = {
            app: {
                app_url: app_url,
                server_url: server_url,
                access_token: "",
                lang: lang,
                version: version,
                build: build,
                server_type: server_type
            },
            user: null,
            home: null,
            errorCode: null
        };
        this.forceLogin = this.forceLogin.bind(this);
        this.forceLogout = this.forceLogout.bind(this);
        this.setupBetaCode = this.setupBetaCode.bind(this);
    }

    componentDidMount() {
        this.login();
    }

    login() {
        const userName = react_user;
        const password = react_password;
        const {cookies} = this.props;
        if (cookies != null && cookies.get('access_token') != null) {
            this.setState({
                app: {...this.state.app, access_token: cookies.get('access_token')}
            }, () => {
                this.downloadUser();
            });
        } else {
            console.log('NO COOKIE !!!');
            this.forceLogin(userName, password);
        }
    }

    forceLogin(userName, password) {
        const {cookies} = this.props;
        console.log('FORCE LOGIN !!!');
        ajax
            .post(this.state.app.server_url + '/oauth/token')
            .query({"username": userName, "password": password, "grant_type": "password"})
            .set('Authorization', 'Basic YmFycW1lLWdlbmVyYWwtY2xpZW50OnBXSk5BRUNOeTlCYXJxTWUxVzNncEZaeUZZ')
            .accept('application/json')
            .then(res => {
                const token = res.body.access_token;
                this.setState({app: {...this.state.app, access_token: token}}, () => {
                    cookies.set('access_token', token, cookies_settings);
                    if (window.location.toString().includes("/login-form") || window.location.toString().includes("//my-profile")) {
                        window.location.href = "/";
                    } else {
                        this.downloadUser();
                    }
                });
            })
            .catch((err) => {
                if (401 === err.status) {
                    const error = {
                        type: "error",
                        title: "Sikertelen bejelentkezés!",
                        message: "Nem sikerült bejelentkezni a megadott felhasználói névvel és jelszóval!"
                    };
                    PubSub.publish("EVENT_SHOW_MESSAGE", error);
                } else {
                    const error = {type: "error", title: "Sikertelen bejelentkezés!", message: err.error}
                    PubSub.publish("EVENT_SHOW_MESSAGE", error);
                }
            });
    }

    forceLogout() {
        const {cookies} = this.props;
        cookies.remove('access_token', cookies_settings);
        window.location.href = "/";
    }

    reauth() {
        const {cookies} = this.props;
        cookies.remove('access_token', cookies_settings);
        this.login();
    }

    downloadUser() {
        const {cookies} = this.props;
        ajax
            .post(this.state.app.server_url + '/user/home')
            .set('Authorization', 'Bearer ' + this.state.app.access_token)
            .accept('application/json')
            .then(res => {
                const home = res.body;
                const user = home.user;
                if (!user.anonym) {
                    cookies.set('user_name', user.userName, cookies_settings);
                }
                this.setState({home: home, user: user});
            })
            .catch((err) => {
                if (401 === err.status) {
                    this.reauth();
                } else if (err.crossDomain === true) {
                    this.showError("connection_error");
                } else {
                    // alert(JSON.stringify(err));
                    console.log(err);
                    throw err;
                }
            });
    }

    showError(code) {
        this.setState({errorCode: code});
    }

    setupBetaCode(code) {
        const {cookies} = this.props;
        cookies.set('beta_code', code, cookies_settings);
        window.location.href = "/";
    }

    render() {
        const user = this.state.user;
        const target = window.location.toString();
        let dictionary = Hungarian;
        if (this.state.app.lang === "en") {
            dictionary = English;
        }

        const {cookies} = this.props;
        const betaAccessGranted = cookies.get('beta_code') === "1234";

        if (this.state.errorCode != null) {
            return <ErrorPage code={this.state.errorCode} app={this.state.app}/>
        } else if (user && !this.state.user.dateOfTermsOfServices && !target.includes("/page/")) {
            return <Router><PageUserStart app={this.state.app} user={this.state.user} {...this.props} /></Router>
        } else if (this.state.app.server_type !== "LIVE" && !betaAccessGranted) {
            return <Router><PageBetaAccess app={this.state.app} user={this.state.user} {...this.props}
                                           setupBetaCode={this.setupBetaCode}/></Router>
        } else if (user) {
            return <CookiesProvider>
                <IntlProvider locale={this.state.app.lang} messages={dictionary}>
                    <Router>
                        <div>
                            <Switch>
                                <Redirect from="//*" to="/*"/>
                                <Route path="/" exact={true}
                                       component={() => <PageBarqboard app={this.state.app} user={this.state.user} home={this.state.home}/>}/>
                                <Route path="/about" exact={true}
                                       component={() => <PageAbout app={this.state.app} user={this.state.user} home={this.state.home}/>}/>
                                <Route path="/categories" exact={true}
                                       component={() => <PageCategories app={this.state.app} user={this.state.user} home={this.state.home}/>}/>
                                <Route path="/board/:board"
                                       component={(props) => <PageBoard app={this.state.app}
                                                                        user={this.state.user} home={this.state.home} {...props} />}/>

                                <Route path="/barq/:id"
                                       component={(props) => <ModernViewBarq app={this.state.app}
                                                                             user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/barq/:id/:titleUrl"
                                       component={(props) => <ModernViewBarq app={this.state.app}
                                                                             user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/search" exact={true}
                                       component={(props) => <PageSearchboard app={this.state.app}
                                                                              user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/search/:q"
                                       component={(props) => <PageContent mode="search" app={this.state.app}
                                                                          user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/login"
                                       component={(props) => <PageLogin app={this.state.app}
                                                                        user={this.state.user} home={this.state.home} {...props}
                                                                        onLogin={this.forceLogin}/>}/>
                                <Route path="/login-form"
                                       component={(props) => <PageLoginForm app={this.state.app}
                                                                            user={this.state.user} home={this.state.home} {...props}
                                                                            onLogin={this.forceLogin}/>}/>
                                <Route path="/register"
                                       component={(props) => <PageRegister app={this.state.app}
                                                                           onLogin={this.forceLogin}
                                                                           user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/contact-us"
                                       component={(props) => <PageContactUs app={this.state.app}
                                                                            user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/my-profile"
                                       component={(props) => <PageMyProfile app={this.state.app}
                                                                            user={this.state.user} home={this.state.home} {...props}
                                                                            onLogout={this.forceLogout}/>}/>
                                <Route path="/my-interests"
                                       component={(props) => <PageMyInterests app={this.state.app}
                                                                              user={this.state.user} home={this.state.home} {...props}/>}/>
                                <Route path="/my-messages"
                                       component={(props) => <PageMyMessages app={this.state.app}
                                                                             user={this.state.user} home={this.state.home} {...props}/>}/>
                                <Route path="/change-password"
                                       component={(props) => <PageChangePassword app={this.state.app}
                                                                                 user={this.state.user} home={this.state.home} {...props}/>}/>
                                <Route path="/page/features"
                                       component={(props) => <PageFeatures app={this.state.app} exact={true}
                                                                           user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/page/share"
                                       component={(props) => <PageShare app={this.state.app} exact={true}
                                                                        user={this.state.user} home={this.state.home} {...props} />}/>

                                <Route path="/page/:code" component={(props) => <PageViewArticle app={this.state.app}
                                                                                                 user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/post/:code" component={(props) => <PageViewPost app={this.state.app}
                                                                                              user={this.state.user} home={this.state.home} {...props} />}/>

                                <Route path="/community" component={(props) => <PageCommunityboard app={this.state.app}
                                                                                                   user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/blog" exact={true}
                                       component={(props) => <PageArticles app={this.state.app}
                                                                           user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/blog/:promoter" exact={true}
                                       component={(props) => <PageArticles app={this.state.app}
                                                                           user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="/blog/:promoter/:category" exact={true}
                                       component={(props) => <PageArticles app={this.state.app}
                                                                           user={this.state.user} home={this.state.home} {...props} />}/>

                                <Route path="/editor" component={(props) => <PagePromoter app={this.state.app}
                                                                                          user={this.state.user} home={this.state.home} {...props} />}/>
                                <Route path="*" exact={true}
                                       component={(props) => <ErrorPage code="404" app={this.state.app}/>}
                                       status={404}/>
                            </Switch>
                        </div>
                    </Router>
                </IntlProvider>
            </CookiesProvider>
        } else {
            return null;
        }
    }

}

export default withCookies(App);
