import React, {Component} from "react";
import "./ErrorPage.css";
import BarqMeCookies from "./BarqMeCookies";

class ErrorPage extends Component {


    constructor(props, context) {
        super(props, context);
        const errorCode = this.props.code;
        let title = "Hiba";
        let message = "";
        let large = ";(";
        if (errorCode === "connection_error") {
            title = "Kapcsolódási hiba!";
            message = "A weboldal nem tudott kapcsolódni a barq.me szerverhez. Ez akkor fordulhat elő, ha a szerver karbantartás vagy valamilyen hiba miatt leállt. Bizonyára dolgozunk a probláma elhárításán, kérjük látogass vissza később!";
        } else if (errorCode === "404") {
            title = "Az oldal nem található!";
            large = "404";
            message = "A kért weboldal nem található. Sajnáljuk, valami nem jó!";
        }
        this.state = {code: errorCode, title: title, large: large, message: message};
        this.reloadMe = this.reloadMe.bind(this);
        this.goHome = this.goHome.bind(this);
    }

    reloadMe(event) {
        window.location.reload();
        event.preventDefault();
    }

    goHome(event) {
        window.location = this.props.app.app_url;
        event.preventDefault();
    }

    render() {
        return <div>
            <BarqMeCookies/>
            <div className="ErrorContainer ThemeBackground ">
                <div className="ErrorImage"></div>
                <div className="Large">
                    {this.state.large}
                </div>

                <div className="Error">
                    <h1 className="Ooops ThemeColor">Upsz!</h1>
                    <h1 className="Title ThemeColor">{this.state.title}</h1>
                    <p className="Message ThemeColor">{this.state.message}<br/>
                        <button className="BorderButton ThemeBackground" onClick={this.reloadMe}>Oldal újratöltése</button> <button className="BorderButton ThemeBackground" onClick={this.goHome}>Ugrás a főoldalra</button>
                    </p>
                </div>
            </div>
        </div>
    }
}

export default ErrorPage;