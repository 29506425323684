import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import './ArticleParagraphPairs.css'

import {Redirect} from "react-router-dom";
import {NewParagraphButtons} from "./ParagraphBlock";
import {Padding} from "../../components/Components";
import BarqMeLink from "../../components/BarqMeLink";

const PubSub = require('pubsub-js');


class ArticleParagraphPairs extends Component {

    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <ArticleParagraphPairsEditMode paragraph={paragraph} addParagraph={this.props.addParagraph}
                                                    moveParagraph={this.props.moveParagraph}
                                                    app={this.props.app}/>
        } else {
            return <ArticleParagraphPairsView paragraph={paragraph} app={this.props.app}/>
        }
    }
}

class ArticleParagraphPairsView extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {execute: null};
        this.executeAction = this.executeAction.bind(this);
        this.pairSelected = this.pairSelected.bind(this);
    }

    executeAction() {
        return <Redirect to={'/search/' + this.state.execute.action}/>;
    }

    pairSelected(pair) {
        window.location = this.props.app.app_url + "/search/" + pair.action;
    }

    render() {
        if (this.state.execute != null) {
            return this.executeAction();
        } else {
            const paragraph = this.props.paragraph;
            let i = 0;
            const pairsView = paragraph.pairs.map((pair) => {
                i = (i + 1) % 14;
                return <div key={"pairs"+pair.title+"-"+pair.text} className="ArticleParagraphPairs ThemeBackground">
                    <span className="Key">{pair.title}:</span>
                    {pair.action && <span className="Name"> <BarqMeLink action={pair.action}>{pair.text}</BarqMeLink></span>}
                    {!pair.action && <span className="Name">{pair.text}</span>}
                </div>
            })
            return <div style={{marginLeft: 10, marginTop: 10}}>
                {paragraph.title && <h2>{paragraph.title}</h2>}
                {pairsView}
                <div style={{clear: 'both'}}/>
            </div>
        }
    }
}

class ArticleParagraphPairsEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);
    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }

    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(title, pairs) {
        this.setState({paragraph: {...this.state.paragraph, ...{title: title, pairs: pairs}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });

    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <div>

            {!showEditor && <div><ArticleParagraphPairsView paragraph={paragraph}/> <Padding>
                <button onClick={this.showEditor}>...</button>
            </Padding></div>}

            {showEditor &&
            <ArticleParagraphPairsEditor title={paragraph.title} pairs={paragraph.pairs}
                                           buttons={<NewParagraphButtons onRemove={this.remove}
                                                                         onAddParagraph={this.addParagraph}/>}
                                           onCancel={this.hideEditor} onSave={this.save}/>}
        </div>
    }
}

class ArticleParagraphPairsEditor extends Component {


    constructor(props, context) {
        super(props, context);

        let pairs = [{title: "", action: "SEARCH:"}];
        if (this.props.pairs && this.props.pairs.length > 0) pairs = this.props.pairs
        this.state = {
            title: this.props.title,
            pairs: pairs
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.onActionChanged = this.onActionChanged.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.title, this.state.pairs);
    }

    onTitleChanged(index, event) {
        const target = event.target;
        const value = target.value;
        const pairs = this.state.pairs;
        pairs[parseInt(index)].title = value
        this.setState({pairs: pairs});
    }

    onTextChanged(index, event) {
        const target = event.target;
        const value = target.value;
        const pairs = this.state.pairs;
        pairs[parseInt(index)].text = value
        this.setState({pairs: pairs});
    }


    onActionChanged(index, event) {
        const target = event.target;
        const value = target.value;
        const pairs = this.state.pairs;
        pairs[parseInt(index)].action = value
        this.setState({pairs: pairs});
    }

    addNewItem() {
        const pairs = this.state.pairs;
        pairs.push({title: "", text:"", action: "SEARCH:"})
        this.setState({pairs: pairs})
    }

    deleteItem(index) {
        const pairs = this.state.pairs;
        pairs.splice(index, 1)
        this.setState({pairs: pairs});
    }

    render() {
        return <div>
            <div className="ThemeSolidBackground BlockEditorTitle">
                <button className="BlockEditorTitleButton" onClick={this.addNewItem}>Új elem</button>
            </div>

            {this.state.pairs.map((pair, i) => {
                return <div className="ArticleParagraphPairsEditorElement">
                    <input className="ArticleParagraphPairsEditorField" type="text" value={pair.title}
                           onChange={(e) => this.onTitleChanged(i, e)}/>:
                    <input className="ArticleParagraphPairsEditorField" type="text" value={pair.text}
                           onChange={(e) => this.onTextChanged(i, e)}/>:

                    <input className="ArticleParagraphPairsEditorField" type="text" value={pair.action}
                           onChange={(e) => this.onActionChanged(i, e)}/>

                    <button className="grayButton ParagraphButton ParagraphButtonTrash" onClick={(e) => this.deleteItem(i)}>&nbsp;</button>
                </div>
            })}

            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


export default ArticleParagraphPairs;