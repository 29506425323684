import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import "./PostsSection.css"
import {ViewArticlePanel} from "../../article/Article";
import {Link} from "react-router-dom";
import SectionHeader from "./SectionHeader";
import {translateMessage} from "../../i18n/TranslateMessage";
import ShowPostDialog from "../../auth/ShowPostDialog";
import {toast, ToastContainer} from "react-toastify";

var moment = require('moment-timezone');

class PostsSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {content: props.section, posts: null, post: null};
        this.showPost = this.showPost.bind(this);
        this.hidePost = this.hidePost.bind(this);
        this.deletePost = this.deletePost.bind(this);
    }

    downloadPosts() {
        ajax
            .post(this.props.app.server_url + '/section/get')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": this.props.section.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({content: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }

    showPost(post) {
        const posts = this.state.content?.posts?.posts;
        const index = posts.findIndex((p) => p.code === post.code);
        if (index >= 0) {
            //Remove
            let postsNew = [...posts];
            postsNew.splice(index, 1)

            const content = this.state.content;
            content.posts.posts = postsNew;
            this.setState({content: content, post: post})
        }
    }

    hidePost() {
        this.setState({post: null});
    }

    deletePost(post) {
        ajax
            .post(this.props.app.server_url + '/post/delete')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": post.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Az üzenetet töröltük.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                this.setState({post: null}, (e)=> {
                    this.downloadPosts();
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        // const section = this.props.section;
        const posts = this.state.content?.posts?.posts;
        if (posts != null) {
            return (
                <div className="PaddingDependsOnWidth">
                    <div className="Section ModernSection SectionWidthContainer ThemeBackground">
                        <SectionHeader title={this.state.content.title} button={translateMessage("barqboard_section_button_all_empty")} link={"/my-messages"}/>
                        <div className="PostSectionItemContainer">
                            {posts.map((post) =>
                                <PostSectionItem key={'post-' + post.code} post={post}
                                                 onShow={this.showPost}
                                                 app={this.props.app} user={this.props.user}/>
                            )}
                        </div>
                    </div>
                    <ShowPostDialog app={this.props.app} user={this.props.user} display={this.state.post != null}
                                    postCode={this.state.post?.code}
                                    onDelete={this.deletePost}
                                    onClose={this.hidePost}/>
                    <ToastContainer/>
                </div>
            );
        } else {
            return <div
                className="Section SectionWidthContainer BarqboardBarqListContainer BarqboardBarqListContainerLoading"/>
        }

    }
}


class PostSectionItem extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {text: ""};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    render() {
        const post = this.props.post;
        return <div className="PostSectionItem ThemeSolidBackground HandCursor" onClick={(e) => this.props.onShow(post)}>

            <div className="PostSectionItemAuthor">
                <div className="PostSectionItemImage"
                     style={{backgroundImage: 'url(' + post.promoter.imageLogo + ")"}}>&nbsp;</div>
                <div className="PostSectionItemTitle">
                    {post.postAuthor}
                </div>
            </div>
            <div className="PostSectionItemContent">
                <div className="PostSectionItemTitle">
                    {post.article.title}
                </div>
                <div className="PostSectionItemText">
                    {post.article.excerpt}
                </div>
            </div>

        </div>
    }


}


export default PostsSection;
