import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import './Article.css';
import Container, {Padding} from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import ajax from "superagent";

import BarqMeCookies from "../BarqMeCookies";
import {ViewArticleParagraph} from "./paragraph/ParagraphBlock";
import BarqMeTopbar from "../topbar/Topbar";
import Loading from "../components/Loading";
import ArticleParagraphFeatures from "./paragraph/ArticleParagraphFeatures";

class PageViewArticle extends Component {


    constructor(props, context) {
        super(props, context);
        const code = this.props.article || this.props.match.params.code;
        this.addParagraph = this.addParagraph.bind(this);
        this.state = {code: code};
    }


    componentDidMount() {
        this.downloadArticle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevCode = prevProps.article || prevProps.match.params.code;
        const code = this.props.article || this.props.match.params.code;
        if (prevCode !== code) {
            this.setState({code: code, article: null}, () => {
                this.downloadArticle();
            });
        }
    }

    downloadArticle() {
        ajax
            .post(this.props.app.server_url + '/article/get')
            .query({
                "platform": "react",
                "lang": this.props.app.lang,
                "code": this.state.code, editorMode: this.props.editorMode
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                // const log = JSON.stringify(res.body);
                // console.log(log);
                this.setState({article: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    addParagraph() {

    }

    render() {
        if (this.state.article != null) {
            const article = this.state.article;
            return <div>
                <Helmet>
                    <title>barq.me | {article.title}</title>
                    <meta name="description" lang="hu" content={'barq.me: ' + article.title + ", " + article.excerp}/>
                </Helmet>
                <BarqMeCookies/>
                <Sidebar>
                    <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
                </Sidebar>
                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    <ViewArticlePanel app={this.props.app} user={this.props.user} article={article}
                                      addParagraph={this.addParagraph}/>
                    {this.props.children}
                    <Padding>
                        {/*{this.props.nodownload || <div style={{paddingTop: 20}}><b>A cikk letölthető pdf formátumban: <a*/}
                        {/*    href={this.props.app.server_url + '/article/pdf-info?code=' + article.code}>Infógrafika</a> | <a*/}
                        {/*    href={this.props.app.server_url + '/article/pdf-doc?code=' + article.code}>Dokumentum</a>*/}
                        {/*</b></div>}*/}
                        {this.props.user.superAdmin &&
                            <a href={this.props.app.app_url + "/editor#tab=articles&code=" + article.code}>Cikk
                                szerkesztése</a>}
                    </Padding>
                </Container>
            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

class PageViewPost extends Component {


    constructor(props, context) {
        super(props, context);
        const code = this.props.post || this.props.match.params.code;
        this.addParagraph = this.addParagraph.bind(this);
        this.state = {code: code};
    }


    componentDidMount() {
        this.downloadPost();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevCode = prevProps.post || prevProps.match.params.code;
        const code = this.props.post || this.props.match.params.code;
        if (prevCode !== code) {
            this.setState({code: code, post: null}, () => {
                this.downloadPost();
            });
        }
    }

    downloadPost() {
        ajax
            .post(this.props.app.server_url + '/post/read')
            .query({
                "platform": "react",
                "lang": this.props.app.lang,
                "code": this.state.code, editorMode: this.props.editorMode
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert(JSON.stringify(res.body))
                this.setState({post: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    addParagraph() {

    }

    render() {
        if (this.state.post != null) {
            const article = this.state.post.article;
            return <div>
                <Helmet>
                    <title>barq.me | {article.title}</title>
                    <meta name="description" lang="hu" content={'barq.me: ' + article.title + ", " + article.excerp}/>
                </Helmet>
                <BarqMeCookies/>
                <Sidebar>
                    <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
                </Sidebar>
                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    <ViewArticlePanel app={this.props.app} user={this.props.user} article={article}
                                      addParagraph={this.addParagraph}/>
                    {this.props.children}
                </Container>
            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

class DownloadArticle extends Component {

    constructor(props, context) {
        super(props, context);
        const code = this.props.article || this.props.match.params.code;
        this.state = {code: code};
        this.dummy = this.dummy.bind(this);
    }


    componentDidMount() {
        this.downloadArticle();
    }

    downloadArticle() {
        ajax
            .post(this.props.app.server_url + '/article/get')
            .query({
                "platform": "react",
                "lang": this.props.app.lang,
                "code": this.state.code,
                hideTitle: this.props.hideTitle === true,
                editorMode: this.props.editorMode
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({article: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    dummy() {

    }

    render() {
        if (this.state.article != null) {
            const article = this.state.article;
            return <ViewArticlePanel app={this.props.app} user={this.props.user} article={article}
                                     addParagraph={this.dummy} moveParagraph={this.dummy}/>
        } else {
            return <Loading loading={true}/>
        }
    }

}

class ViewArticlePanel extends Component {
    constructor(props, context) {
        super(props, context);
        if (this.props.addParagraph) {
            this.addParagraph = this.props.addParagraph.bind(this);
        } else {
            this.addParagraph = this.addParagraph.bind(this);
        }
        if (this.props.moveParagraph) {
            this.moveParagraph = this.props.moveParagraph.bind(this);
        } else {
            this.moveParagraph = this.moveParagraph.bind(this);
        }
    }

    addParagraph() {
        alert("NOT AVAILABLE HERE");
    }

    moveParagraph() {
        alert("NOT AVAILABLE HERE");
    }

    render() {
        let containerClass = "FullContainer";
        if (this.props.dialogMode === true)
            containerClass = "DialogContainer";
        const article = this.props.article;
        if (article?.paragraphs) {
            const paragraphs = article?.paragraphs;
            const editorMode = this.props.editorMode === true;
            let embedSearch = this.props.embedSearch === true;
            const paragraphViews = paragraphs.map((paragraph) => {
                return <ViewArticleParagraph key={paragraph.code} article={article} paragraph={paragraph}
                                             collectionClass={this.props.collectionClass} viewMode={this.props.viewMode}
                                             noPadding={this.props.noPadding} settings={this.props.settings}
                                             editorMode={editorMode} embedSearch={embedSearch}
                                             addParagraph={this.addParagraph} moveParagraph={this.moveParagraph}
                                             app={this.props.app} user={this.props.user}/>
            });
            return <div className={containerClass}>{paragraphViews}</div>
        } else {
            return null;
        }
    }
}

class ViewContentArticle extends Component {
    render() {
        const article = this.props.article;
        if (article?.paragraphs) {
            const paragraphs = article?.paragraphs;
            const editorMode = false;
            let embedSearch = false;
            const paragraphViews = paragraphs.map((paragraph) => {
                console.log("dd: " + this.props.filterPlace + " ---- " + paragraph.place);
                if (this.props.filterPlace == null || this.props.filterPlace === paragraph.place)
                    return <ViewArticleParagraph key={paragraph.code} article={article} paragraph={paragraph}
                                                 collectionClass={this.props.collectionClass}
                                                 viewMode={this.props.viewMode}
                                                 noPadding={this.props.noPadding} settings={this.props.settings}
                                                 editorMode={editorMode} embedSearch={embedSearch}
                                                 switchTab={this.props.switchTab}
                                                 tabSelected={this.props.tabSelected}
                                                 app={this.props.app} user={this.props.user}/>
            });
            return <div>{paragraphViews}</div>
        } else {
            return null;
        }
    }
}

class ViewHeaderPanel extends Component {
    constructor(props, context) {
        super(props, context);
        this.dummy = this.dummy.bind(this);
    }

    dummy() {
        alert("NOT AVAILABLE HERE");
    }


    render() {
        const article = this.props.article;
        const paragraphs = article.paragraphs;
        const editorMode = this.props.editorMode === true;
        let embedSearch = this.props.embedSearch === true;

        const paragraphViews = paragraphs.map((paragraph) => {
            if (paragraph.place === "header")
                return <ViewArticleParagraph key={paragraph.code} article={article} paragraph={paragraph}
                                             noPadding={this.props.noPadding} settings={this.props.settings}
                                             editorMode={editorMode} embedSearch={embedSearch}
                                             addParagraph={this.dummy} moveParagraph={this.dummy}
                                             app={this.props.app} user={this.props.user}/>
            else
                return null
        });


        return <div>{paragraphViews}</div>
    }
}

class ViewContentPanel extends Component {
    constructor(props, context) {
        super(props, context);
        this.dummy = this.dummy.bind(this);
    }

    dummy() {
        alert("NOT AVAILABLE HERE");
    }


    render() {
        const article = this.props.article;
        const paragraphs = article.paragraphs;
        const editorMode = this.props.editorMode === true;
        let embedSearch = this.props.embedSearch === true;

        const paragraphViews = paragraphs.map((paragraph) => {
            console.log("P")
            console.log(paragraph);
            if (paragraph.place !== "header")
                return <ViewArticleParagraph key={paragraph.code} article={article} paragraph={paragraph}
                                             collectionClass={this.props.collectionClass} viewMode={this.props.viewMode}
                                             noPadding={this.props.noPadding} settings={this.props.settings}
                                             editorMode={editorMode} embedSearch={embedSearch}
                                             addParagraph={this.dummy} moveParagraph={this.dummy}
                                             app={this.props.app} user={this.props.user}/>
            else
                return null
        });


        return <div>{paragraphViews}</div>
    }
}


export default PageViewArticle;
export {PageViewPost, DownloadArticle, ViewArticlePanel, ViewHeaderPanel, ViewContentPanel, ViewContentArticle};