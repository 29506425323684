//TranslateMessage: completed
import React, {Component} from "react";
import "./SearchPanelAnimation.css";
import "./SearchPanel.css";
import "./SearchPanelSmall.css";
import "./SearchPanelSidebar.css";
import {SearchRelatedTags} from "./SearchTags";

import queryString from "query-string";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from 'react-cookie';
import SpicesDialog from "./SpicesDialog";
import DateDialog from "./DateDialog";
import moment from "moment";
import SelectCityDialog from "./SelectCityDialog";
import SearchTextDialog from "./SearchTextDialog";
import {translateMessage, TranslateMessage} from "../i18n/TranslateMessage";
import HistoryDialog from "./HistoryDialog";


class SearchPanel extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };


    constructor(props, context) {
        super(props, context);
        const form = this.props.form;
        const searchData = this.props.section?.searchData;
        const values = queryString.parse(window.location.hash);
        const mode = this.props.mode || values["mode"];
        const hideSearch = values["hide_search"] === "1";


        let dateAfter = null;
        if (form?.dateAfter)
            dateAfter = moment(form.dateAfter, "YYYY-MM-DD")
        else if (searchData?.filter?.dateAfter)
            dateAfter = moment(searchData?.filter?.dateAfter, "YYYY-MM-DD")

        let dateBefore = null;
        if (form?.dateBefore)
            dateBefore = moment(form.dateBefore, "YYYY-MM-DD")
        else if (searchData?.filter?.dateBefore)
            dateBefore = moment(searchData?.filter?.dateBefore, "YYYY-MM-DD")

        let text = ""
        if (form?.text)
            text = form?.text
        else if (searchData?.text)
            text = searchData?.text

        let textWithoutSpices = ""
        if (form?.textWithoutSpices)
            textWithoutSpices = form?.textWithoutSpices
        else if (searchData?.textWithoutSpices)
            textWithoutSpices = searchData?.textWithoutSpices

        let spiceValues = [];
        if (form?.spiceValues)
            spiceValues = form?.spiceValues
        else if (searchData?.spiceValues)
            spiceValues = searchData?.spiceValues
        this.state = {
            mode: mode,
            hideSearch: hideSearch,
            text: text,
            textWithoutSpices: textWithoutSpices,
            dateAfter: dateAfter,
            dateBefore: dateBefore,
            spices: form?.spices,
            spiceValues: spiceValues,
            showCitySelection: false,
            showSpices: false,
            showOffers: false,
            selectDateAfter: false,
            selectDateBefore: false,
            showHistory: false
        };
        this.onTextChanged = this.onTextChanged.bind(this);

        this.onValueChanged = this.onValueChanged.bind(this);
        this.onTagSelect = this.onTagSelect.bind(this);

        this.onTagSubmit = this.onTagSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.showOffers = this.showOffers.bind(this);
        this.hideOffers = this.hideOffers.bind(this);

        this.hide = this.hide.bind(this);
        this.closeAllPopup = this.closeAllPopup.bind(this);

        this.clearText = this.clearText.bind(this);

        this.selectDateAfter = this.selectDateAfter.bind(this);
        this.selectDateBefore = this.selectDateBefore.bind(this);
        this.cancelSelectDate = this.cancelSelectDate.bind(this);
        this.dateAfterSelected = this.dateAfterSelected.bind(this);
        this.dateBeforeSelected = this.dateBeforeSelected.bind(this);

        this.showCitySelection = this.showCitySelection.bind(this);
        this.hideCitySelection = this.hideCitySelection.bind(this);
        this.selectCity = this.selectCity.bind(this);

        this.showSpices = this.showSpices.bind(this);
        this.hideSpices = this.hideSpices.bind(this);
        this.setSpiceValues = this.setSpiceValues.bind(this);
        this.searchSpiceValues = this.searchSpiceValues.bind(this);
        this.clearAllSpice = this.clearAllSpice.bind(this);
        this.clearDateAfter = this.clearDateAfter.bind(this);
        this.clearDateBefore = this.clearDateBefore.bind(this);

        this.showHistory = this.showHistory.bind(this);
        this.closeHistory = this.closeHistory.bind(this);
        this.historySelected = this.historySelected.bind(this);
    }

    collectSubmitText(text) {
        let result = "";

        let searchText = this.state.textWithoutSpices.trim();
        if (text != null && text.trim().length > 0) searchText = text.trim();

        if (searchText.length > 0) {
            result = searchText;
        }

        if (this.state.spiceValues?.length > 0) {
            result = result + " " + this.state.spiceValues.map(s => s.tag).join(' ');
        }

        if (this.state.dateAfter) result = result + " filter.dateAfter:" + moment(this.state.dateAfter).format("YYYY-MM-DD");
        if (this.state.dateBefore) result = result + " filter.dateBefore:" + moment(this.state.dateBefore).format("YYYY-MM-DD");
        return result.trim();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.text != this.props.text) {
            let form = this.props.form;
            const dateAfter = form?.dateAfter ? moment(form.dateAfter, "YYYY-MM-DD") : null
            const dateBefore = form?.dateBefore ? moment(form.dateBefore, "YYYY-MM-DD") : null
            this.setState({
                text: form?.text || "",
                textWithoutSpices: form?.textWithoutSpices || "",
                dateAfter: dateAfter,
                dateBefore: dateBefore,
                spices: form?.spices,
                spiceValues: form?.spiceValues,
                showSpices: false,
                showOffers: false,
                selectDateAfter: false,
                selectDateBefore: false
            })
        }
    }

    onTextChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        // alert("NAME: " + name + " VALUE: " + value);
        this.setState({[name]: value});
    }

    onValueChanged(text) {
        this.setState({text: text}, () => {
            this.hide()
        });
    }

    onTagSubmit(text) {
        this.closeAllPopup();
        this.props.onSearchTextChanged("SEARCH:" + this.collectSubmitText(text))
    }

    submit() {
        this.onTagSubmit(null);
    }

    onTagSelect(text) {
        this.setState({textWithoutSpices: text + " ", showOffers: false}, () => {
        });
    }

    showOffers() {
        this.setState({
            showOffers: true,
            showDateAfter: false,
            showDateBefore: false
        });
    }

    hideOffers() {
        this.setState({
            showOffers: false,
        });
    }

    hide() {
        this.setState({
            showOffers: false,
            showDateAfter: false,
            showDateBefore: false
        });
    }

    selectDateAfter() {
        this.setState({selectDateAfter: true});
    }

    selectDateBefore() {
        this.setState({selectDateBefore: true});
    }

    cancelSelectDate() {
        this.setState({selectDateAfter: false, selectDateBefore: false});
    }

    dateAfterSelected(dateAfter) {
        this.setState({selectDateAfter: false, dateAfter: dateAfter});
    }

    dateBeforeSelected(dateBefore) {
        this.setState({selectDateBefore: false, dateBefore: dateBefore});
    }


    closeAllPopup() {
        this.setState({
            showCitySelection: false,
            showSpices: false,
            showOffers: false,
            showDateAfter: false,
            showDateBefore: false,
            showHistory: false
        });
    }

    clearText() {
        this.setState({text: "", textWithoutSpices: ""});
    }

    clearDateAfter() {
        this.setState({dateAfter: ""});
    }

    clearDateBefore() {
        this.setState({dateBefore: ""});
    }

    showCitySelection() {
        this.setState({showCitySelection: true});
    }

    hideCitySelection() {
        this.setState({showCitySelection: false});
    }

    selectCity(city) {
        const spiceValues = this.state.spiceValues;
        const cityIndex = spiceValues.findIndex(s => s.type === 1);
        if (cityIndex >= 0) spiceValues.splice(cityIndex, 1);
        if (city) {
            spiceValues.push(city.tag);
        }
        this.setState({spiceValues: spiceValues}, () => {
            this.hideCitySelection();
        });


    }

    showSpices() {
        this.setState({showSpices: true});
    }

    hideSpices() {
        this.setState({showSpices: false});
    }

    setSpiceValues(spiceValues) {
        const keepCity = this.state.spiceValues?.filter(s => s.type === 1);
        let values = [...spiceValues];
        if (keepCity[0]) values.push(keepCity[0]);
        this.setState({spiceValues: values}, (e) => {
            this.hideSpices();
        });
    }

    searchSpiceValues(spiceValues) {
        const keepCity = this.state.spiceValues?.filter(s => s.type === 1);
        let values = [...spiceValues];
        if (keepCity[0]) values.push(keepCity[0]);
        this.setState({spiceValues: values}, (e) => {
            this.hideSpices();
            this.submit();
        });
    }

    clearAllSpice() {
        //TODO: Keep city
        const keepCity = this.state.spiceValues?.filter(s => s.type === 1 || s.type === 18);
        if (keepCity) {
            this.setState({spiceValues: keepCity});
        } else {
            this.setState({spiceValues: []});
        }
    }

    showHistory() {
        this.setState({showHistory: true});
    }

    closeHistory() {
        this.setState({showHistory: false});
    }

    historySelected(history) {
        this.closeAllPopup();
        this.props.onSearchTextChanged("SEARCH:" + history.text)
    }

    render() {
        const user = this.props.user;
        let spiceButtonTitle = translateMessage("websearch_spices_text");
        const spiceValuesToDisplay = this.state.spiceValues?.filter(s => s.type !== 1 && s.type !== 18);
        if (spiceValuesToDisplay?.length > 0) {
            spiceButtonTitle = spiceValuesToDisplay.map(s => s.display).join(', ');
        }

        const searchTextButtonTitle = this.state.textWithoutSpices || translateMessage("websearch_search_text")

        if (!this.state.hideSearch) {
            return <div>

                {(this.state.mode === "sidebar") &&
                <div className="SearchPanelSidebar CloseOverlayOver">
                    <div className="SearchPanelContent ThemeBackground">
                        <h2 className="SearchPanelTitle">
                            <TranslateMessage id="filter_text_title" defaultMessage="Keresés"/> <span className={"HighlightColor HandCursor"}
                                          onClick={this.showCitySelection}>{this.state.spiceValues?.find((p) => p.type === 1)?.display || translateMessage("search_city_all", "Minden városban")}</span>
                        </h2>
                        <p><TranslateMessage id="search_text" defaultMessage="Milyen programokat keresel?"/></p>

                        <div className="Full ThemeSolidBackground">
                            <div className="Icon SpiceIcon"/>
                            <button className="Button ThemeSolidBackground ThemeColor"
                                    onClick={this.showSpices}
                                    id="spices">{spiceButtonTitle}</button>
                            <button className="ClearButton" onClick={this.clearAllSpice}/>
                        </div>


                        <div className="Full ThemeSolidBackground">
                            <div className="Icon TextIcon"/>
                            <button className="Button ThemeSolidBackground ThemeColor"
                                    onClick={this.showOffers}
                                    id="searchText">{searchTextButtonTitle}</button>
                            <button className="ClearButton" onClick={this.clearText}/>
                        </div>
                        <div className="SearchPanelTri">
                            <div className="Left ThemeSolidBackground">
                                <div className="Icon CalendarIcon"/>
                                <button className="Button ThemeSolidBackground ThemeColor"
                                        onClick={this.selectDateAfter}
                                        id="spices">{this.state.dateAfter ? moment(this.state.dateAfter).format("YYYY.MM.DD") : translateMessage("search_date_after", "Első nap")}</button>
                                <button className="ClearButton" onClick={this.clearDateAfter}/>
                            </div>
                            <div className="Center ThemeSolidBackground">
                                <div className="Icon CalendarIcon"/>
                                <button className="Button ThemeSolidBackground ThemeColor"
                                        onClick={this.selectDateBefore}
                                        id="spices">{this.state.dateBefore ? moment(this.state.dateBefore).format("YYYY.MM.DD") : translateMessage("search_date_before", "Utolsó nap")}</button>
                                <button className="ClearButton" onClick={this.clearDateBefore}/>
                            </div>
                            <button className="Right SearchPanelContentButton" onClick={this.submit}><TranslateMessage id="search_button"/>
                            </button>
                        </div>
                    </div>
                </div>
                }


                {(this.state.mode === "full") && <div>
                    <div className={"SearchImage "}>
                        <div className="SearchPanelLarge CloseOverlayOver">
                            <div className="SearchPanelContent ThemeBackground">
                                <h2 className="SearchPanelTitle">
                                    <TranslateMessage id="filter_text_title" defaultMessage="Keresés"/> <span className={"HighlightColor HandCursor"}
                                                  onClick={this.showCitySelection}>{this.state.spiceValues?.find((p) => p.type === 1)?.display || translateMessage("search_city_all", "Minden városban")}</span>
                                    {(user !=null && !user?.anonym) && <button className="SearchPanelTitleHistory" onClick={this.showHistory}/>}
                                </h2>
                                <p>
                                    <TranslateMessage id="search_text" defaultMessage="Milyen programokat keresel?"/>
                                    <div className="SearchArticleLink"><TranslateMessage id="search_text_interesting"/> <a className="ColorLink" target="_blank" href="/page/search-introduction"><TranslateMessage id="search_text_interesting_link"/></a></div>
                                </p>

                                <div className="Full ThemeSolidBackground">
                                    <div className="Icon SpiceIcon"/>
                                    <button className="Button ThemeSolidBackground ThemeColor"
                                            onClick={this.showSpices}
                                            id="spices">{spiceButtonTitle}</button>
                                    <button className="ClearButton" onClick={this.clearAllSpice}/>
                                </div>


                                <div className="Full ThemeSolidBackground">
                                    <div className="Icon TextIcon"/>
                                    <button className="Button ThemeSolidBackground ThemeColor"
                                            onClick={this.showOffers}
                                            id="searchText">{searchTextButtonTitle}</button>
                                    <button className="ClearButton" onClick={this.clearText}/>
                                </div>
                                <div className="SearchPanelTri">
                                    <div className="Left ThemeSolidBackground">
                                        <div className="Icon CalendarIcon"/>
                                        <button className="Button ThemeSolidBackground ThemeColor"
                                                onClick={this.selectDateAfter}
                                                id="spices">{this.state.dateAfter ? moment(this.state.dateAfter).format("YYYY.MM.DD") : translateMessage("search_date_after", "Első nap")}</button>
                                        <button className="ClearButton" onClick={this.clearDateAfter}/>
                                    </div>
                                    <div className="Center ThemeSolidBackground">
                                        <div className="Icon CalendarIcon"/>
                                        <button className="Button ThemeSolidBackground ThemeColor"
                                                onClick={this.selectDateBefore}
                                                id="spices">{this.state.dateBefore ? moment(this.state.dateBefore).format("YYYY.MM.DD") : translateMessage("search_date_before", "Utolsó nap")}</button>
                                        <button className="ClearButton" onClick={this.clearDateBefore}/>
                                    </div>
                                    <button className="Right SearchPanelContentButton" onClick={this.submit}><TranslateMessage id="search_button"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(this.state.mode === "compact") && <div className={""}>
                    <div className="SearchPanelSmall ThemeBackground">
                        <div className="SearchPanelContent CloseOverlayOver ThemeBackground">


                            <div className="Field City ThemeSolidBackground">
                                <div className="Icon CityIcon"/>
                                <button className="SpicesButton ThemeSolidBackground ThemeColor"
                                        onClick={this.showCitySelection}
                                        id="city">{this.state.spiceValues?.find((p) => p.type === 1)?.display || translateMessage("search_city_all", "Minden városban")}</button>
                                <button className="ClearButton" onClick={(e) => this.selectCity(null)}/>
                            </div>


                            <div className="Field Spices ThemeSolidBackground">
                                <div className="Icon SpiceIcon"/>
                                <button className="SpicesButton ThemeSolidBackground ThemeColor"
                                        onClick={this.showSpices}
                                        id="spices">{spiceButtonTitle}</button>
                                <button className="ClearButton" onClick={this.clearAllSpice}/>
                            </div>

                            <div className="Field Text ThemeSolidBackground">
                                <div className="Icon TextIcon"/>
                                <button className="SpicesButton ThemeSolidBackground ThemeColor"
                                        onClick={this.showOffers}
                                        id="searchText">{searchTextButtonTitle}</button>
                                <button className="ClearButton" onClick={this.clearText}/>
                            </div>
                            <div className="Field Date DateAfter ThemeSolidBackground">
                                <div className="Icon CalendarIcon"/>
                                <button className="Button ThemeSolidBackground ThemeColor"
                                        onClick={this.selectDateAfter}
                                        id="spices">{this.state.dateAfter ? moment(this.state.dateAfter).format("YYYY.MM.DD") : translateMessage("search_date_after", "Első nap")}</button>
                                <button className="ClearButton" onClick={this.clearDateAfter}/>
                            </div>
                            <div className="Field Date DateBefore ThemeSolidBackground">
                                <div className="Icon CalendarIcon"/>
                                <button className="Button ThemeSolidBackground ThemeColor"
                                        onClick={this.selectDateBefore}
                                        id="spices">{this.state.dateBefore ? moment(this.state.dateBefore).format("YYYY.MM.DD") : translateMessage("search_date_before", "Utolsó nap")}</button>
                                <button className="ClearButton" onClick={this.clearDateBefore}/>
                            </div>


                            <button className="Right SearchPanelContentButton" onClick={this.submit}><TranslateMessage id="search_button"/></button>
                        </div>
                    </div>
                </div>
                }

                <SelectCityDialog app={this.props.app} user={this.props.user} display={this.state.showCitySelection}
                                  text={translateMessage("select_city_long")}
                                  onClose={this.hideCitySelection} selectCity={this.selectCity}/>


                <SpicesDialog app={this.props.app} user={this.props.user} display={this.state.showSpices}
                              text={this.collectSubmitText(this.state.textWithoutSpices)}
                              onClose={this.hideSpices} spiceValues={this.state.spiceValues}
                              setSpiceValues={this.setSpiceValues} searchSpiceValues={this.searchSpiceValues}/>

                <SearchTextDialog app={this.props.app} user={this.props.user} display={this.state.showOffers}
                                  spices={this.state.spiceValues}
                                  textWithoutSpices={this.state.textWithoutSpices}
                                  onClose={this.hideOffers} searchTextChanged={this.onTagSelect}/>


                <DateDialog display={this.state.selectDateAfter} app={this.props.app} user={this.props.user}
                            title={translateMessage("search_date_after")} placeholder={translateMessage("search_date_after")} date={this.state.dateAfter}
                            closePopup={this.cancelSelectDate}
                            selectDate={this.dateAfterSelected}/>

                <DateDialog display={this.state.selectDateBefore} app={this.props.app} user={this.props.user}
                            title={translateMessage("search_date_before")} placeholder={translateMessage("search_date_before")} date={this.state.dateBefore}
                            closePopup={this.cancelSelectDate}
                            selectDate={this.dateBeforeSelected}/>
                <HistoryDialog display={this.state.showHistory} app={this.props.app} user={this.props.user}
                            title={translateMessage("search_date_before")} placeholder={translateMessage("search_date_before")}
                            closePopup={this.closeHistory}
                            selectHistory={this.historySelected}/>

            </div>
        } else {
            return null;
        }
    }
}

class SearchPanelEmpty extends Component {

    render() {
        return <div className="SearchPanelLargeEmpty ThemeSolidBackground">
        </div>
    }
}

export default withCookies(SearchPanel);
export {SearchPanelEmpty};