//TranslateMessage: completed
import React, {Component} from 'react';
import './Topbar.css';
import {Link} from "react-router-dom";
import {MenuElements} from '../sidebar/Sidebar'
import {translateMessage, TranslateMessage} from "../i18n/TranslateMessage";
import ActionManager from "../action/ActionManager";

const PubSub = require('pubsub-js');

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {showSidebar: true};
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }


    handleToggleMenu(event) {
        PubSub.publish('EVENT_TOGGLE_MENU');
        event.preventDefault();
    }

    changeLanguage(lang) {
        PubSub.publish("EVENT_TOGGLE_LANG", {lang: lang});
    }

    render() {
        const editor = this.props.editor === true;
        const home = this.props.home;
        const user = this.props.user;
        let rootClass;
        if (this.state.showSidebar)
            rootClass = "RootContainer";
        else
            rootClass = "RootContainerNoSidebar";

        return (
            <div>
                <div className={"TopbarFull"}/>
                <div
                    className={"Topbar TextOnHighlightBackground ThemeSolidColor SectionWidthContainer " + rootClass}>
                    <div className="ContactHeader HideOnMobile">
                        {editor && <div className="ContactLeft">
                            <TranslateMessage id="webmenu_title_text_editor"/>
                        </div>}

                        {!editor && <div className="ContactLeft">
                            {(home.topActions?.length > 0) && home.topActions.map((action) => {
                                return <ActionManager action={action.action} className="TopbarAction">{action.title}</ActionManager>
                            })}
                            {(home.topActions?.length ==  0) && <span>Tetszik a Barq.me? Szeretnél értesülni az eseményekről? <Link to={"/register"}>Regisztrálj</Link> vagy <Link to={"/login"}>jelentkezz be</Link>!</span>}
                        </div>}
                        <div style={{clear: 'both'}}></div>
                    </div>
                    <div className="LogoAndSearch">
                        <div className="Logo">

                            <div style={{width: 16, height: 40, display: "inline-block"}} className="HideOnMobile"/>

                            <button title="Alkalmazás menü megnyitása" name="Alkalmazás menü"
                                    onClick={this.handleToggleMenu}
                                    className="SidebarMenu ThemeMenu ShowOnMobileOnly"/>
                            <Link to="/">
                                <div title="Ugrás a barq.me főoldalára" className="TopbarAppLogo ThemeLogo"/>
                            </Link>
                        </div>
                        <div className="LoginData">

                            {this.props.app.app_url != 'https://barq.me' && <div className="ChangeLanguageContainer">
                                {this.props.app.lang != "hu" && <button className="ChangeLanguage ChangeLanguageHu"
                                                                        onClick={(e) => this.changeLanguage("hu")}>HU</button>}
                                {this.props.app.lang != "en" && <button className="ChangeLanguage ChangeLanguageEn"
                                                                        onClick={(e) => this.changeLanguage("en")}>EN</button>}
                            </div>}

                            {user.anonym && <Link className="MyProfile" to="/login">
                                <img title={translateMessage("webmenu_login")} alt={translateMessage("webmenu_login")}
                                     className="Image"
                                     src="/barqme-no-user-white.png"/>
                            </Link>}

                            {user.anonym || <Link className="MyProfile" to="/my-profile">
                                <img title={translateMessage("webmenu_mypofile_long")}
                                     alt={translateMessage("webmenu_mypofile_long")}
                                     className="Image" src={user.imageName}/>
                            </Link>}

                            <TopbarDarkMode/>

                            {/*{(!editor && user.anonym) &&*/}
                            {/*<Link className="TopbarButton HideOnMobile" to="/login"><TranslateMessage id="webmenu_login" defaultMessage={"Bejelentkezés"}/></Link>}*/}

                            {(!editor && user.superAdmin) &&
                                <Link className="TopbarButton HideOnMobile" to="/editor"><TranslateMessage
                                    id="webmenu_editor" defaultMessage={"Szerkesztő"}/></Link>}

                            {(editor && !user.anonym) &&
                                <Link className="TopbarButton HideOnMobile" to="/"><TranslateMessage id="webmenu_browse"
                                                                                                     defaultMessage={"Böngészés"}/></Link>}

                            {/*{!user.anonym &&*/}
                            {/*<Link className="TopbarButton HideOnMobile" to="/my-profile"><TranslateMessage id="webmenu_mypofile" defaultMessage={"Profilom"}/></Link>}*/}

                        </div>
                    </div>
                    <div className="TopbarMenu HideOnMobile">
                        {!editor && <MenuElements/>}
                        {editor && this.props.children}
                    </div>
                </div>
            </div>

        );
    }
}

class BarqMeTopbar extends Component {
    render() {
        const editor = this.props.editor === true;
        return <Topbar editor={editor} app={this.props.app} user={this.props.user} home={this.props.home} search={this.props.search}
                       hideSearch={this.props.hideSearch}>
            {this.props.children}
        </Topbar>
    }
}

class TopbarDarkMode extends Component {

    constructor(props, context) {
        super(props, context);
        const THEME_NAME = window.themeName;
        this.state = {themeName: THEME_NAME || "theme_light"};
        // this.setupTheme = this.setupTheme.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
    }

    componentDidMount() {
        // PubSub.subscribe('EVENT_TOGGLE_THEME', this.setupTheme);
    }

    componentWillUnmount() {
        // PubSub.unsubscribe('EVENT_TOGGLE_THEME');
    }


    toggleTheme() {

        let theme = "theme_system";
        if (this.state.themeName === "theme_light")
            theme = "theme_dark";
        else if (this.state.themeName === "theme_dark")
            theme = "theme_system";
        else if (this.state.themeName === "theme_system")
            theme = "theme_light";
        this.setState({themeName: theme});
        PubSub.publish("EVENT_TOGGLE_THEME", {theme: theme});
    }

    render() {

        let imageName = "";
        let imageTitle = "";
        if (this.state.themeName === "theme_light") {
            imageName = "/barqme-darkmode-off.png";
            imageTitle = translateMessage("theme_light");
        } else if (this.state.themeName === "theme_dark") {
            imageName = "/barqme-darkmode-on.png";
            imageTitle = translateMessage("theme_dark");
        } else if (this.state.themeName === "theme_system") {
            imageName = "/barqme-darkmode-auto.png";
            imageTitle = translateMessage("theme_auto");
        }
        return <img title={imageTitle} className="DarkMode" src={imageName}
                    onClick={this.toggleTheme} alt={this.state.themeName}/>
    }
}

export default BarqMeTopbar;