import '../Barqboard.css';

import React, {Component} from "react";
import {ViewArticlePanel} from "../../article/Article";

class ArticleSection extends Component {

    render() {
        const section = this.props.section;
        return <div className="PaddingDependsOnWidth">
            <div className="Section SectionWidthContainer ArticleSection">
                <ViewArticlePanel article={section.article} app={this.props.app}/>
            </div>
        </div>
    }

}

export default ArticleSection;