import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import ImageWithTitle from "../../components/ImageWithTitle";

class LocationsSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {data: null}
    }

    componentDidMount() {
        this.downloadTags();
    }


    downloadTags() {
        ajax
            .post(this.props.app.server_url + '/section/get')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": this.props.section.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({data: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        if (this.state.data != null) {
            const locations = this.state.data.locations.locations;
            return <div className="PaddingDependsOnWidth">
                <div className="Section ModernSection SectionWidthContainer ImageWithTitleSectionWithSectionTitle">
                    <h2 className="SectionTitle">Érdekes helyszínek</h2>
                    <div className="MenuSectionContainer">

                            {locations.map((location, i) => {
                                return <ImageWithTitle key={"location" + i + "-" + location.locationId}
                                                       title={location.name} image={location.imageUrl1}
                                                       index={i}
                                                       link={this.props.app.app_url + "/search/SEARCH:" + location.tag.tag}/>
                            })
                            }
                    </div>
                </div>
            </div>
        } else {
            return null;
        }

    }
}


export default LocationsSection;