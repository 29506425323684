import React, {Component} from "react";
import ajax from "superagent";
import {InfoMessage, Padding} from "../../components/Components";
import {PromoterTabFooter} from "../EditorComponent";
import './BarqEditor.css';
import BarqTags from "./BarqTags";
import Loading from "../../components/Loading";
import {MediaSelectorComponent} from "../media/MediaEditor";

class BarqEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {barq: this.props.barq, loading: true};
        this.save = this.save.bind(this);
        this.updateBarq = this.updateBarq.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.onTagsChanged = this.onTagsChanged.bind(this)
    }

    componentDidMount() {
        this.editBarq();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    editBarq() {
        this.setState({loading:true});
        let barqId = null;
        if (this.state.barq != null) barqId = this.state.barq.barqId;
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/edit')
            .query({"platform": "react", barqId: barqId})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({barq: res.body, loading: false});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    save() {
        this.setState({loading:true});
        const barq = this.state.barq;
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/save')
            .query({...{"platform": "react"}})
            .send(barq)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({barq: res.body, loading:false});
                alert("x MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    updateBarq(barq) {
        this.setState({barq: barq}, (e) => {
            this.save()
        })
    }


    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({barq: {...this.state.barq, ...{[name]: value}}});
    }

    onCategoryChanged(category, event) {
        const barq = this.state.barq;
        const selected = barq.selectedCategories;
        const index = selected.findIndex((s) => s.tagId === category.tagId);
        if (index >= 0) {
            //Remove
            let newSelected = [...selected];
            newSelected.splice(index, 1)
            barq.selectedCategories = newSelected
            this.setState({barq: barq})
        } else {
            //Add
            let newSelected = [...selected];
            newSelected.push(category)
            barq.selectedCategories = newSelected
            this.setState({barq: barq})
        }
    }


    onTagsChanged(tags) {
        const barq = this.state.barq;
        // const newTags = res.body;
        // const tags = [...barq.tags, ...newTags];
        barq.tags = tags;
        this.setState({barq: barq})

    }

    render() {
        const barq = this.state.barq;
        if (barq != null && barq.categories != null) {
            return <div>
                <Padding>

                    <div className="TabHeader">
                        <button className="EditorButtonBack ThemeBackground HighlightColor"
                                onClick={this.props.onClose}>Saját események >
                        </button>
                        <span className="TabTitle">
                           {"Szerkesztés: " + (barq.title || "Névtelen")}
                        </span>
                    </div>

                    <h2>Alapadatok</h2>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_title">Megnevezés</label><input id="barq_title" type="text" name="title"
                                                                             value={barq.title}
                                                                             onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_details">Leírás:</label><input id="barq_details" type="text" name="details"
                                                                            value={barq.details}
                                                                            onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_startDate">Kezdés időpont (yyyy-MM-dd HH:mm):</label><input
                        id="barq_startDate" type="text" name="startDate"
                        value={barq.startDate} onChange={this.onPropertyChanged}/>
                    </div>

                    {/*<div>*/}
                    {/*    <label htmlFor="barq_finishDate">Vége időpont (yyyy-MM-ddd hh:mm):</label><input*/}
                    {/*    id="barq_finishDate" type="text" name="finishDate"*/}
                    {/*    value={barq.finishDate} onChange={this.onPropertyChanged}/>*/}
                    {/*</div>*/}
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_imageName">Kép:</label>


                        <MediaSelectorComponent id="form_form_imageHeader" name="imageName"
                                                value={barq.imageName} className="ArticleParagraphEditorField"
                                                onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_sourceUrl">Forrás webcím:</label><input id="barq_sourceUrl" type="text"
                                                                                     name="sourceUrl"
                                                                                     value={barq.sourceUrl}
                                                                                     onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="barq_location">Helyszín:</label><input id="barq_location" type="text"
                                                                               name="location"
                                                                               value={barq.location}
                                                                               onChange={this.onPropertyChanged}/>
                    </div>


                    {barq.barqId &&
                    <BarqTags barq={barq} onCategoryChanged={this.onCategoryChanged} onTagsChanged={this.onTagsChanged} app={this.props.app}/>}
                    {!barq.barqId && <PleaseSaveWarning/>}
                    {(barq.barqId && !barq.readyToOffer) && <NotCompletedWarning/>}


                    {(barq.barqId && barq.readyToOffer) && <InfoMessage>
                        <div className="InfoMessageSwitch">
                            <input type="checkbox" className="apple-switch" checked={barq.publishMe}
                                   name="publishMe"
                                   onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="InfoMessageContent">
                            <span className="Title">Barq publikálása</span> - Kapcsold be ezt a kapcsolót, hogy a
                            barqod a felhasználók számára elérhetők legyenek. <b>A változtatás csak a mentés után lép életbe!</b>
                            {(barq.barqId && barq.readyToOffer && !barq.published) && <p className="ColorWarning">Az esemény még nincs publikálva. Ez azt jelenti, hogy a felhasználók még nem találhatják meg és
                                tekinthetik meg az eseményt. Az esemény teljesíti a publikálási feltételeket, így csak rajtad múlik,
                                mikor publikálod.</p>}
                        </div>
                    </InfoMessage>}



                    <PromoterTabFooter onSave={this.save} editorMode={this.props.editorMode}/>
                </Padding>
                <Loading loading={this.state.loading}/>
            </div>
        } else {
            return <Loading loading={this.state.loading}/>
        }
    }
}


class PleaseSaveWarning extends Component {

    render() {
        return <div className="NotPublishedWarning ThemeSolidBackground">
            <p>Az esemény besorolásához és címkézéséhez először add meg a címke alapadatait és ments el az eseményt!</p>
        </div>
    }

}


class NotCompletedWarning extends Component {

    render() {
        return <div className="NotCompletedWarning ThemeSolidBackground">
            <p>Az esemény még nem publikálható, mert nem
                teljesíti az összes szükséges feltételt. A publikálási feltételek azt szolgálják, hogy a felhasználók
                könnyen megtalálhassák, amit keresnek.</p>
            <p>A publikálási feltételek a következők:</p>
            <ul>
                <li>Az eseményhez képet kell rendelni</li>
                <li>Az eseményhez legalább egy kategóriát ki kell választani</li>
                <li>Az eseményhez legalább 5 címkét hozzá kell rendelni</li>
            </ul>
        </div>
    }

}

export default BarqEditor;