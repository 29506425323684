import React, {Component} from 'react';
import './ContactUs.css';
import Container from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import {Link} from "react-router-dom";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import {DownloadArticle} from "../article/Article";
import {Helmet} from "react-helmet";

class PageContactUs extends Component {


    constructor(props, context) {
        super(props, context);

        const reasons = [
            {key: "feedback", display: "Visszajelzés, vélemény"},
            {key: "error", display: "Hibabejelentés"},
            {key: "join", display: "Kérdés csatlakozásról"},
            {key: "press", display: "Sajtóérdeklődés"},
            {key: "barq_error", display: "Hibás esemény adatok"},
            {key: "barq_mod", display: "Elhalasztott, elmaradó esemény"},
            {key: "location_error", display: "Hibás helyszín adatok"},
            {key: "location_close", display: "Ideiglenesen, véglegesen bezáró helyszín"}
        ]

        this.state = {
            name: "",
            email: "",
            reason: reasons[0].key,
            link: "",
            message: "",
            reasons: reasons
        }
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }


    render() {
        return <div>
            <Helmet>
                <title>barq.me | kapcsolat</title>
                <meta name="description" lang="hu" content={'barq.me kapcsolat'}/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <DownloadArticle app={this.props.app} user={this.props.user} article="web-contactus"/>
                <div className="ContactUsForm">
                    <div className="PaddingDependsOnWidth">
                        <p>
                            <label>Kérjük, add meg a neved</label>
                            <input type="text" name="name" placeholder="Neved,ahogy szólíthatunk"
                                   value={this.state.name} onChange={this.onPropertyChanged}
                                   className="ThemeSolidBackground" autoComplete="off"/>

                            <label>Kérjük, add meg az email címedet, amire válaszolhatunk</label>
                            <input type="text" name="email" placeholder="Email címed, amin elérhetünk"
                                   value={this.state.email} onChange={this.onPropertyChanged}
                                   className="ThemeSolidBackground" autoComplete="off"/>

                            <label>Kérjük, válaszd ki, miért keresel minket</label>
                            <select className="ThemeSolidBackground" value={this.state.reason.key}
                                    onChange={this.onPropertyChanged}>
                                {this.state.reasons.map((reason) => {
                                    return <option key={reason.key} value={reason.key}>{reason.display}</option>
                                })}
                            </select>

                            <label>Kérjük, add meg az érintett tartalom linkjét</label>
                            <input type="text" name="email" placeholder="Érintett tartalom, ha létezik"
                                   value={this.state.link} onChange={this.onPropertyChanged}
                                   className="ThemeSolidBackground" autoComplete="off"/>

                            <label>Kérjük, írt ide az üzeneted, kérdésedet</label>
                            <textarea className="ThemeSolidBackground" value={this.state.message}
                                      onChange={this.onPropertyChanged}/>
                            <button className="largeRedButton">Elküldés</button>
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    }
}

export default PageContactUs;