import '../Barqboard.css';
import React, {Component} from "react";
import ImageWithTitle from "../../components/ImageWithTitle";
import {Link} from "react-router-dom";
import "./MenuSection.css";
import {translateMessage, TranslateMessage} from "../../i18n/TranslateMessage";
import SectionHeader from "./SectionHeader";

class MenuSection extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {data: this.props.section}
    }


    componentDidMount() {
    }

    render() {
        if (this.state.data != null) {
            let displayClass = "BarqboardMenuSectionContainer";
            if (this.props.all)
                displayClass = "";
            return <div className="PaddingDependsOnWidth">
                <div className="Section ModernSection SectionWidthContainer ImageWithTitleSectionNoSectionTitle">
                    {this.props.all || <SectionHeader title={this.state.data.title} button={translateMessage("barqboard_section_button_all", null, {count: this.state.data.tags.tags.length})} link={"/categories/"}/>}

                    <div className={"MenuSectionContainer "+displayClass}>
                        {this.state.data.tags.tags.map((tag, i) => {
                            let imageUrl = tag.imageUrl || "https://img3.barq.me/content/default_szinhaz" + (i + 1) + ".jpg";
                            const link = this.props.app.app_url + "/search/SEARCH:" + tag.tag;
//                            alert("APP_URL: " + this.props.app.app_url+" LINK: " + link);
                            return <ImageWithTitle key={"menu-" + i + "-" + tag.tag}
                                                   title={tag.display + " (" + tag.actionInfoSummary.numberOfBarqs + ")"}
                                                   image={imageUrl} index={i} all={this.props.all}
                                                   link={link}/>
                        })
                        }
                    </div>
                </div>
            </div>
        } else {
            return null;
        }

    }
}

export default MenuSection;