import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import './MyPosts.css';
import ajax from "superagent";
import ShowPost from "./ShowPost";
import PageArticleEditor from "../../article/ArticleEditor";

class MyPosts extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {tab: "list"};
        this.changeTab = this.changeTab.bind(this);
        this.newPost = this.newPost.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeTab(tab) {
        this.setState({tab: tab}, () => {
        });
    }

    closeEditor() {
        this.changeTab("list");
    }

    newPost() {
        this.setState({tab: "editor"}, (e) => {
            this.changeTab("editor");
        })

    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Posztok</title>
                <meta name="description" lang="hu" content="Posztok"/>
            </Helmet>

            {this.state.tab === "list" &&
            <MyPostList editorMode={this.props.editorMode} app={this.props.app} onNewPost={this.newPost}/>}
            {this.state.tab === "editor" &&
            <PostEditor editorMode={this.props.editorMode} app={this.props.app} onCloseEditor={this.closeEditor}
                        barq={this.state.barq}/>}
        </div>
    }
}


class MyPostList extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            posts: null
        };
    }


    componentDidMount() {
        this.downloadMyPosts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadMyPosts() {
        ajax
            .post(this.props.app.server_url + '/editor/post/my-posts')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({posts: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        const posts = this.state.posts;
        if (posts != null) {
            const postsView = posts.map((post, i) => {
                return <ShowPost key={"post"+i} post={post} editorMode={this.props.editorMode}/>
            })

            return <div className="MyPostList">
                <div className="ShowPost ThemeSolidBackground">
                    Új poszt írásához kattints ide: <button className="barqButton" onClick={this.props.onNewPost}>Új poszt</button>
                </div>
                {postsView}
            </div>
        } else {
            return ""
        }
    }
}

class PostEditor extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            post: {
                code: "",
                article: {
                    code: "",
                    title: "",
                    group: "post",
                    excerpt: "",
                    lang: "hu",
                    paragraphs: [{type: "text", code: "", text: "Ide írd az üzeneted!"}]
                }
            }
        };
        // this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.closeEditor = this.props.onCloseEditor.bind(this);
        this.updateArticle = this.updateArticle.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    save() {
        const data = this.state.post;
        ajax
            .post(this.props.app.server_url + '/editor/post/save')
            .query({"platform": "react"})
            .send(data)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.closeEditor();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onPropertyChanged(event) {
        const target = event.target;
        const value = target.value;
        this.setState({text: value});
    }

    updateArticle(article) {
        const post = this.props.post;
        post.article = article;
        this.setState({post: post})
    }

    render() {
        const post = this.state.post;
        return <div className="MyPostList">
            <div className="ShowPost ThemeBackground">
                Az üzeneted:
                <PageArticleEditor article={post.article} onArticleChanged={this.updateArticle}
                                   app={this.props.app} user={this.props.user} editorMode={this.props.editorMode}
                                   hideArticleData={true}/>

                <button onClick={this.save}>Mentés</button>
            </div>
        </div>
    }

}

export default MyPosts;