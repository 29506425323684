import React, {Component} from "react";
import './ArticleParagraphFeatures.css';

class ArticleParagraphFeatures extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            items: [
                {
                    color: "#42ABC2",
                    darkColor: "#308294",
                    cssClass: "CardBarqboard",
                    title: "Egy helyen a számodra érdekes események"
                },
                {
                    color: "#E8AFA1",
                    darkColor: "#DF6070",
                    cssClass: "CardSearch",
                    title: "A kereső segítségével bármelyik programot megtalálhatod"
                },
                {
                    color: "#81BD88",
                    darkColor: "#1A700C",
                    cssClass: "CardSpices",
                    title: "Események szűrése a tulajdonságaik alapján"
                },
                {
                    color: "#72B8E5",
                    darkColor: "#0094C7",
                    cssClass: "CardSubscribe",
                    title: "Az érdeklődésedet a keresések mentésével bővítheted"
                },
                {
                    color: "#E95244",
                    darkColor: "#AD0613",
                    cssClass: "CardDiversity",
                    title: "Nálunk megfér egymás mellett sport és kultúra"
                },
                {
                    color: "#7BBDBD",
                    darkColor: "#2E9C8B",
                    cssClass: "CardEvents",
                    title: "Részletes információk az eseményekről"
                },

            ]
        }
        this.displayEvent = this.displayEvent.bind(this);
    }

    componentDidMount() {
        this.display()
        document.addEventListener('scroll', this.displayEvent);
        document.addEventListener('resize', this.displayEvent);
    }


    componentWillUnmount() {
        document.removeEventListener("scroll", this.displayEvent);
        document.removeEventListener("resize", this.displayEvent);
    }

    displayEvent(e) {
        this.display();
    }

    display() {
        // let cardWidth = 825;
        // let cardHeight = 585;
        const titleHeight = document.getElementById("FeatureTitle").offsetHeight;
        const fullWidth = window.innerWidth;
        const fullHeight = window.innerHeight;
        let skip = fullHeight/2;
        let titleTop = 120;
        let titleTopPadding = 30;

        if ( fullHeight < 900 ) {
            titleTop = -titleHeight;
            if (fullWidth < 500) {
                titleTopPadding = 100;
            } else {
                titleTopPadding = 140;
            }
        } else if (fullHeight >= 900 && fullHeight < 1024) {

            if (fullWidth < 500) {
                titleTop = 80;
                titleTopPadding = 60;
            } else {
                titleTop = 120;
                titleTopPadding = 60;
            }



        } else if (fullHeight >= 1024) {
            titleTop = fullHeight / 2 - titleHeight / 2 - 585/2;
        }

        const divHeight = fullHeight - 50 + (this.state.items.length * (skip+ 585 /2)) - 100;
        document.getElementById("CardContainer").style.height = divHeight + "px";

        document.getElementById("FeatureTitle").style.top = Math.max(titleTop, (fullHeight / 2) - window.scrollY - titleHeight / 2) + "px";
        for (let i = 0; i < this.state.items.length; i++) {
            const elementId = "FeatureCard" + i;
            document.getElementById(elementId).style.top = Math.max(titleTop + titleTopPadding + titleHeight, fullHeight - 50 + (i * (skip+ 585 /2)) - window.scrollY) + "px";
        }

    }

    render() {

        const fullHeight = window.innerHeight;

        return <div id="CardContainer" className={"CardContainer"}>
            <div id="FeatureTitle" className="FeatureTitle"
                 style={{top: Math.max(150, (fullHeight / 2) - window.scrollY)}}>
                Ismerd meg, hogy a barq.me milyen funkciókkal segít neked!
            </div>

            {
                this.state.items.map((item, index) => {
                    return <div id={"FeatureCard" + index} key={"FeatureCard" + index} className={"FeatureCard ThemeBackground"}
                                style={{
                                    top: Math.max(300, ((index + 1) * (fullHeight - 50)) - window.scrollY) + "px",
                                    // backgroundColor: item.color,
                                    borderColor: item.darkColor
                                }}>
                        <div className={"FeatureText"}>{item.title}</div>
                        <div className={"Iphone "+ item.cssClass}/>
                    </div>
                })

            }
        </div>
    }
}

export default ArticleParagraphFeatures;