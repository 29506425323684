import React, {Component} from "react";
import BarqItemList, {BarqItemListShowAll} from "./BarqItemList";
import BarqItemTile, {BarqItemTileShowAll} from "./BarqItemTile";
import BarqItemCard, {BarqItemCardAll, BarqItemCardShowAll} from "./BarqItemCard";

class BarqItem extends Component {

    render() {
        if (this.props.mode === "list") {
            return <BarqItemList {...this.props}/>
        } else if (this.props.mode === "newspaper") {
            return <BarqItemTile {...this.props}/>
        } else if (this.props.mode === "card") {
            return <BarqItemCard {...this.props}/>
        } else {
            return <BarqItemTile {...this.props}/>
        }
    }

}

class BarqItemShowAll extends Component {

    render() {
        if (this.props.mode === "list") {
            return <BarqItemListShowAll {...this.props}>{this.props.children}</BarqItemListShowAll>
        } else if (this.props.mode === "tile") {
            return <BarqItemTileShowAll {...this.props}>{this.props.children}</BarqItemTileShowAll>
        } else if (this.props.mode === "card") {
            return <BarqItemCardShowAll {...this.props}>{this.props.children}</BarqItemCardShowAll>
        } else {
            return <BarqItemTileShowAll {...this.props}>{this.props.children}</BarqItemTileShowAll>
        }
    }

}
class BarqItemAll extends Component {

    render() {
        if (this.props.mode === "list") {
            return <div>Missing</div>
        } else if (this.props.mode === "newspaper") {
            return <div>Missing</div>
        } else if (this.props.mode === "card") {
            return <BarqItemCardAll {...this.props}>{this.props.children}</BarqItemCardAll>
        } else {
            return <div>Missing</div>
        }
    }

}

export {BarqItemShowAll, BarqItemAll};
export default BarqItem;