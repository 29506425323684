import React, {Component} from 'react';
import './Register.css';
import './UserStart.css';
import Container, {Padding} from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import {DownloadArticle} from "../article/Article";
import ajax from "superagent";
import {LoginForm} from "./LoginForm";
import {Helmet} from "react-helmet";
import ArticleParagraphTitle, {ArticleParagraphTitleView} from "../article/paragraph/ArticleParagraphTitle";
import ArticleParagraphText from "../article/paragraph/ArticleParagraphText";
import {ImageWithTitleSelectable} from "../components/ImageWithTitle";
import "../components/ImageWithTitle.css";
import {Redirect} from "react-router-dom";
import Link from "react-router-dom/es/Link";

class PageUserStart extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {tags: null, selected: [], termsAccepted: false};
        this.toggleTag = this.toggleTag.bind(this);
        this.onPropChanged = this.onPropChanged.bind(this);
        this.acceptTermsAndServices = this.acceptTermsAndServices.bind(this);
    }

    componentDidMount() {
        this.downloadTags();
    }

    downloadTags() {
        ajax
            .post(this.props.app.server_url + '/tag/initialOffer')
            .query({"platform": "react",
                "lang": this.props.app.lang
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({tags: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    acceptTermsAndServices() {
        if (this.state.termsAccepted) {
            let subscribe = this.state.selected.map(tag => tag.tag).join(',');
            ajax
                .post(this.props.app.server_url + '/subscription/saveInitialInterests')
                .query({"platform": "react",
                    "lang": this.props.app.lang,
                    "texts": subscribe})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    window.location.href = "/";
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        } else {
            alert("A kezdéshez kérjük fogadd el a felhasználási feltételeket!")
        }
    }

    toggleTag(tag) {
        const selected = this.state.selected;
        const index = selected.findIndex((s) => s.tag === tag.tag);
        if (index >= 0) {
            //Remove
            let newSelected = [...selected];
            newSelected.splice(index, 1)
            this.setState({selected: newSelected})
        } else {
            //Add
            let newSelected = [...selected];
            newSelected.push(tag)
            this.setState({selected: newSelected})
        }
    }

    onPropChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.checked;
        this.setState({[name]: value})
    }


    render() {
        const user = this.props.user;
        const tags = this.state.tags;
        return <div>
            <Helmet>
                <title>barq.me | Üdvözlünk!</title>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user}/>
            <Container app={this.props.app} user={this.props.user}>
                <ArticleParagraphTitle
                    paragraph={{title: "A regisztrációd sikeres volt, a barq.me köszönt téged, " + user.firstName + "!"}}/>

                <ArticleParagraphText
                    paragraph={{text: "Válassz ki néhány témát, ami érdekel, hogy a barq.me könnyebben ajánlhasson neked programokat. Később a témákat bármikor módosíthatod.\nA folytatáshoz a témák alatt fogadd el a felhasználási feltételeinket."}}
                    decoration="intro"/>
                <Padding>
                    {(tags != null) && <div className={"InitialTagOffers"}>
                        {tags.tags.map((tag, i) => <SelectTag tag={tag} index={i + 1}
                                                              selected={this.state.selected.findIndex((s) => s.tag === tag.tag) >= 0}
                                                              onChange={this.toggleTag}/>)}
                    </div>}
                </Padding>
                <ArticleParagraphTitleView
                    paragraph={{title: "Felhasználási feltételek"}}/>
                <ArticleParagraphText
                    paragraph={{text: "A kezdéshez kérjük fogadd el a felhasználási feltételeket:"}}
                    decoration="intro"/>
                <Padding>
                    <input id="termsAccepted" type="checkbox" name="termsAccepted" value={this.state.termsAccepted}
                           onChange={this.onPropChanged}/> <label htmlFor="termsAccepted">Elfogadom a</label><a target="_blank" className="ColorLink" href="page/terms-of-service"> felhasználási
                    feltételeket!</a>
                    <div className={"PaddedTop"}>
                        <button className={"largeRedButton "}
                                onClick={this.acceptTermsAndServices}>Kezdhetjük!
                        </button>
                    </div>
                </Padding>
            </Container>
        </div>
    }
}

class SelectTag extends Component {

    render() {
        const tag = this.props.tag;
        const colorClass = "BackgroundHighlight" + (1 + (this.props.index % 13));
        return <ImageWithTitleSelectable obj={tag} title={tag.display} image={tag.imageUrl}
                                         onChange={this.props.onChange} selected={this.props.selected}
                                         colorClass={colorClass}/>
    }
}

export default PageUserStart;