//TranslateMessage: completed

import React, {Component} from "react";
import ajax from "superagent";
import {Link} from "react-router-dom";
import "./SpicesDialog.css"
import ModernDialog from "../popup/ModernDialog";
import SelectSpiceDialog from "./SelectSpiceDialog";
import {TranslateMessage, translateMessage} from "../i18n/TranslateMessage";

class SpicesDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.actionButtonClicked = this.actionButtonClicked.bind(this);
        this.searchButtonClicked = this.searchButtonClicked.bind(this);
        this.state = {spices: null, spice: null};
        this.selectSpice = this.selectSpice.bind(this);
        this.selectSpiceValue = this.selectSpiceValue.bind(this);
        this.searchSpiceValue = this.searchSpiceValue.bind(this);
        this.clearSpice = this.clearSpice.bind(this);
        this.closeSpice = this.closeSpice.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.display != null && prevProps.display !== this.props.display) {
            this.downloadSpices();
        }
    }


    downloadSpices() {
        ajax
            .post(this.props.app.server_url + '/search/loadSpices')
            .query({"client_build": "100", "platform": "react", "lang": this.props.app.lang, "limit": 100, text: this.props.text || ""})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({
                    spices: res.body.searchSpices
                }, () => {
                    if (this.props.spiceValues)
                        this.props.spiceValues.map((spice) => this.selectSpiceValue(spice));
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    getFilters() {
        return this.state.spices?.filter((spice) => spice.type !== 1)
    }

    actionButtonClicked() {
        const spiceValues = [];
        this.getFilters().map((spice) => {
            if (spice.value != null) spiceValues.push(spice.value);
        });
        this.props.setSpiceValues(spiceValues);
    }

    searchButtonClicked() {
        const spiceValues = [];
        this.getFilters().map((spice) => {
            if (spice.value != null) spiceValues.push(spice.value);
        });
        this.props.searchSpiceValues(spiceValues);
    }

    selectSpice(spice) {
        this.setState({spice: spice});
    }

    selectSpiceValue(value) {
        this.setState({spice: null});
        const index = this.getFilters().findIndex((s) => s.type === value.type);
        if (index >= 0) {
            let spices = [...this.getFilters()];
            let spice = spices[index];
            spice.value = value;
            spices[index] = spice;
            this.setState({spices: spices})
        }
    }

    searchSpiceValue(value) {
        this.setState({spice: null});
        const index = this.getFilters().findIndex((s) => s.type === value.type);
        if (index >= 0) {
            let spices = [...this.getFilters()];
            let spice = spices[index];
            spice.value = value;
            spices[index] = spice;
            this.setState({spices: spices}, (e) => {
                this.props.searchSpiceValues([value]);
            })
        }

    }

    clearSpice(spice) {
        this.setState({spice: null});
        const index = this.getFilters().findIndex((s) => s.type === spice.type);
        if (index >= 0) {
            let spices = [...this.getFilters()];
            let spice = spices[index];
            spice.value = null;
            spices[index] = spice;
            this.setState({spices: spices})
        }
    }

    closeSpice() {
        this.setState({spice: null});
    }

    render() {

        if (this.props.display) {
            const spices = this.getFilters();
            let spicesView = "";
            if (spices) {
                spicesView = spices.map((spice, index) => {
                        return <SpiceElement key={'spice-' + index} spice={spice} onSelect={this.selectSpice}
                                             clearSpice={this.clearSpice}
                                             app={this.props.app} index={index}/>
                    }
                );
            }
            return <div><ModernDialog title={translateMessage("filter_title")} level={1} onClose={this.props.onClose}
                                      extraClass="ModernDialogSizeWide ThemeBackground">
                <div className="SpiceItemContainer">
                    {spicesView}

                    <div className="SpiceItem"/>
                    <div className="SpiceItem">
                        <div className="SpiceValue">
                            <div className="SpiceButtons">
                                <button className={"SpiceDialogSearchButton largeBarqButton"}
                                        onClick={this.actionButtonClicked}><TranslateMessage id="button_search_apply"/>
                                </button>
                                <button className={"SpiceDialogSearchButton largeRedButton"}
                                        onClick={this.searchButtonClicked}><TranslateMessage id="button_search_action"/>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>



            </ModernDialog>
                <SelectSpiceDialog level={2} buttonText={translateMessage("button_spice_none")} display={this.state.spice != null}
                                   spice={this.state.spice} oncClose={this.closeSpice}
                                   onSelect={this.selectSpiceValue} onSearch={this.searchSpiceValue}/>
            </div>
        } else {
            return "";
        }
    }
}

class SpiceElement extends Component {

    render() {
        const spice = this.props.spice;

        let key = spice.title+":";
        const dependsOnValue = spice.dependsOn?.display;
        if (dependsOnValue) {
            key = spice.title+ " ("+dependsOnValue+"): ";
        }

        let value = spice.value?.display;
        if (!value) {
            value = translateMessage("filter_text_any");
        }

        return <div className="SpiceItem">
            <div className="SpiceKey ThemeBackground">{key}</div>
            <div className="SpiceValue ThemeSolidBackground">
                <button onClick={(e) => this.props.onSelect(spice)}
                        className="SpiceButton ThemeSolidBackground">{value}</button>
                <button className="ClearSpiceButton" onClick={(e) => this.props.clearSpice(this.props.spice)}/>
            </div>
        </div>
    }


}

export default SpicesDialog;