import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import {PaddingEditor} from "../../components/Components";
import {NewParagraphButtons} from "./ParagraphBlock";
import {MediaSelectorComponent} from "../../editor/media/MediaEditor";
import ActionManager from "../../action/ActionManager";
import "./ArticleParagraphMediaImageGroup.css";

const PubSub = require('pubsub-js');


class ArticleParagraphMediaImageGroup extends Component {


    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <ArticleParagraphVariableImageGroupEditMode app={this.props.app} paragraph={paragraph}
                                                               addParagraph={this.props.addParagraph}
                                                               moveParagraph={this.props.moveParagraph}/>
        } else {
            return <ArticleParagraphVariableImageGroupView paragraph={paragraph}/>
        }
    }
}

class ArticleParagraphVariableImageGroupView extends Component {


    constructor(props, context) {
        super(props, context);
        const paragraph = this.props.paragraph;
        this.state = {images: paragraph.images, index: 0}
        this.selectImage = this.selectImage.bind(this);
    }

    selectImage(index) {
        this.setState({index: index});
    }

    render() {
        const paragraph = this.props.paragraph;
        const image = this.state.images[this.state.index];
        let containerClass = "PaddingDependsOnWidth";
        let imageClass = "RoundImage";
        if (paragraph.place === "header") {
            containerClass = "";
            imageClass = "HeaderImage";
        }


        if (image.image.imageMobile || image.image.imageTablet || image.image.imageDesktop) {

            if (image.action) {
                return <div className={containerClass}>
                    <ActionManager action={image.action} failOnEmpty={false}>
                        <div className={imageClass + "Container"}>
                            <img src={image.image.imageMobile} className={imageClass + " ShowOnlySmall"}
                                 alt=""/>
                            <img src={image.image.imageTablet} className={imageClass + " ShowOnlyMedium"} alt=""/>
                            <img src={image.image.imageDesktop} className={imageClass + " ShowOnlyLarge"} alt=""/>
                            {image.showTitle && <div className={imageClass + "Title"}>
                                {image.title}
                            </div>}
                        </div>
                    </ActionManager>
                    <div className="Pager">
                        {this.state.images.map((image, index) => {
                            let buttonClass = "ThemeSolidBackground";
                            if (index===this.state.index) buttonClass = "HighlightBackground";
                            return <button key={paragraph.code+"_image_"+index} className={buttonClass} onClick={(e)=> this.selectImage(index)}/>
                        })}
                    </div>
                </div>

            } else {
                return <div className={containerClass}>
                    <div className={imageClass + "Container"}>
                        <img src={image.image.imageMobile} className={imageClass + " ShowOnlySmall"} alt=""/>
                        <img src={image.image.imageTablet} className={imageClass + " ShowOnlyMedium"} alt=""/>
                        <img src={image.image.imageDesktop} className={imageClass + " ShowOnlyLarge"} alt=""/>
                        {image.showTitle && <div className={imageClass + "Title"}>
                            {image.title}
                        </div>}
                    </div>
                </div>

            }


        } else {
            return null;
        }
    }
}

class ArticleParagraphVariableImageGroupEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);
    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }


    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(image, action) {
        this.setState({paragraph: {...this.state.paragraph, ...{image: image}, ...{action: action}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });

    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <PaddingEditor>
            {!showEditor && <div>
                <img src={paragraph.image.imageMobile} className="ShowOnlySmall" style={{width: '100%'}}
                     alt=""/>
                <img src={paragraph.image.imageTablet} className="ShowOnlyMedium" style={{width: '100%'}}
                     alt=""/>
                <img src={paragraph.image.imageDesktop} className="ShowOnlyLarge" style={{width: '100%'}}
                     alt=""/>
                <button onClick={this.showEditor}>...</button>
            </div>}

            {showEditor &&
            <ArticleParagraphVariableImageGroupEditor image={paragraph.image} action={paragraph.action}
                                                      buttons={<NewParagraphButtons onRemove={this.remove}
                                                                                    onAddParagraph={this.addParagraph}/>}
                                                      onCancel={this.hideEditor}
                                                      app={this.props.app} onSave={this.save}/>}
        </PaddingEditor>
    }
}

class ArticleParagraphVariableImageGroupEditor extends Component {


    constructor(props, context) {
        super(props, context);
        const image = this.props.image;
        this.state = {
            imageMobile: image.imageMobile,
            imageTablet: image.imageTablet,
            imageDesktop: image.imageDesktop,
            action: this.props.action || ""
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onImageChanged = this.onImageChanged.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        const image = {
            imageMobile: this.state.imageMobile,
            imageTablet: this.state.imageTablet,
            imageDesktop: this.state.imageDesktop
        }
        this.props.onSave(image, this.state.action);
    }

    onImageChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value})
    }

    render() {
        return <div>

            <div>
                Mobil eszközön (keskenyebb, mint 500 pixel):<br/>

                <MediaSelectorComponent id="form_form_imageHeader" name="imageMobile"
                                        value={this.state.imageMobile} className="ArticleParagraphEditorField"
                                        onChange={this.onImageChanged}
                                        app={this.props.app} user={this.props.user}/>
            </div>
            <div>

                Közepes méretben (500-767 pixel között):<br/>
                <MediaSelectorComponent id="form_form_imageHeader" name="imageTablet"
                                        value={this.state.imageTablet} className="ArticleParagraphEditorField"
                                        onChange={this.onImageChanged}
                                        app={this.props.app} user={this.props.user}/>
            </div>
            <div>
                Nagy méretben (767 pixel fölött):<br/>
                <MediaSelectorComponent id="form_form_imageHeader" name="imageDesktop"
                                        value={this.state.imageDesktop} className="ArticleParagraphEditorField"
                                        onChange={this.onImageChanged}
                                        app={this.props.app} user={this.props.user}/>
            </div>
            <label htmlFor="form_action">Kép funkciója:</label>
            <input id="form_action" className="ArticleParagraphEditorField" type="text" name="action"
                   value={this.state.action} onChange={this.onImageChanged}/>


            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}

export default ArticleParagraphMediaImageGroup;
export {ArticleParagraphVariableImageGroupView};