import React, {Component} from "react";
import '../Articles.css'
import './ArticleParagraphActions.css'
import '../ArticleEditor.css'
import {Redirect} from "react-router-dom";
import {NewParagraphButtons} from "./ParagraphBlock";
import {Padding} from "../../components/Components";
import ActionManager from "../../action/ActionManager";

const PubSub = require('pubsub-js');


class ArticleParagraphActions extends Component {

    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <ArticleParagraphActionsEditMode paragraph={paragraph} addParagraph={this.props.addParagraph}
                                                    moveParagraph={this.props.moveParagraph}
                                                    app={this.props.app}/>
        } else {
            return <ArticleParagraphActionsView paragraph={paragraph} app={this.props.app}/>
        }
    }
}

class ArticleParagraphActionsView extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {execute: null, narrow: this.props.paragraph.actions.length > 8};
        this.executeAction = this.executeAction.bind(this);
        this.actionSelected = this.actionSelected.bind(this);
        this.showAll = this.showAll.bind(this);
    }

    executeAction() {
        return <Redirect to={'/search/' + this.state.execute.action}/>;
    }

    actionSelected(action) {
        window.location = this.props.app.app_url + "/search/" + action.action;
        // this.setState({execute:action});
    }

    showAll() {
        this.setState({narrow: false});
    }

    render() {
        if (this.state.execute != null) {
            return this.executeAction();
        } else {
            const paragraph = this.props.paragraph;
            let i = 0;

            const actionsView = paragraph.actions.map((action) => {
                i = i % 12 + 1;
                return <ActionManager key={action.action + "-" + action.title} action={action.action}
                               className={"OfferButtonSize PromoterActionButton BackgroundHighlight" + i}>{action.title}</ActionManager>
            })
            return <div className="PaddingDependsOnWidth">
                {paragraph.title && <div><h2>{paragraph.title}</h2></div>}
            <div>
                <div className="ArticleParagraphActionsView">
                    {actionsView}
                </div>
                {/*{this.state.narrow && <div className="ArticleParagraphActionsViewShowMore">*/}
                {/*    <ActionManager className="transparentButton" onClick={this.showAll}>Mutass többet...</ActionManager>*/}
                {/*</div>}*/}
            </div>
            </div>
        }
    }
}

class ArticleParagraphActionsEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {paragraph: this.props.paragraph, showEditor: this.props.paragraph.newbie === true};
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);
    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }

    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    save(title, actions) {
        this.setState({paragraph: {...this.state.paragraph, ...{title: title, actions: actions}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });

    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        return <div>

            {!showEditor && <div><ArticleParagraphActionsView paragraph={paragraph}/> <Padding>
                <button onClick={this.showEditor}>...</button>
            </Padding></div>}

            {showEditor &&
            <ArticleParagraphActionsEditor title={paragraph.title} actions={paragraph.actions}
                                           buttons={<NewParagraphButtons onRemove={this.remove}
                                                                         onAddParagraph={this.addParagraph}/>}
                                           onCancel={this.hideEditor} onSave={this.save}/>}
        </div>
    }
}

class ArticleParagraphActionsEditor extends Component {


    constructor(props, context) {
        super(props, context);

        let actions = [{title: "", action: "SEARCH:"}];
        if (this.props.actions && this.props.actions.length > 0) actions = this.props.actions
        this.state = {
            title: this.props.title,
            actions: actions
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
        this.onActionChanged = this.onActionChanged.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.title, this.state.actions);
    }

    onTitleChanged(index, event) {
        const target = event.target;
        const value = target.value;
        const actions = this.state.actions;
        actions[parseInt(index)].title = value
        this.setState({actions: actions});
    }


    onActionChanged(index, event) {
        const target = event.target;
        const value = target.value;
        const actions = this.state.actions;
        actions[parseInt(index)].action = value
        this.setState({actions: actions});
    }

    addNewItem() {
        const actions = this.state.actions;
        actions.push({title: "", action: "SEARCH:"})
        this.setState({actions: actions})
    }

    deleteItem(index) {
        const actions = this.state.actions;
        actions.splice(index, 1)
        this.setState({actions: actions});
    }

    render() {
        return <div>
            <div className="ThemeSolidBackground BlockEditorTitle">
                <button className="BlockEditorTitleButton" onClick={this.addNewItem}>Új elem</button>
            </div>

            {this.state.actions.map((action, i) => {
                return <div className="ArticleParagraphActionsEditorElement">
                    <input className="ArticleParagraphActionsEditorField" type="text" value={action.title}
                           onChange={(e) => this.onTitleChanged(i, e)}/>:

                    <input className="ArticleParagraphActionsEditorField" type="text" value={action.action}
                           onChange={(e) => this.onActionChanged(i, e)}/>

                    <button className="grayButton ParagraphButton ParagraphButtonTrash"
                            onClick={(e) => this.deleteItem(i)}>&nbsp;</button>
                </div>
            })}

            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


export default ArticleParagraphActions;