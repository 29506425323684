import React, {Component} from "react";
import {Link} from "react-router-dom";
import './SectionHeader.css';

class SectionHeader extends Component {


    render() {
        const title = this.props.title;
        const button = this.props.button;
        const link = this.props.link;
        if (button != null) {
            return <div>
                <div>
                    <div className="SectionTitleButton">
                        <Link className="SectionTitleButtonLink ColorLink"
                              to={link}>{button}</Link>
                    </div>
                    <h2 className="SectionTitle">{title}</h2>
                </div>
                <div className="SectionTitleClear"/>
            </div>
        } else {
            return <h2 className="SectionTitle">{title}</h2>
        }
    }
}

export default SectionHeader;