import './ImageWithTitle.css'
import React, {Component} from "react";

class ImageWithTitle extends Component {

    render() {
        const title = this.props.title;
        const link = this.props.link;
        const image = this.props.image;
        const colorClass = this.props.colorClass;
        return <a href={link} className="ImageWithTitleSectionSize ImageWithTitleDisplay">
            <div className="ImageWithTitleSectionItemParent">
                <div className="ImageWithTitleSectionItem"
                     style={{backgroundImage: 'url(' + image + ")"}}>
                </div>
                <SmallOfferTitle title={title} colorClass={colorClass}/>
            </div>
        </a>
    }
}

class ImageWithTitleSelectable extends Component {

    render() {
        const obj = this.props.obj;
        const selected = this.props.selected;
        const title = this.props.title;
        const image = this.props.image;
        const colorClass = this.props.colorClass;

        let stateClass = "ImageWithTitleSelectableSelectionOff";
        if (selected) stateClass = "ImageWithTitleSelectableSelectionOn";

        return <div className="ImageWithTitleSectionSize ImageWithTitleDisplay" onClick={(e)=> this.props.onChange(obj)}>
            <div className="ImageWithTitleSectionItemParent">
                <div className="ImageWithTitleSectionItem"
                     style={{backgroundImage: 'url(' + image + ")"}}>
                </div>
                <SmallOfferTitle title={title} colorClass={colorClass}/>
                <div className="ImageWithTitleSectionItem">
                    <div className={"ImageWithTitleSelectableSelection "+stateClass}/>
                </div>

            </div>
        </div>
    }
}

class OfferTitle extends Component {


    render() {

        const random = 1 + parseInt(Math.random() * 1000, 10) % 13;
        const colorClass = this.props.colorClass || "BackgroundHighlight" + random;

        const visible = this.props.visible;
        const title = this.props.title;
        if (visible)
            return <div className={"TitleOnOffer " + colorClass}>{title}</div>
        else
            return <span>&nbsp;</span>;

    }
}


class SmallOfferTitle extends Component {


    render() {

        const random = 1 + parseInt(Math.random() * 1000, 10) % 12;
        const colorClass = this.props.colorClass || "BackgroundHighlight" + random;

        const title = this.props.title;
        return <div className={"SmallTitleOnOffer MediumTextSize " + colorClass}>{title}</div>
    }
}


export default ImageWithTitle;
export {OfferTitle, SmallOfferTitle, ImageWithTitleSelectable};