import React, {Component} from "react";
import {Helmet} from "react-helmet";
import ajax from "superagent";
import './Barqboard.css';
import Sidebar, {SidebarBarqboard} from "../sidebar/Sidebar";
import Container, {ScrollToTop} from '../components/Components'
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import Loading from "../components/Loading";
import BarqboardSections from "./BarqboardSections";

class PageBoard extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {data: null};
    }

    componentDidMount() {
        this.downloadBoard();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.board !== prevProps.match.params.board) {
            this.downloadBoard();
        }
    }



    downloadBoard() {
        ajax
            .post(this.props.app.server_url + '/board/loadBoard')
            .query({"client_build": "100", "platform": "react",
                "lang": this.props.app.lang,
                board: this.props.match.params.board})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                // const log = JSON.stringify(res.body);
                // console.log(log);
                this.setState({data: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        if (this.state.data != null) {
            return (
                <div>
                    <Helmet>
                        <title>barq.me | Talájl neked való programokat!</title>
                        <meta name="description" lang="hu"
                              content="barq.me programajánló. A barq.me célja segítséget nyújtani neked olyan szabaidős programokat találni, amikről amúgy könnyen lemaradnál vagy elfelejtenéd őket. Események összegyűjtése, Érdekes események ajánlása, Események böngészése, Ha megtaláltad, ne is felejtsd el, Közös program barátokkal"/>
                    </Helmet>
                    <BarqMeCookies/>
                    <Sidebar settings={this.props.settings}><SidebarBarqboard app={this.props.app}
                                                                              user={this.props.user}/></Sidebar>
                    <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                    <Container app={this.props.app} user={this.props.user}>
                        <BarqboardSections data={this.state.data} app={this.props.app} user={this.props.user}/>
                    </Container>
                    <ScrollToTop app={this.props.app}/>
                </div>
            );
        } else {
            return <Loading loading={true}/>
        }
    }
}

export default PageBoard;
