import React, {Component} from "react";
import {ArticleParagraphMediaImageView} from "../../article/paragraph/ArticleParagraphMediaImage";

class ImageSection extends Component {

    render() {
        const section = this.props.section;
        const paragraph = {
            action: section.image.action,
            code: section.code,
            title: section.image.title,
            place: "",
            showTitle: section.image.showTitle,
            image: section.image.image
        }
        return <ArticleParagraphMediaImageView paragraph={paragraph}/>
    }
}

export default ImageSection;
