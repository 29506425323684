import '../Barqboard.css';
import React, {Component} from "react";
import {Padding} from "../../components/Components";
import {TagsSectionItem} from "./TagsSection";
import {Link} from "react-router-dom";
import SectionHeader from "./SectionHeader";
import {TranslateMessage, translateMessage} from "../../i18n/TranslateMessage";
class SubscriptionsSection extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        const section = this.props.section;
        const subscriptions = section?.subscriptions?.subscriptions;
        if (subscriptions != null) {

            return (
                <div className="PaddingDependsOnWidth">
                    <div className="Section ModernSection SectionWidthContainer">



                        <SectionHeader title={section.title} button={translateMessage("section_button_settings")} link={"/my-interests"}/>
                            {(subscriptions.length > 0) &&
                            <div className="">
                                {(subscriptions.length > 0) && subscriptions.map((subscription, i) =>
                                    <TagsSectionItem key={'subscription' + subscription.subscriptionId} tag={subscription.text} display={subscription.name}
                                                     app={this.props.app}/>
                                )}
                            </div>}
                            {(subscriptions.length === 0) && <Padding>
                                <div className="NoInterests">Nincsenek olyan érdeklődéseid, amelyekhez legalább egy barq
                                    tartozik.
                                </div>
                            </Padding>}
                    </div>
                </div>
            );
        } else {
            return null;
        }

    }
}

// class SubscriptionsSectionItem
//     extends Component {
//
//     render() {
//         const subscription = this.props.subscription;
//         return <div className="BarqboardSubscription">
//             <Link to={'/list/SEARCH:' + subscription.text}>
//                 <div className="image"
//                      style={{backgroundImage: `url(${subscription.imageUrl})`}}>&nbsp;</div>
//                 <div className="content">{subscription.name}</div>
//             </Link>
//         </div>
//     }
//
// }

export default SubscriptionsSection;