import React, {Component} from "react";
import './BarqBadges.css';

class BarqBadges extends Component {


    render() {
        const badges = this.props.badges;
        let extraCss = "BarqBadgesNormalSize";
        if (this.props.extraCss)
            extraCss = this.props.extraCss;

        if (badges?.length > 0) {
            const badgesView = badges.map((badge) => {
                return <BadgeItem badge={badge}/>
            })
            return <div className={"BarqBadges " + extraCss}>{badgesView}</div>
        } else {
            return null
        }
    }
}


class BadgeItem extends Component {

    render() {
        const badge = this.props.badge;
        return <div className="BadgeImage" style={{backgroundImage:"url("+badge.imageUrl+")"}}/>
    }
}

export default BarqBadges;