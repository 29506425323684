import React, {Component} from 'react';
import './Cancellations.css';
import ajax from "superagent";
import Moment from "react-moment";
import {InfoMessage} from "../../components/Components";

class Cancellations extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            cancellations: []
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    render() {
        const location = this.props.location;
        return <div>
            <h2>Zárvatartási időszakok</h2>
            <div>
                Ha az intézményed egy időre bezár és elmaradnak az előadásai, azokat itt jelezheted.
                {location.cancellations.map((cancellation) => {
                    return <CancellationItem key={"cancellation-"+cancellation.cancellationId} app={this.props.app} user={this.props.user} cancellation={cancellation}/>
                })}
            </div>
            <CancellationNew app={this.props.app} user={this.props.user} location={location}/>
        </div>
    }

}

class CancellationItem extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            cancellation: {},
            modes: []
        }
    }

    render() {
        const cancellation = this.props.cancellation;
        return <div className="CancellationItem">
            <div className="Col Col1">{cancellation.mode.display}</div>
            <div className="Col Col4">{cancellation.details}</div>
            <div className="Col Col2"><Moment add={{hours: 2}} format="YYYY.MM.DD">{cancellation.firstDay}</Moment>
            </div>
            <div className="Col Col3"><Moment add={{hours: 2}} format="YYYY.MM.DD">{cancellation.lastDay}</Moment></div>
            <div className="Col Col1">{cancellation.state}</div>

        </div>
    }


}

class CancellationNew extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            cancellation: {firstDay: "", lastDay: "", details: "", mode: null, web: "", articleId: ""},
            modes: []
        }
        this.saveCancellation = this.saveCancellation.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
    }

    componentDidMount() {
        this.downloadModes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    downloadModes() {
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/cancellation_modes')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({modes: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (name !== "mode") {
            this.setState({cancellation: {...this.state.cancellation, ...{[name]: value}}});
        } else {
            const mode = this.state.modes.find((m) => m.tagId == value) || null;
            this.setState({cancellation: {...this.state.cancellation, ...{mode: mode}}});
        }
    }

    saveCancellation() {
        const cancellation = this.state.cancellation;
        cancellation.location = this.props.location;
        console.log(cancellation);
        ajax
            .post(this.props.app.server_url + '/editor/location_admin/cancellation_save')
            .query({"platform": "react"})
            .send(cancellation)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {

            })
            .catch((err) => {
                console.log(err);
                if (err.status === 409)
                    alert(JSON.stringify(err.response.text));
                else
                    throw err;
            });
    }

    render() {
        const cancellation = this.state.cancellation;
        return <div>
            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_cancellation_first">Első nap:</label> <input
                id="form_cancellation_first" type="text" name="firstDay"
                value={cancellation.firstDay || ""} onChange={this.onPropertyChanged}/>
            </div>
            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_cancellation_last">Utolsó nap:</label> <input
                id="form_cancellation_last" type="text" name="lastDay"
                value={cancellation.lastDay || ""} onChange={this.onPropertyChanged}/>
            </div>
            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_cancellation_details">Leírás:</label> <input
                id="form_cancellation_details" type="text" name="details"
                value={cancellation.details || ""} onChange={this.onPropertyChanged}/>
            </div>

            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_web">A közbeeső eseményekkel mi történik?</label>
                <select name="mode" onChange={this.onPropertyChanged} value={cancellation?.mode?.tagId}>
                    <option>Válassz</option>
                    {this.state.modes.map((mode) => {
                        return <option key={"mode-"+mode.tagId} value={mode.tagId}>{mode.display}</option>
                    })}
                </select>
            </div>

            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_cancellation_article">Cikk:</label> <input
                id="form_cancellation_article" type="text" name="articleCode"
                value={cancellation.articleCode || ""} onChange={this.onPropertyChanged}/>
            </div>
            <div className="EditorRow ThemeBackground">
                <label htmlFor="form_cancellation_web">Web:</label> <input
                id="form_cancellation_web" type="text" name="web"
                value={cancellation.web || ""} onChange={this.onPropertyChanged}/>
            </div>

            <InfoMessage>
                <b>Mielőtt elmented a változtatást!</b> -
                Ha az intervallumot elmented, akkor
                <ul>
                    <li>az összes, jelenleg intervallumba eső barq megjelölésre kerül az elmaradás módjával</li>
                    <li>az összes, jövőben, az intervallumba eső dátummal létrehozott barq megjelölésre kerül az
                        elmaradás módjával
                    </li>
                    <li>a helyszínnél meg fog jelenni az intervallum</li>
                    <li>Az intervallum a jövőben nem törölhető, csak visszavonható. ez azt jelenti, hogy az intervallum
                        listázódni fog, mint visszavont intervallum.
                    </li>
                    <li>Automatikus poszt készül a promóter profidhoz az intervallumról</li>
                    <li>Az érintett felhasználók értesítést kapnak az őket érintett eseményekről</li>
                </ul>
            </InfoMessage>
            <button onClick={this.saveCancellation}>Mentés</button>
        </div>
    }

}

export default Cancellations;