import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import './PromoterOffers.css';
import {Padding} from "../../components/Components";
import ajax from "superagent";
import Loading from "../../components/Loading";


class PromoterOffers extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {result: {result: null}};
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
    }


    componentDidMount() {
        this.downloadOffers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadOffers() {
        ajax
            .post(this.props.app.server_url + '/editor/offer/offers')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    save() {
        ajax
            .post(this.props.app.server_url + '/editor/offer/save')
            .query({"platform": "react"})
            .send(this.state.result.subscriptions)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body});
                alert("MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onChange(subscription) {
        const result = this.state.result;
        const index = result.subscriptions.findIndex((s) => s.subscriptionId === subscription.subscriptionId);
        result.subscriptions[index].published = !result.subscriptions[index].published
        this.setState({result: result})
    }


    render() {
        const result = this.state.result;
        let contentView;
        if (result.subscriptions == null) {
            contentView = <Loading loading={true}/>

        } else if (result.subscriptions.length === 0) {
            contentView = <Padding>Nincsenek érdeklődések és ajánlók</Padding>
        } else {
            const offersContent = result.subscriptions.map((subscription) =>
                <SubscriptionItem key={'subscription-' + subscription.subscriptionId} subscription={subscription}
                                  app={this.props.app} onChange={this.onChange} editorMode={this.props.editorMode}/>
            );
            contentView = <div className="">{offersContent}</div>
        }

        return <div>
            <Helmet>
                <title>barq.me | {this.props.promoter.location.name} események</title>
                <meta name="description" lang="hu" content="Promóter oldal"/>
            </Helmet>
            <Padding>
                <hr/>
                {this.props.editorMode && <button onClick={this.save}>Mentés</button>}
                <h1>Ajánlatok és érdeklődések</h1>
                <p>Itt megoszthatod másokkal az érdeklődéseidet.</p>
            </Padding>
            {contentView}
        </div>
    }
}

class SubscriptionItem extends Component {


    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        this.props.onChange(this.props.subscription);
    }

    render() {
        const offer = this.props.subscription;

        let publicityClass;
        if (!this.props.editorMode) {
            if (offer.published)
                publicityClass = "SubscriptionItemPublished";
            else
                publicityClass = "SubscriptionItemPrivate";
        }

        return <div className="SubscriptionItem ThemeBackground">
            <img src={offer.imageUrl} className="SubscriptionItemImage"/>
            <div className="SubscriptionItemContent">
                <div className="SubscriptionItemName">{offer.name}</div>
                <div className="SubscriptionItemText">{offer.text}</div>
            </div>
            <div className={'SubscriptionItemPublicity ' + publicityClass}>
                {this.props.editorMode &&
                <input className="SubscriptionItemPublicityCheckbox" type="checkbox" checked={offer.published}
                       onChange={this.onChange}/>}
            </div>

        </div>
    }

}

export default PromoterOffers;