import '../Barqboard.css';
import React, {Component} from "react";
import ActionManager from "../../action/ActionManager";


class InfoSection extends Component {

    render() {
        const section = this.props.section;
        let fullAction;
        let articleAction;

        section.actions.map((action) => {
            if (fullAction == null && action.fullLink) {
                fullAction = action;
            }
            if (articleAction == null && action.action.startsWith("article:")) {
                articleAction = action;
            }
        });

        const action = fullAction || articleAction;

        const compSize = section.size === "small" ? 'ArticleMediaHeightSize' : 'ArticleMediaHeightDoubleSize';
        return <div className={'Section SectionWidthContainer InfoSection ' + compSize}>
            <ActionManager action={action.action}>
                <div className={'InfoSectionImage ' + compSize} style={{
                    backgroundImage: `url(${section.image.imageUrlMedium})`
                }}>

                    <div className="content">
                        <div className="title">
                            {section.title}
                        </div>
                        <div className="text">
                            {section.text}
                        </div>
                    </div>
                </div>
            </ActionManager>
        </div>
    }

}

export default InfoSection;