import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import queryString from "query-string";
import BarqEditor from "./BarqEditor";
import {BarqsList} from "./BarqAdmin";
import BarqBatchUpload from "./BarqBatchUpload";
import BarqImagesUpload from "./BarqImagesUpload";
import BarqBatchGoogleSheet from "./BarqBatchGoogleSheet";

class MyBarqs extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const barqId = values["barq"] || null;
        let tab = values["mode"] || "list";
        if (barqId != null) tab = "editor";

        this.state = {tab: tab, barq: {barqId: barqId}};
        this.changeTab = this.changeTab.bind(this);
        this.newBarq = this.newBarq.bind(this);
        this.editBarq = this.editBarq.bind(this);
        this.batchUploadBarq = this.batchUploadBarq.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeTab(tab) {

        let barqId = this.state.barq.barqId;
        if (tab === "list") barqId = null;
        let hash = '#tab=my-events';
        if (barqId != null) {
            hash = hash + "&mode=editor&barq=" + barqId;
        } else if (tab === "editor") {
            hash = hash + "&mode=editor";
        } else if (tab === "batch") {
            hash = hash + "&mode=batch";
        }
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }
        this.setState({tab: tab}, () => {
        });
    }

    closeEditor() {
        this.changeTab("list");
    }

    newBarq() {
        this.setState({tab: "editor", barq: {barqId: null}}, (e) => {
            this.changeTab("editor");
        })

    }

    editBarq(barq) {
        this.setState({tab: "editor", barq: barq}, () => {
            this.changeTab("editor");
        })

    }


    batchUploadBarq() {
        this.setState({tab: "batch"}, () => {
            this.changeTab("batch");
        })
    }

    uploadImages() {
        this.setState({tab: "images"}, () => {
            this.changeTab("images");
        })
    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Eseményeim</title>
                <meta name="description" lang="hu" content="Cikkek"/>
            </Helmet>

            {this.state.tab === "list" &&
            <BarqsList mode="user" editorMode={this.props.editorMode} app={this.props.app} onNewBarq={this.newBarq}
                       onEditBarq={this.editBarq}
                       onBatchUploadBarq={this.batchUploadBarq} onUploadImages={this.uploadImages}
            />}
            {this.state.tab === "editor" &&
            <BarqEditor editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}
                        barq={this.state.barq}/>}
            {this.state.tab === "batch" &&
            <div>
                <BarqBatchUpload editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}/>
                <BarqBatchGoogleSheet editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}/>
            </div>}

            {this.state.tab === "images" &&
            <BarqImagesUpload editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}/>}
        </div>
    }
}

export default MyBarqs;