import React, {Component} from 'react';
import Container, {Padding} from "../components/Components";
import Sidebar, {SidebarBlock} from "../sidebar/Sidebar";
import {Link, Redirect} from "react-router-dom";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import "./MyProfile.css";
import Moment from "react-moment";
import {TranslateMessage} from "../i18n/TranslateMessage";

class PageMyProfile extends Component {

    constructor(props, context) {
        super(props, context);
        this.handleLogout = this.handleLogout.bind(this);
    }


    handleLogout(event) {
        this.props.onLogout();
        event.preventDefault();
    }

    render() {
        const user = this.props.user;
        if (!user.anonym) {

            let userName;
            if (user.provider === "barqme")
                userName = user.userName
            else if (user.anonym)
                userName = "Vendgég felhasználó";
            else if (user.provider === "google")
                userName = "Google felhasználó";
            else if (user.provider === "apple")
                userName = "Apple felhasználó";
            else if (user.provider === "facebook")
                userName = "Facebook felhasználó";
            else
                userName = "http://barq.me"

            return <div>
                <BarqMeCookies/>
                <Sidebar settings={this.props.settings}>

                    <SidebarBlock title="Linkek">
                        <ul>
                            <li><Link to="/">Barqboard</Link></li>
                            <li><Link to="/page/terms-of-use">Felhasználási feltételek</Link></li>
                        </ul>
                    </SidebarBlock>

                </Sidebar>
                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    <Padding>

                        <div className="MyProfileHeader">
                            <div className="MyProfileImage">
                                <img title="Felhasználói profilkép" alt="Felhasználói profilkép"
                                     className="Image" src={user.imageName}/>
                            </div>
                            <div className="MyProfileData">
                                <div className="Title">{user.name}</div>
                                <div>{userName}</div>
                                <div>Csatlakozás időpontja: <Moment format="YYYY.MM.DD">{user.dateOfConnect}</Moment>
                                </div>
                                <div>
                                    <button className="largeRedButton" onClick={this.handleLogout}>Kijelentkezés
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className="">Előzmények</h1>

                            <Link className="MyProfileDataLink" to={"/search/id:userliked"}>Kedvelt eseményeim</Link>
                            <Link className="MyProfileDataLink" to={"/search/id:userdisliked"}>Nem kedvelt eseményeim</Link>
                            <Link className="MyProfileDataLink" to={"/search/id:userflagged"}>Könyvjelzős eseményeim</Link>
                            <Link className="MyProfileDataLink" to={"/search/id:history"}>Korábban megtekintett eseményeim</Link>


                            <h1 className="">Profilbeállítások</h1>
                            {user.provider === "barqme" && <Link className="MyProfileDataLink" to={"/change-password"}><TranslateMessage id="change_password_title"/></Link>}
                            <Link className="MyProfileDataLink" to={"/my-interests"}>Feliratkozásaim</Link>
                            <Link className="MyProfileDataLink" to={"/my-messages"}>Üzeneteim</Link>

                            <h1 className="">Rólunk</h1>
                            <Link className="MyProfileDataLink" to={"/about"}>Névjegy</Link>
                            <Link className="MyProfileDataLink" to={"/page/share"}>Alkalmazás megosztás</Link>
                            <Link className="MyProfileDataLink" to={"/page/terms-of-service"}>Felhasználási feltételek</Link>
                            <Link className="MyProfileDataLink" to={"/page/privacy-policy"}>Adatkezelési szabályzat</Link>

                        </div>
                    </Padding>
                </Container>
            </div>
        } else {
            return <Redirect to="/"/>
        }
    }
}

export default PageMyProfile;