import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./BarqItemCard.css";
import Moment from "react-moment";
import TimeToGoString, {ambiguousTime, timeToGo} from "./TimeToGoCalculator";
import {displayBarqStartTime, translateMessage} from "../i18n/TranslateMessage";
import BarqBadges from "./BarqBadges";

class BarqItemCard extends Component {

    constructor(props, context) {
        super(props, context);
        this.editBarq = this.editBarq.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    editBarq(event) {
        this.props.onEditBarq(this.props.barq);
        event.preventDefault();
    }

    onSelectionChanged(event) {
        const target = event.target;
        const value = target.checked;
        this.props.onSelectionChanged(this.props.barq, value);
        event.stopPropagation();
        return false;
    }

    render() {
        const barq = this.props.barq;
        const location = barq.location;

        const useColorClasses = false && this.props.index != null;
        let colorClass = "HighlightBackground";
        if (useColorClasses) {
            const random = 1 + this.props.index % 12;
            colorClass = "BackgroundHighlight" + random;
        }

        let sizeClass= this.props.sizeClass || "BarqItemCardSize";
        let link = '/barq/' + barq.barqId+"/"+barq.titleUrl + (this.props.sid ? "#sid="+this.props.sid : "")




        return <div className={"BarqItemCard ThemeBackground ShadowBottom "+sizeClass}>
            <Link to={link}>


                <div className="BarqItemCardImage">
                    <div className="BarqItemCardImageOverlayParent">
                        <div className="BarqboardBarqOverlay BarqItemCardImageOverlay"
                             style={{backgroundImage: "url('/barqme-no-image.png')"}}/>

                        <div className="BarqboardBarqOverlay BarqItemCardImageOverlay"
                             style={{backgroundImage: `url(${barq.imageUrl || '/barqme-no-image.png'})`}}/>

                        {barq.fullBadge && <div className="BarqItemCardImageOverlay fullBadge"
                                                style={{backgroundImage: `url(${barq.fullBadge.imageUrl || ''})`}}/>}


                    </div>
                    <div className="BarqItemCardImageOverlayParent">
                        <BarqBadges badges={barq.badges} extraCss="BarqBadgesNormalSize"/>
                    </div>

                </div>
                <div className="BarqItemCardImageDetails">
                    <div className="BarqItemCardImageTitle">
                        {barq.title}
                    </div>
                    <div className="BarqItemCardImageText">
                        <div className="BarqItemCardImageTextTime">
                            {displayBarqStartTime(barq)}
                        </div>
                        <div className="BarqItemCardImageTextLocation">
                            {location.name}
                        </div>
                        <div className="BarqItemCardImageTextTimeToGo">
                            <TimeToGoString barq={barq} extraCss="HighlightColor"/>
                        </div>
                    </div>

                </div>

            </Link>
        </div>
    }
}

class BarqItemCardEmpty extends Component {

    render() {
        let sizeClass= this.props.sizeClass || "BarqItemCardSize";

        return <div className={"BarqItemCard ThemeBackground ShadowBottom "+sizeClass}>



                <div className="BarqItemCardImage ThemeSolidBackground">

                </div>
                <div className="BarqItemCardImageDetails">
                    <div className="BarqItemCardImageTitle ThemeSolidColor">
                        &#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;<br/>
                        &#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;
                    </div>
                    <div className="BarqItemCardImageText">
                        <div className="BarqItemCardImageTextTime ThemeSolidColor">
                            &#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;
                        </div>
                        <div className="BarqItemCardImageTextLocation ThemeSolidColor">

                            &#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;&#9644;
                        </div>
                    </div>

                </div>
        </div>
    }
}

class BarqItemCardShowAll extends Component {


    render() {
        let sizeClass= this.props.sizeClass || "";

        return <div className={"BarqItemCard BarqItemCardShowAll ThemeSolidBackground "+sizeClass}>
            <Link to={this.props.to}>
                <div className="BarqItemCardShowAllContent">
                        {this.props.children}
                </div>
            </Link>
        </div>
    }
}

class BarqItemCardAll extends Component {

    render() {
        return <div className="BarqItemCardAll BarqItemCard ThemeBackground ShadowBottom BarqItemCardSize">
            <Link to={this.props.to}>
                <div className="BarqItemCardImageDetails BarqItemCardAllContent">
                    <div>
                        {this.props.children}
                    </div>
                </div>
            </Link>
        </div>
    }
}

export default BarqItemCard;
export {BarqItemCardEmpty, BarqItemCardShowAll, BarqItemCardAll};