import React, {Component} from "react";
import ajax from "superagent";
import PubSub from "pubsub-js";

class UnsubscriptionAction extends Component {


    constructor(props, context) {
        super(props, context);
        this.unsubscribe = this.unsubscribe.bind(this);
    }

    componentDidMount() {
        this.unsubscribe();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    unsubscribe() {
        const text = this.props.text;
        ajax
            .post(this.props.app.server_url + '/subscription/unsubscribe')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                text: text})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                PubSub.publish("SUBSCRIPTION_UNSUBSCRIBED", res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        return null
    }

}

export default UnsubscriptionAction;