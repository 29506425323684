import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import './PageEditor.css';
import Container from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import ajax from "superagent";
import BarqMeCookies from "../BarqMeCookies";
import PageEditorRegistration from "./EditorRegistration";
import PageEditorNotAvailable from "./EditorNotAvailable";
import PageEditorWaiting from "./EditorWaiting";
import EditorComponent from "./EditorComponent";
import BarqMeTopbar from "../topbar/Topbar";
import Loading from "../components/Loading";

class PagePromoter extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {promoter: null};
    }

    componentDidMount() {
        this.downloadPromoter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadPromoter() {
        ajax
            .post(this.props.app.server_url + '/editor/promoter/get')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({promoter: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }



    render() {
        const promoter = this.state.promoter;
        const user = this.props.user;

        let webContent;
        if (user.anonym) {
            webContent = <PageEditorNotAvailable app={this.props.app} user={this.props.user}/>
        } else if (promoter != null) {


            if (promoter.state === "requested") {
                webContent = <PageEditorWaiting promoter={this.state.promoter} app={this.props.app} user={this.props.user}/>
            } else if (promoter.state === "") {
                webContent = <PageEditorRegistration app={this.props.app} user={this.props.user}/>

            } else {
                webContent = <EditorComponent promoter={this.state.promoter} app={this.props.app} user={this.props.user}/>

            }
        } else {
            webContent =  <Loading loading={true}/>
        }


        return <div>
            <Helmet>
                <title>barq.me | Promóter oldal</title>
                <meta name="description" lang="hu" content="Promóter oldal"/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={true} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                {webContent}
            </Container>
        </div>
    }
}

export default PagePromoter;