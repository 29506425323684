import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import ajax from "superagent";
import {Padding} from "../../components/Components";
import PageArticleEditor from "../../article/ArticleEditor";
import {PromoterTabFooter, PromoterTabTitle} from "../EditorComponent";
import {MediaSelectorComponent} from "../media/MediaEditor";

class PromoterData extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {promoter: this.props.promoter};
        this.updateArticle = this.updateArticle.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.save = this.save.bind(this);
    }

    save(event) {
        const promoter = this.state.promoter;
        ajax
            .post(this.props.app.server_url + '/editor/promoter/save')
            .query({...{"platform": "react"}})
            .send(promoter)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({promoter: res.body});
                this.props.onPromoterChange(res.body);
                alert("MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        event.preventDefault();
    }

    updateArticle(article) {
        const promoter = this.state.promoter;
        promoter.article = article;
        this.setState({promoter: promoter})
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({promoter: {...this.state.promoter, ...{[name]: value}}});
    }


    render() {
        const promoter = this.state.promoter;
        return <div>
            <Helmet>
                <title>barq.me | {promoter.name}</title>
                <meta name="description" lang="hu" content="Promóter oldal"/>
            </Helmet>
                <PromoterTabTitle title="Bemutatkozás"/>
                {!this.props.editorMode && <div>

                    {promoter.imageDoubleHeader && <div className="PromoterImageDoubleHeader" style={{backgroundImage: 'url(' + promoter.imageDoubleHeader + ")"}}>
                        <div className="PromoterImageLogo" style={{backgroundImage:'url('+promoter.imageLogo+")"}}/>
                        <div className="PromoterImageCorner" style={{backgroundImage:'url('+promoter.imageCornerBadge+")"}}/>
                    </div>}
                    {promoter.imageHeader && <div className="PromoterImageHeader" style={{backgroundImage: 'url(' + promoter.imageHeader + ")"}}>
                        <div className="PromoterImageLogo" style={{backgroundImage:'url('+promoter.imageLogo+")"}}/>
                        <div className="PromoterImageCorner" style={{backgroundImage:'url('+promoter.imageCornerBadge+")"}}/>

                    </div>}


                </div>}
            <Padding>

                {this.props.editorMode && <div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (desktop):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderDesktop"
                                                value={promoter.imageHeaderDesktop} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (tablet):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderTablet"
                                                value={promoter.imageHeaderTablet} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (mobile):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderMobile"
                                                value={promoter.imageHeaderMobile} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="imageLogo">Promóter logó</label>

                        <MediaSelectorComponent id="imageLogo" name="imageLogo" value={promoter.imageLogo} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>


                        {/*<input*/}
                        {/*id="imageLogo" type="text" name="imageLogo"*/}
                        {/*value={promoter.imageLogo} onChange={this.onPropertyChanged}/>*/}
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="imageCornerBadge">Promóter logó</label>


                        <MediaSelectorComponent id="imageCornerBadge" name="imageCornerBadge" value={promoter.imageCornerBadge} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>


                        {/*<input*/}
                        {/*id="imageCornerBadge" type="text" name="imageCornerBadge"*/}
                        {/*value={promoter.imageCornerBadge} onChange={this.onPropertyChanged}/>*/}
                    </div>
                </div>}

            </Padding>

            <PageArticleEditor article={this.state.promoter.article} onArticleChanged={this.updateArticle}
                               app={this.props.app} user={this.props.user} editorMode={this.props.editorMode} hideArticleData={true}/>

            <Padding>
                <PromoterTabFooter onSave={this.save} editorMode={this.props.editorMode}/>
            </Padding>
        </div>
    }
}

export default PromoterData;