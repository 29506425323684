import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import './Festivals.css'
import ajax from "superagent";
import queryString from "query-string";
import {Padding} from "../../components/Components";
import FestivalEditor from "./FestivalEditor";
import {PromoterTabTitle} from "../EditorComponent";

class Festivals extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const festivalId = values["festival"] || null;
        const parentId = values["parent"] || null;
        let tab = values["mode"] || "list";
        if (festivalId != null) tab = "editor";

        this.state = {tab: tab, festival: {festivalId: festivalId, parent: {festivalId: parentId}}};
        this.changeTab = this.changeTab.bind(this);
        this.newFestival = this.newFestival.bind(this);
        this.editFestival = this.editFestival.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeTab(tab) {

        let festivalId = this.state.festival.festivalId;
        let parentId = this.state.festival.parent ? this.state.festival.parent.festivalId : null;

        if (tab === "list") festivalId = null;
        let hash = '#tab=festivals';
        if (festivalId != null) {
            if (parentId) {
                hash = hash + "&mode=editor&festival=" + festivalId + "&parent=" + parentId;
            } else {
                hash = hash + "&mode=editor&festival=" + festivalId;
            }
        } else if (tab === "editor") {
            if (parentId) {
                hash = hash + "&mode=editor&parent=" + parentId;
            } else {
                hash = hash + "&mode=editor";
            }
        }
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }
        this.setState({tab: "load"}, () => {
            this.setState({tab: tab}, () => {
            });
        });
    }

    closeEditor() {
        this.changeTab("list");
    }

    newFestival(parentId) {
        this.setState({tab: "editor", festival: {festivalId: null, parent: {festivalId: parentId}}}, (e) => {
            this.changeTab("editor");
        })

    }

    editFestival(festival) {
        this.setState({tab: "editor", festival: festival}, (e) => {
            this.changeTab("editor");
        })

    }


    render() {
        return <div>
            <Helmet>
                <title>barq.me | Fesztiváljaim</title>
                <meta name="description" lang="hu" content="Fesztiváljaim"/>
            </Helmet>

            {this.state.tab === "list" &&
            <FestivalList editorMode={this.props.editorMode} app={this.props.app} onNewFestival={this.newFestival}
                          onEditFestival={this.editFestival}/>}
            {this.state.tab === "editor" &&
            <FestivalEditor editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}
                            festival={this.state.festival}
                            onNewStage={this.newFestival} onEditStage={this.editFestival}/>}
        </div>
    }
}


class FestivalList extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            result: {festivals: null}
        };
        this.editFestival = this.props.onEditFestival.bind(this);
    }


    componentDidMount() {
        this.downloadFestivals();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadFestivals() {
        ajax
            .post(this.props.app.server_url + '/editor/festival/my-festivals')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({festivals: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        const festivals = this.state.festivals || [];
        const festivalsView = festivals.map((festival, i) => {
            return <FestivalListElement key={"festival-" + festival.festivalId} festival={festival}
                                        className={((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}
                                        editorMode={this.props.editorMode}
                                        onEditFestival={this.editFestival}/>
        })
        return <div>
            <PromoterTabTitle title="Fesztiválok"/>

            <Padding>
                <p>Itt találod azokat a fesztiválokat, amelyeket te hoztál létre. <button className="grayButton"
                                                                                          onClick={this.props.onNewFestival}>Új
                    fesztivál létrehozása</button>
                </p>
                {festivals.length === 0 && <div>Nincsenek fesztiválok!</div>}
                {festivals.length > 0 && <div className="ShowFestivalContainer">{festivalsView}</div>}
            </Padding>
        </div>
    }
}


class FestivalListElement extends Component {

    constructor(props, context) {
        super(props, context);
        this.editFestival = this.editFestival.bind(this);
    }

    editFestival(festival) {
        this.props.onEditFestival(festival)
    }

    render() {
        const festival = this.props.festival;
        return <div className={"FestivalListElement " + this.props.className}>
            <div className="Col Col0"><img src={festival.image.imageDesktop}/></div>
            <div className="Col Col1">{festival.tag ? festival.tag.tag : "-"}</div>
            <div className="Col Col2">{festival.name}</div>
            <div className="Col Col3">
                <button className="barqButton" onClick={(e) => this.editFestival(festival)}>Szerkeszt</button>
            </div>
        </div>
    }

}


class ShowFestival extends Component {


    render() {
        const festival = this.props.festival;
        return <div className="ShowFestivalParent">
            <a className="ShowFestival" onClick={(e) => this.props.onEditFestival(festival)}>
                <div className="PromoterImageHeader" style={{backgroundImage: `url(${festival.imageHeaderUrl})`}}>
                    <div className="FestivalTitle TextSizeLarge TextSizeLargePadding">{festival.name}
                        {this.props.editorMode === true && <span><br/><button
                            onClick={(e) => this.props.onEditFestival(festival)}>...</button></span>}</div>
                </div>
            </a>
        </div>
    }

}

class ShowStage extends Component {


    render() {
        const festival = this.props.festival;
        return <div className="ShowStage">
            <div className="FestivalImage" style={{backgroundImage: `url(${festival.imageHeaderUrl})`}}>
                <div className="FestivalTitle TextSizeLarge TextSizeLargePadding">{festival.name}
                    {this.props.editorMode === true && <span><br/><button
                        onClick={(e) => this.props.onEditFestival(festival)}>Szerkeszt</button></span>}</div>
            </div>
        </div>
    }

}


export default Festivals;
export {FestivalListElement, ShowFestival, ShowStage};