import React, {Component} from "react";
import {FullOverlay} from "../../components/Components";

class EditorPopupDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.onCancel = this.props.onCancel.bind(this);
    }

    render() {
        const width = this.props.width;
        const height = this.props.height;
        const widthVal = width + "px";
        const heightVal = height + "px";
        const marginLeftVal = (-1*width/2)+"px";
        const marginTopVal = (-1*height/2)+"px";

        return <FullOverlay>
            <div className="ArticleParagraphEditorContainer ThemeBackground" style={{marginLeft: marginLeftVal, marginTop: marginTopVal}} >
                <div className={"ArticleParagraphEditor " + this.props.classes}
                     style={{width: widthVal, height: heightVal}}>
                    <div className="EditorPopupDialogCloseParent">
                        <button onClick={this.onCancel} className="EditorPopupDialogClose ThemeBackground">X</button>
                    </div>
                    <div className="EditorPopupDialogContent">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </FullOverlay>

    }

}

export default EditorPopupDialog;