import React, {Component} from "react";
import ajax from "superagent";
import {InfoMessage, Padding} from "../../components/Components";
import {PromoterTabFooter, PromoterTabTitle} from "../EditorComponent";
import PageArticleEditor from "../../article/ArticleEditor";
import './Festivals.css'
import {FestivalListElement} from "./Festivals";
import BarqList from "../../barq/BarqList";
import {MediaSelectorComponent} from "../media/MediaEditor";

class FestivalEditor extends Component {


    constructor(props, context) {
        super(props, context);

        this.state = {festival: null};
        this.updateArticle = this.updateArticle.bind(this);
        this.saveFestival = this.saveFestival.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.onNewStage = this.props.onNewStage.bind(this);
        this.postFestival = this.postFestival.bind(this);
        this.generateArticle = this.generateArticle.bind(this);
        // this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
        this.downloadFestival();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    downloadFestival() {
        let parentId = this.props.festival.parent ? this.props.festival.parent.festivalId : null;
        ajax
            .post(this.props.app.server_url + '/editor/festival/festival')
            .query({"platform": "react", festivalId: this.props.festival.festivalId, parentId: parentId})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({festival: res.body});
                console.log(res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    generateArticle() {
        console.log(this.state.festival.article);

        ajax
            .post(this.props.app.server_url + '/editor/festival/generate-article')
            .query({"platform": "react", festivalId: this.props.festival.festivalId})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                if (res.body!=null) {
                    this.updateArticle(res.body);
                };
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    updateArticle(article) {
        const festival = this.state.festival;
        festival.article = article;
        console.log(article);
        this.setState({festival: festival})
    }

    saveFestival(event) {
        const festival = this.state.festival;
        festival.barqs = null;
        console.log(festival);
        ajax
            .post(this.props.app.server_url + '/editor/festival/save')
            .query({...{"platform": "react"}})
            .send(festival)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({festival: res.body});
                alert("MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        event.preventDefault();
    }

    postFestival() {
        ajax
            .post(this.props.app.server_url + '/editor/post/post_festival')
            .query({"platform": "react", "festivalId": this.state.festival.festivalId})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("A fesztivál posztolása megtörtént!\r\nA poszt elérhető a posztjaid között.")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const festival = {...this.state.festival, ...{[name]: value}}
        this.setState({festival: festival});
    }

    render() {
        const festival = this.state.festival;
        if (festival != null) {
            const stagesView = festival.stages.map((stage, i) => {
                return <FestivalListElement festival={stage}
                                            className={((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}
                                            editorMode={this.props.editorMode}
                                            onEditFestival={this.props.onEditStage}/>
            })

            return <div>
                <PromoterTabTitle title={"Adatok: " + (festival.name || "Névtelen")} onSave={this.saveFestival}
                                  editorMode={this.props.editorMode}/>
                {!this.props.editorMode && <div>
                    <img src={festival.image.imageDesktop }/>

                    <div style={{clear: 'both'}}/>

                    {festival.imageUrl1 && <img className="LocationImage" src={festival.imageUrl1} alt={festival.name+" kép 1"}/>}
                    {festival.imageUrl2 && <img className="LocationImage" src={festival.imageUrl2} alt={festival.name+" kép 2"}/>}
                    {festival.imageUrl3 && <img className="LocationImage" src={festival.imageUrl3} alt={festival.name+" kép 3"}/>}
                    {festival.imageUrl4 && <img className="LocationImage" src={festival.imageUrl4} alt={festival.name+" kép 4"}/>}

                    <Padding>

                        <div style={{clear: 'both'}}/>

                        { festival.tag?.tag && <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Kapcsolódó címke:</div>
                            <div className="DisplayRowValue">{festival.tag.tag || "----"}</div>
                        </div>}

                            <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Weboldal:</div>
                            <div className="DisplayRowValue">{festival.web || "----"}</div>
                        </div>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Facebook:</div>
                            <div className="DisplayRowValue">{festival.facebook || "----"}</div>
                        </div>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Telefonszám:</div>
                            <div className="DisplayRowValue">{festival.phone || "----"}</div>
                        </div>

                        <div className="DisplayRow ThemeBackground">
                            <div className="DisplayRowKey">Email:</div>
                            <div className="DisplayRowValue">{festival.email || "----"}</div>
                        </div>

                    </Padding>
                </div>}


                <Padding>

                    {this.props.editorMode && <div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_name">Megnevezés</label> <input
                            id="form_name" type="text" name="name"
                            value={festival.name} onChange={this.onPropertyChanged}/>
                        </div>
                        <div style={{clear: 'both'}}/>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (desktop):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderDesktop"
                                                    value={festival.imageHeaderDesktop} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (tablet):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderTablet"
                                                    value={festival.imageHeaderTablet} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_header">Fejléc kép (mobile):</label>
                            <MediaSelectorComponent id="form_header" name="imageHeaderMobile"
                                                    value={festival.imageHeaderMobile} onChange={this.onPropertyChanged}
                                                    app={this.props.app} user={this.props.user}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_title_on_offer">Cím megjelenítése ajánlásoknál:</label>

                            <select id="form_title_on_offer" name="titleOnOffer" value={festival.titleOnOffer} onChange={this.onPropertyChanged}>
                                <option value={false}>Nem</option>
                                <option value={true}>Igen</option>
                            </select>
                        </div>
                        <div style={{clear: 'both'}}/>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image1">Kép 1:</label> <input
                            id="form_image1" type="text" name="imageUrl1"
                            value={festival.imageUrl1} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image2">Kép 2:</label> <input
                            id="form_image2" type="text" name="imageUrl2"
                            value={festival.imageUrl2} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image3">Kép 3:</label> <input
                            id="form_image3" type="text" name="imageUrl3"
                            value={festival.imageUrl3} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_image4">Kép 4:</label> <input
                            id="form_image4" type="text" name="imageUrl4"
                            value={festival.imageUrl4} onChange={this.onPropertyChanged}/>
                        </div>
                        <div style={{clear: 'both'}}/>


                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_web">Weboldal:</label> <input
                            id="form_web" type="text" name="web"
                            value={festival.web} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_facebook">Facebook:</label> <input
                            id="form_facebook" type="text" name="facebook"
                            value={festival.facebook} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_phone">Telefonszám:</label> <input
                            id="form_phone" type="text" name="phone"
                            value={festival.phone} onChange={this.onPropertyChanged}/>
                        </div>
                        <div className="EditorRow ThemeBackground">
                            <label htmlFor="form_email">Email:</label> <input
                            id="form_email" type="text" name="email"
                            value={festival.email} onChange={this.onPropertyChanged}/>
                        </div>

                        <div>
                            <button onClick={this.generateArticle}>Cikk generálása</button>
                        </div>

                    </div>}

                    {!festival.parent && <h1>Fesztivál bemutatása</h1>}
                    {festival.parent && <h1>Színpad bemutatása</h1>}
                </Padding>
                <PageArticleEditor article={festival.article} onArticleChanged={this.updateArticle}
                                   app={this.props.app} user={this.props.user} editorMode={this.props.editorMode}
                                   hideArticleData={true}/>
                <Padding>
                    <PromoterTabFooter onSave={this.saveFestival} editorMode={this.props.editorMode}/>
                </Padding>
                {(festival.festivalId && !festival.parent) && <div>
                    <Padding><h1>Színpadok</h1>
                        <p>Ha a fesztiválod több helyszínen, vagy nevesített helyen, színpadon kerül megrendezésre,
                            akkor azokat itt hozgatod létre.</p>
                        <button className="barqButton ButtonFull"
                                onClick={(e) => this.onNewStage(this.state.festival.festivalId)}>Új színpad
                        </button>
                        <div className="StageContainer">
                            {stagesView}
                        </div>
                    </Padding>
                    <Padding>
                        <InfoMessage>
                            <span className="Title">Fesztivál posztolása</span> - Fesztivál posztolása, hogy az
                            megjelenjen az olvasóid hírfolyamában.
                            <button className="barqButton" onClick={this.postFestival}>Fesztivál posztolása</button>
                        </InfoMessage>
                    </Padding>
                </div>}
                {(festival.festivalId && festival.barqs) && <BarqList barqsResult={festival.barqs}
                                                                      title="Kapcsolódó események"
                                                                      info="Itt találod azokat az eseményeket, amelyeket te töltöttél fel."
                                                                      editorMode={false}/>}

            </div>
        } else {
            return "Loading...5"
        }
    }

}

export default FestivalEditor;

