import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import {Link} from "react-router-dom";
import './BarqsSection.css';
import BarqItem from "../../barq/BarqItem";
import {BarqItemCardAll, BarqItemCardEmpty} from "../../barq/BarqItemCard";
import {translateMessage, TranslateMessage} from "../../i18n/TranslateMessage";
import SectionHeader from "./SectionHeader";

class BarqsSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        const section = this.props.section;

        const barqs = section.barqs.barqs.map((barq, index) =>
            <BarqItem key={section.code + '-' + barq.barqId} barq={barq} index={index}
                      mode={this.props.mode || "card"} app={this.props.app}/>
        );


        const linkTo = '/search/' + section.code;

        return (
            <div className="PaddingDependsOnWidth">
                <div
                    className={"Section ModernSection ThemeBackground " + (this.props.display === "similar" || "SectionWidthContainer")}>
                    <SectionHeader title={section.title} button={translateMessage("barqboard_section_button_all", null, {count: section.barqs.all})} link={linkTo}/>
                    <div className="BarqboardBarqListContainer">
                        <div className="BarqboardBarqList">
                            {barqs}
                            {/*{section.barqs.all > 5 && <BarqItemCardAll to={linkTo}>Mind a {section.barqs.all - 5} esemény*/}
                            {/*    listázása...</BarqItemCardAll>}*/}
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}

export default BarqsSection;
