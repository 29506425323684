import React, {Component} from "react";
import {Helmet} from "react-helmet";
import ajax from "superagent";
import './ModernViewBarq.css';
import './ViewBarqBadge.css';
import './ViewOccurence.css';
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import Container from "../components/Components";
import ArticleParagraphTitle, {ArticleParagraphTitleView} from "../article/paragraph/ArticleParagraphTitle";
import ArticleParagraphText from "../article/paragraph/ArticleParagraphText";
import SearchPanel from "../search/SearchPanel";
import BarqsSection from "../barqboard/sections/BarqsSectionStatic";
import "../barq/BarqItem";
import queryString from "query-string";
import {Link} from "react-router-dom";
import BarqActions from "./BarqActions";
import {ToastContainer} from "react-toastify";
import {displayBarqStartTime, displayTime, translateMessage, translateMonth} from "../i18n/TranslateMessage";
import TimeToGoString, {ambiguousTime} from "./TimeToGoCalculator";
import BarqBadges from "./BarqBadges";
import ContentTabbed, {DisplayContent} from "../content/ContentTabbed";

var moment = require('moment-timezone');

class ModernViewBarq extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {response: null, exists: true, allTime: false}
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.downloadBarq()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            window.scrollTo(0, 0);
            this.downloadBarq();
        }
    }

    downloadBarq() {
        const values = queryString.parse(window.location.hash);
        const sid = values["sid"] || null;
        ajax
            .post(this.props.app.server_url + '/barqboard/barq')
            .query({
                "platform": "react",
                "lang": this.props.app.lang,
                "id": this.props.match.params.id, sid: sid
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                if (res.body != null)
                    this.setState({response: res.body});
                else
                    this.setState({exists: false});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    search(search) {
        window.location = this.props.app.app_url + "/search/" + search;
    }

    renderNarrow(barq) {
        return <div className="ModernViewBarq ModernViewBarqNarrow">
            <ContentMain response={this.state.response} app={this.props.app} user={this.props.user}/>
        </div>
    }

    renderWide(barq) {
        const searchPosition = this.state.response.searchPosition;
        return <div className="ModernViewBarq ModernViewBarqWide">

            <div className={"ContentFull"}>
                <ContentMain response={this.state.response} app={this.props.app} user={this.props.user}/>
                <div className={"ContentSidebar"}>
                    <SearchPanel mode={"sidebar"} form={{text: ""}} app={this.props.app} user={this.props.user}
                                 onSearchTextChanged={this.search} recommendations={true}/>

                    {/*{(searchPosition) && <div className={"SearchPosition ThemeBackground"}>*/}
                    {/*    <ArticleParagraphTitleView*/}
                    {/*        paragraph={{title: searchPosition.title + " " + (searchPosition.index + 1) + "/" + (searchPosition.all)}}*/}
                    {/*        noPadding={true}/>*/}
                    {/*    <div>Egy keresés eredményei között böngészel. Az előző/következő gombokkal bejárhatod az*/}
                    {/*        eseményeket.*/}
                    {/*    </div>*/}
                    {/*    <div><Link to={'/search/' + searchPosition.search} className={"ColorLink"}>Keresés*/}
                    {/*        újra: {searchPosition.title}</Link></div>*/}
                    {/*    <Link to={'/barq/' + searchPosition.prev + "#sid=" + searchPosition.sid}*/}
                    {/*          className={"PrevButton grayButton " + (searchPosition.prev || " ButtonDisabled")}>Előző</Link>*/}
                    {/*    <Link to={'/barq/' + searchPosition.next + "#sid=" + searchPosition.sid}*/}
                    {/*          className={"NextButton grayButton " + (searchPosition.next || " ButtonDisabled")}>Következő</Link>*/}
                    {/*</div>}*/}
                </div>
            </div>
        </div>
    }

    render() {
        const barq = this.state.response?.barq;
        const content = this.state.response?.content;

        return <div>
            <Helmet>
                <title>barq.me | {barq?.title || "Esemény betöltése..."}</title>
                {(barq?.title) && <meta name="description" lang="hu"
                                        content={'barq.me programajánló. Esemény ' + barq.title + ", " + barq.details + ', helyszín: ' + barq.location.name}/>
                }
            </Helmet>
            <BarqMeCookies/>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                {(barq?.barqId > 0) && this.renderNarrow(barq)}
                {(barq?.barqId > 0) && this.renderWide(barq)}
                {(barq?.barqId <= 0) && <ContentTabbed content={content} app={this.props.app} user={this.props.user}/> }
                <ToastContainer/>
            </Container>
        </div>
    }

}

class ContentMain extends Component {
    render() {
        const barq = this.props.response.barq;
        const location = this.props.response.location;
        const tags = this.props.response.tags;
        const spices = this.props.response.barq.spices;
        const similars = this.props.response.sections;

        return <div className={"ContentMain PaddingDependsOnWidth"}>
            <div className="SeparateBlock"><ArticleParagraphTitle paragraph={{title: barq.title}} noPadding={true}/>
                <div className={"EventDetails"}>
                    <ViewLocationData title={translateMessage("barq_location_title")} value={location.name}/>
                    {(location.address) &&
                        <ViewLocationData title={translateMessage("location_address")} value={location.address}/>}
                    <ViewLocationData title={translateMessage("barq_starttime_title")}
                                      value={displayBarqStartTime(barq)}/>
                </div>
            </div>

            <div className={"WideImageContainer SeparateBlock"}>
                <div className={"WideImageLeft"}
                     style={{backgroundImage: 'url(/barqme-no-image.png)', backgroundSize: "100%"}}>
                    <div className="Overflow FullImage" style={{backgroundImage: 'url(' + barq.imageUrl + ")"}}/>


                    {barq.fullBadge != null && <div className="Overflow FullBadge"
                                                    style={{backgroundImage: 'url(' + barq.fullBadge.imageUrl + ")"}}/>}

                    <div className="Overflow">
                        <BarqBadges badges={barq.badges} extraCss="BarqBadgesLargeSize"/>
                    </div>
                </div>
                <div className={"WideImageRight"}>
                    <div className={"CalendarData HighlightBackground TextOnHighlightBackground"}>
                        <div className={"Calendar"}>
                            <div className={"CalendarDay"}>
                                {moment(barq.startDateStamp).tz("Europe/Budapest").format("D")}
                            </div>
                            <div className={"CalendarMonth"}>
                                {translateMonth(barq.startDateStamp)}
                            </div>
                            <div className={"CalendarTime"}>
                                {ambiguousTime(barq) != null && moment(barq.startDateStamp).tz("Europe/Budapest").format("HH:mm")}
                            </div>
                            <div className={"CalendarRemain"}><TimeToGoString barq={barq}/></div>
                        </div>
                    </div>
                    <div className="BarqActionsContainer">
                        <BarqActions barq={barq} app={this.props.app} user={this.props.user}/>
                    </div>
                </div>
            </div>
            <div className="ModernViewBarqActions">
                <BarqActions barq={barq} app={this.props.app} user={this.props.user}/>
            </div>

            {barq.badges?.length > 0 && <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_badges_title")}}/>
                <div className={"ViewBadgeList"}>
                    {barq.fullBadge && <ViewBarqBadge badge={barq.fullBadge}/>}
                    {barq.badges.map((badge) => <ViewBarqBadge badge={badge}/>)}
                </div>
            </div>}

            {barq.occurrences?.length > 0 && <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_occurences_title")}}/>
                <div className={"ViewOccurenceList"}>
                    {barq.occurrences.map((occurrence) => <ViewOccurence barq={barq} occurrence={occurrence}/>)}
                </div>
            </div>}


            <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_spices_title")}}/>
                <div className={"ViewSpiceList"}>
                    {spices.map((spice) => <ViewSpice spice={spice}/>)}
                </div>

            </div>

            <ArticleParagraphText paragraph={{text: barq.details}}/>

            <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_tags_title")}}/>
                <div className={"ViewTagList"}>
                    {tags.map((tag) => <ViewTag tag={tag}/>)}
                </div>
            </div>

            {barq.sourceUrl && <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_source_title")}}/>
                <a href={barq.sourceUrl}>{barq.sourceUrl}</a>
            </div>}

            {barq.sourceUrl && <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: translateMessage("barq_ticket_title")}}/>
                <a href={barq.sourceUrl}>{barq.sourceUrl}</a>
            </div>}


            <div className={"SeparateBlock"}>
                <ArticleParagraphTitleView paragraph={{title: location.name}}/>
                <div className={"LocationImages"}>
                    {location.imageUrl1 &&
                        <img className="BarqLocationImage" src={location.imageUrl1}
                             alt={location.name}/>}
                    {location.imageUrl2 &&
                        <img className="BarqLocationImage" src={location.imageUrl2}
                             alt={location.name}/>}
                    {location.imageUrl3 &&
                        <img className="BarqLocationImage" src={location.imageUrl3}
                             alt={location.name}/>}
                    {location.imageUrl4 &&
                        <img className="BarqLocationImage" src={location.imageUrl4}
                             alt={location.name}/>}
                    {location.imageUrl5 &&
                        <img className="BarqLocationImage" src={location.imageUrl5}
                             alt={location.name}/>}
                    {location.imageUrl6 &&
                        <img className="BarqLocationImage" src={location.imageUrl6}
                             alt={location.name}/>}
                </div>
            </div>
            <div className={"SeparateBlock"}>
                <div className={"LocationDetails"}>
                    {(location.address) && <ViewLocationData title={"Cím"} value={location.address}/>}
                    {(location.facebook) &&
                        <ViewLocationData title={"Facebook"} value={location.facebook} link={location.facebook}/>}
                    {(location.web) &&
                        <ViewLocationData title={"Weboldal"} value={location.web} link={location.web}/>}
                    {(location.phone) && <ViewLocationData title={"Telefonszám"} value={location.phone}
                                                           link={"phone://" + location.phone}/>}
                </div>
            </div>

            <div className={"SeparateBlock"}>
                <div className={"BarqsSectionContainer"}>
                    {similars.map((section) => <BarqsSection key={section.code} section={section}
                                                             app={this.props.app} display={"similar"}/>)}
                </div>
            </div>
        </div>
    }
}

class ViewSpice extends Component {
    render() {
        const spice = this.props.spice;

        return <div className={"ViewSpice"}>
            <div className={"SpiceTitle ThemeSolidColor"}>
                {spice.title}
            </div>
            <div className={"SpiceValue HighlightBackground TextOnHighlightBackground"}>
                <Link to={'/search/SEARCH:' + spice.value.tag}>{spice.value.display}</Link>
            </div>

        </div>

    }
}

class ViewOccurence extends Component {

    render() {
        const barq = this.props.barq;
        const occurrence = this.props.occurrence;
        return <Link to={"/barq/" + occurrence.barqId+"/"+barq.titleUrl}>
            <div className="ViewOccurence">
                <div className="ViewOccurenceTime">
                    <b>{displayTime(occurrence.startDateStamp)}</b>
                </div>
                <div className="ViewOccurenceLocation">
                    {occurrence.location.name}
                </div>
            </div>
        </Link>
    }
}

class ViewBarqBadge extends Component {

    render() {
        const badge = this.props.badge;
        return <div className="ViewBarqBadge">
            <div className="ViewBarqBadgeImage">
                <img src={badge.imageUrl} alt={badge.text} title={badge.text}/>
            </div>
            <div className="ViewBarqBadgeText">
                {badge.text}
            </div>
        </div>
    }
}

class ViewTag extends Component {
    render() {
        const tag = this.props.tag;
        return <div className={"ViewTag ThemeSolidBackground ThemeSolidColor"}>
            <Link to={'/search/SEARCH:' + tag.tag}>{tag.display}</Link>
        </div>

    }
}

class ViewLocationData extends Component {
    render() {
        const title = this.props.title;
        const value = this.props.value;
        const link = this.props.link;


        if (link) {
            return <div className={"ViewLocationData"}>
                <div className={"LocationDataTitle ThemeSolidColor"}>
                    {title}
                </div>
                <div className={"LocationDataValue"}>
                    <a href={link}>{value}</a>
                </div>
            </div>
        } else {
            return <div className={"ViewLocationData"}>
                <div className={"LocationDataTitle ThemeSolidColor"}>
                    {title}
                </div>
                <div className={"LocationDataValue"}>
                    {value}
                </div>
            </div>
        }
    }
}

export default ModernViewBarq;