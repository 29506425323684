import React, {Component} from "react";
import ArticleParagraphMediaImageGroup from "../../article/paragraph/ArticleParagraphMediaImageGroup";
import ArticleParagraphMediaImage from "../../article/paragraph/ArticleParagraphMediaImages";

class ImageSectionGroup extends Component {

    render() {
        const section = this.props.section;
        const paragraph = {
            code: section.code,
            title: section.title,
            place: "",
            images: section.images
        }

        if (section.decoration === "responsive") {
            return <div>
                <div className="ShowOnly1024Below">
                    <ArticleParagraphMediaImageGroup paragraph={paragraph}/>
                </div>
                <div className="ShowOnly1024Above">
                    <ArticleParagraphMediaImage paragraph={paragraph}/>
                </div>
            </div>
        } else if (section.decoration === "plain") {
            return <ArticleParagraphMediaImage paragraph={paragraph}/>;
        } else {
            return <ArticleParagraphMediaImageGroup paragraph={paragraph}/>
        }
    }
}

export default ImageSectionGroup;
