import React, {Component} from 'react';
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from 'react-cookie';

const PubSub = require('pubsub-js');
const cookies_path = '/';
const cookies_age = 60 * 24 * 60 * 60; //60 days
const cookies_settings = {path: cookies_path, maxAge: cookies_age};

class BarqMeCookies extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props, context) {
        super(props, context);
        this.toggleMenuState = this.toggleMenuState.bind(this);
        this.hideMenuState = this.hideMenuState.bind(this);
        this.disableScroll = this.disableScroll.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
        this.toggleLang = this.toggleLang.bind(this);
        const {cookies} = this.props;

        const themeName = cookies.get("theme_name", cookies_settings);
        window.themeName = themeName;

        const lang = cookies.get("app_lang", cookies_settings);
        window.barqLang = lang;

        this.state = {themeName: themeName, lang: lang};
        this.setupThemeOnPage();

        const me = this;
        window.matchMedia('(prefers-color-scheme: dark)').addListener(function (e) {
            if (me.state.themeName === 'theme_system') {
                me.setupThemeOnPage();
            }
        });
    }

    componentDidMount() {
        PubSub.subscribe('EVENT_TOGGLE_MENU', this.toggleMenuState);
        PubSub.subscribe('EVENT_TOGGLE_THEME', this.toggleTheme);
        PubSub.subscribe('EVENT_TOGGLE_LANG', this.toggleLang);
        PubSub.subscribe('EVENT_HIDE_MENU', this.hideMenuState);
        PubSub.subscribe('EVENT_SCROLL_DISABLE', this.disableScroll);
        PubSub.subscribe('EVENT_SCROLL_ENABLE', this.enableScroll);

        PubSub.immediateExceptions = true;
    }

    componentWillUnmount() {
        PubSub.unsubscribe('EVENT_TOGGLE_MENU');
        PubSub.unsubscribe('EVENT_HIDE_MENU');
    }

    toggleMenuState(msg, data) {
        if (data == null) {
            const showSidebar = !this.state.showSidebar;
            this.setState({showSidebar: showSidebar}, () => {
                PubSub.publish("EVENT_TOGGLE_MENU", {showSidebar: showSidebar});
            });
        }
    }

    hideMenuState(msg, data) {
        const showSidebar = false;
        this.setState({showSidebar: showSidebar}, () => {
            PubSub.publish("EVENT_TOGGLE_MENU", {showSidebar: showSidebar});
        });
    }

    toggleTheme(msg, data) {
        if (data != null) {
            this.saveThemeState(data.theme);
        }
    }

    setupThemeOnPage() {
        const element = document.getElementById("BarqMeAppBody");

        const systemDarkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;


        if (this.state.themeName === 'theme_dark' || (this.state.themeName === 'theme_system' && systemDarkModeOn)) {
            element.classList.remove('ThemeLight');
            element.classList.add('ThemeDark');
        } else {
            element.classList.remove('ThemeDark');
            element.classList.add('ThemeLight');
        }
    }

    saveThemeState(themeName) {
        this.setState({themeName: themeName}, () => {
            const {cookies} = this.props;
            window.themeName = themeName;
            cookies.set('theme_name', themeName, cookies_settings);
            this.setupThemeOnPage();
        });
    }


    toggleLang(msg, data) {
        if (data != null) {
            this.saveLang(data.lang);
        }
    }

    saveLang(lang) {
        this.setState({lang: lang}, () => {
            const {cookies} = this.props;
            window.barqLang = lang;
            cookies.set('app_lang', lang, cookies_settings);
            window.location = "/";
        });
    }


    disableScroll() {
        const element = document.getElementById("BarqMeAppBody");
        element.classList.add('NoScroll');
    }

    enableScroll() {
        const element = document.getElementById("BarqMeAppBody");
        element.classList.remove('NoScroll');

    }

    render() {
        return null
    }
}

export default withCookies(BarqMeCookies);