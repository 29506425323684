import React, {Component} from "react";
import ajax from "superagent";

class BarqImagesUpload extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            processor: "default",
            processors: [
                {value: "default", name: "Alapértelmezett"},
                {value: "dummy", name: "Változtatás nélkül"},
                {value: "keep", name: "Képarányok megtartása"},
                {value: "blur", name: "Képarányok megtartása, blur"},
                {value: "colorful", name: "Képarányok megtartása, színes"},
                {value: "mupa", name: "Müpa"},
            ]
        };
        this.processorChanged = this.processorChanged.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    componentDidMount() {

    }


    handleFileUpload(event) {
        var formData = new FormData();
        var files = document.getElementById("uploadFile").files;
        for (var i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
        }

        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/upload_images')
            .query({"platform": "react", processor: this.state.processor})
            .send(formData)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                console.log(res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }

    processorChanged(event) {
        const target = event.target;
        const value = target.value;

        this.setState({processor: value});
    }

    render() {
        return <div className="BarqBatchUpload">
            <div className="UploadFile ThemeBackground">
                Kattintson a gombra egy képek feltöltéséhez!<br/>
                <div>
                    <select name="processor" value={this.state.processor} onChange={this.processorChanged}>
                        {this.state.processors.map((p) => {
                            return <option key={p.value} value={p.value}>{p.name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <input id="uploadFile" type="file" multiple={true} onChange={this.handleFileUpload}/>
                </div>


            </div>
        </div>
    }
}

export default BarqImagesUpload;