import React, {Component} from "react";
import {Padding} from "../../components/Components";
import ArticleParagraphTitle from "./ArticleParagraphTitle";
import ArticleParagraphText from "./ArticleParagraphText";
import ArticleParagraphButton from "./ArticleParagraphButton";
import ArticleParagraphSearch from "./ArticleParagraphSearch";
import ArticleParagraphMediaImage from "./ArticleParagraphMediaImage";
import ArticleParagraphActions from "./ArticleParagraphActions";
import ArticleParagraphPairs from "./ArticleParagraphPairs";
import ArticleParagraphTabs from "./ArticleParagraphTabs";
import ArticleParagraphMediaImages from "./ArticleParagraphMediaImages";

class ViewArticleParagraph extends Component {

    render() {
        const article = this.props.article;
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;

        if (paragraph.type === "title")
            return <ArticleParagraphTitle key={article.code + '-' + paragraph.code} app={this.props.app}
                                          paragraph={paragraph}
                                          noPadding={this.props.noPadding} settings={this.props.settings}
                                          editorMode={editorMode} addParagraph={this.props.addParagraph}
                                          moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "text" || paragraph.type === "info" || paragraph.type === "code")
            return <ArticleParagraphText key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                         noPadding={this.props.noPadding} settings={this.props.settings}
                                         editorMode={editorMode} addParagraph={this.props.addParagraph}
                                         moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "media_image")
            return <ArticleParagraphMediaImage key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                               noPadding={this.props.noPadding} app={this.props.app} settings={this.props.settings}
                                               editorMode={editorMode} addParagraph={this.props.addParagraph}
                                               moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "media_images")
            return <ArticleParagraphMediaImages key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                               noPadding={this.props.noPadding} app={this.props.app} settings={this.props.settings}
                                               editorMode={editorMode} addParagraph={this.props.addParagraph}
                                               moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "button")
            return <ArticleParagraphButton key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                           noPadding={this.props.noPadding}
                                           editorMode={editorMode} addParagraph={this.props.addParagraph}
                                           moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "actions")
            return <ArticleParagraphActions key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                            noPadding={this.props.noPadding} settings={this.props.settings}
                                            editorMode={editorMode} addParagraph={this.props.addParagraph}
                                            moveParagraph={this.props.moveParagraph}
                                            app={this.props.app}/>;
        else if (paragraph.type === "pairs")
            return <ArticleParagraphPairs key={article.code + '-' + paragraph.code} paragraph={paragraph}
                                          noPadding={this.props.noPadding} settings={this.props.settings}
                                          editorMode={editorMode} addParagraph={this.props.addParagraph}
                                          moveParagraph={this.props.moveParagraph}
                                          app={this.props.app}/>;
        else if (paragraph.type === "search")
            return <ArticleParagraphSearch key={article.code + '-' + paragraph.code + '-' + paragraph.title}
                                           noPadding={this.props.noPadding} settings={this.props.settings}
                                           collectionClass={this.props.collectionClass} viewMode={this.props.viewMode}
                                           app={this.props.app} paragraph={paragraph} editorMode={editorMode}
                                           addParagraph={this.props.addParagraph}
                                           moveParagraph={this.props.moveParagraph}/>;
        else if (paragraph.type === "tab_switcher")
            return <ArticleParagraphTabs key={article.code + '-' + paragraph.code + '-' + paragraph.title}
                                         noPadding={this.props.noPadding} settings={this.props.settings}
                                         collectionClass={this.props.collectionClass} viewMode={this.props.viewMode}
                                         app={this.props.app} paragraph={paragraph} editorMode={editorMode}
                                         switchTab={this.props.switchTab} tabSelected={this.props.tabSelected}

            />;
        else return null
    }

}

class ParagraphBlock extends Component {

    render() {
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <div className="EditorBlock ThemeBackground">
                {this.props.children}
                <div className="EditButtons"/>
            </div>
        } else {
            return <Padding>{this.props.children}</Padding>
        }
    }
}

class ParagraphButtons extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {expand: ""};
        this.showEditor = this.props.onShowEditor.bind(this);
        this.remove = this.props.onRemove.bind(this);
        this.addParagraph = this.props.onAddParagraph.bind(this);
        this.moveParagraph = this.props.onMoveParagraph.bind(this);
        this.expand = this.expand.bind(this);
    }

    expand(exp) {
        if (this.state.expand !== exp) {
            this.setState({expand: exp})
        } else {
            this.setState({expand: ""})
        }
    }

    render() {
        return <div className="ParagraphButtons">
            <button className="HighlightBackground MainButton" onClick={this.showEditor}>M</button>
            <button className="HighlightBackground MainButton ParagraphButton ParagraphButtonTrash"
                    onClick={this.remove}>&nbsp;</button>
            <button className="HighlightBackground MainButton ParagraphButton ParagraphButtonAdd"
                    onClick={(e) => this.expand("add")}>&nbsp;</button>

            {this.state.expand === "add" && <span className="ChildButtonContainer">Beszúrás:
                <button className="ThemeButton ChildButton" onClick={(e) => this.addParagraph("before")}>Fölé</button>
                <button className="ThemeButton ChildButton"
                        onClick={(e) => this.addParagraph("after")}>Alá</button></span>}

            <button className="HighlightBackground MainButton ParagraphButton ParagraphButtonMove"
                    onClick={(e) => this.expand("move")}>&nbsp;</button>

            {this.state.expand === "move" && <span className="ChildButtonContainer">Mozgatás:
                <button className="ThemeButton ChildButton"
                        onClick={(e) => this.moveParagraph("before")}>Felfele</button>
                <button className="ThemeButton ChildButton" onClick={(e) => this.moveParagraph("after")}>Lefele</button>
                <button className="ThemeButton ChildButton"
                        onClick={(e) => this.moveParagraph("first")}>Felülre</button>
                <button className="ThemeButton ChildButton" onClick={(e) => this.moveParagraph("last")}>Alulra</button></span>}
        </div>
    }

}


class NewParagraphButtons extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {expand: ""};
        this.remove = this.props.onRemove.bind(this);
        this.addParagraph = this.props.onAddParagraph.bind(this);
    }

    render() {
        return <span>
            <button className="ParagraphButton ParagraphButtonTrash" onClick={this.remove}>&nbsp;</button>
                <button className="ParagraphButton ParagraphButtonAdd"
                        onClick={(e) => this.addParagraph("after")}>&nbsp;</button>
        </span>
    }

}


export default ParagraphBlock;
export {ViewArticleParagraph, ParagraphButtons, NewParagraphButtons};