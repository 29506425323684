import React, {Component} from "react";
import "./BarqItemCalendar.css";
import {translateMessage, translateMonth} from "../i18n/TranslateMessage";
import {ambiguousTime} from "./TimeToGoCalculator";

var moment = require('moment-timezone');


class BarqItemCalendarOrMore extends Component {

    render() {
        const barq = this.props.barq
        if (barq.multipleEventCounter <= 1) {
            return <BarqItemCalendar barq={barq} className={this.props.className}/>
        } else {
            return <BarqItemCalendarMore barq={barq} className={this.props.className}/>
        }
    }
}

class BarqItemCalendar extends Component {

    render() {
        const barq = this.props.barq;
        const dateTime = barq.startDateStamp;

        return <div className={"ThemeBackground "+ (this.props.className || "BarqItemCalendar")}>
            <div className="month ThemeColor">
                {translateMonth(dateTime)}
            </div>
            <div className="day">
                {moment(barq.startDateStamp).tz("Europe/Budapest").format("D")}
            </div>
            <div className="time ThemeColor">
                {ambiguousTime(barq)==null && moment(barq.startDateStamp).tz("Europe/Budapest").format("HH:mm")}
            </div>

        </div>
    }

}

class BarqItemCalendarMore extends Component {

    render() {
        const barq = this.props.barq
        if (barq.multipleEventCounter > 1) {
            return <div className={"ThemeBackground "+ (this.props.className || "BarqItemCalendar")}>
                <div className="month ThemeColor">
                    MÉG
                </div>
                <div className="counter">
                    {barq.multipleEventCounter - 1}
                </div>
                <div className="time ThemeColor">
                    időpont
                </div>

            </div>
        } else {
            return null;
        }
    }
}

export default BarqItemCalendarOrMore;
export {BarqItemCalendar, BarqItemCalendarMore};