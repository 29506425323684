import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import './PageEditor.css';
import {Padding} from "../components/Components";
import PromoterData from "./promoter/PromoterData";
import LocationData from "./location/LocationData";
import LocationEvents from "./location/LocationEvents";
import MyEvents from "./barq/MyBarqs";
import PromoterOffers from "./offers/PromoterOffers";
import PromoterArticles from "./my-articles/PromoterArticles";
import queryString from 'query-string'
import MyPosts from "./post/MyPosts";
import Festivals from "./festival/Festivals";
import Sidebar, {SidebarBlock} from "../sidebar/Sidebar";
import MediaEditor from "./media/MediaEditor";
import BarqMeTopbar from "../topbar/Topbar";
import ajax from "superagent";
import LocationDataAdmin from "./location/LocationDataAdmin";
import BarqAdmin from "./barq/BarqAdmin";
import Loading from "../components/Loading";
import AnomaliesAdmin from "./anomalies/Anomalies";

class EditorComponent extends Component {


    constructor(props, context) {
        super(props, context);


        const values = queryString.parse(window.location.hash);
        const tab = values["tab"] || "data";

        this.state = {data: null, promoter: this.props.promoter, tab: tab, editorMode: true};
        this.changeTab = this.changeTab.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.promoterChanged = this.promoterChanged.bind(this);
    }


    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadData() {
        ajax
            .post(this.props.app.server_url + '/editor/data/get')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({data: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    changeTab(tab) {

        this.setState({tab: "loading"}, () => {
            const hash = '#tab=' + tab;
            if (window.history.pushState) {
                window.history.pushState(null, null, hash);
            } else {
                window.location.hash = '#' + hash;
            }
            this.setState({tab: tab}, () => {
            });
        });

    }

    changeMode() {
        this.setState({editorMode: !this.state.editorMode}, () => {
        });

    }

    promoterChanged(promoter) {
        this.setState({promoter: promoter})
    }


    render() {


        if (this.state.data != null) {
            const data = this.state.data;
            const promoter = this.state.promoter;

            let buttons = [];

            if (data.promoter)
                buttons.push({tab: "data", title: data.promoter.name});
            if (data.location) {
                buttons.push({tab: "location", title: data.location.name});
                buttons.push({tab: "location-events", title: "Események nálam"});
            }
            if (data.promoter || data.location)
                buttons.push({tab: "my-events", title: "Eseményeim"});
            if (data.enableOffers)
                buttons.push({tab: "offers", title: "Ajánlók"});
            if (data.enableFestivals)
                buttons.push({tab: "festivals", title: "Fesztiválok"});
            if (data.enableArticles)
                buttons.push({tab: "articles", title: "Cikkek"});
            if (data.enablePosts)
                buttons.push({tab: "posts", title: "Posztok"});
            if (data.promoter || data.location || data.enableArticles)
                buttons.push({tab: "media", title: "Média"});
            if (data.enableLocationAdmin)
                buttons.push({tab: "location_admin", title: "Helyszínek"});
            if (data.enableBarqAdmin)
                buttons.push({tab: "barq_admin", title: "Események"});
            if (data.enableBarqAdmin)
                buttons.push({tab: "anomalies", title: "Anomáliák"});

            const promoterButtons = buttons.map((button) => {
                return <li key={'main_tab_' + button.tab}><a
                    onClick={(e) => this.changeTab(button.tab)}>{button.title}</a>
                </li>
            })

            const sidebarButtons = buttons.map((button) => {
                let className = "ThemeSolidBackground";
                if (this.state.tab === button.tab)
                    className = "HighlightBackground";
                else
                    className = "ThemeSolidBackground ThemeColor";
                return <li key={'main_tab_' + button.tab}><a className={' ' + className}
                                                             onClick={(e) => this.changeTab(button.tab)}>{button.title}</a>
                </li>

            })


            return <div>
                <Helmet>
                    <title>barq.me | {promoter.name}</title>
                    <meta name="description" lang="hu" content="Promóter oldal"/>
                </Helmet>
                <Sidebar>
                    <SidebarBlock title="Promóter">
                        <ul>{sidebarButtons}</ul>
                    </SidebarBlock>
                </Sidebar>
                <BarqMeTopbar editor={true} app={this.props.app} user={this.props.user}>
                    <ul>{promoterButtons}</ul>
                </BarqMeTopbar>
                <div className="ThemeBackground">
                    <Padding>
                        <div className="PromoterHeader">
                            <div className="PromoterName PromoterTitle">
                                {/*{promoter.name}*/}
                            </div>
                            <div className="PromoterLabel">
                                <div className="PromoterLabelCaptionContainer">
                                    <div className="PromoterLabelCaption">Szerkesztés</div>
                                    <input type="checkbox" className="apple-switch" checked={this.state.editorMode}
                                           onChange={this.changeMode}/>
                                </div>
                            </div>
                        </div>

                        <div style={{clear: 'both'}}></div>

                        {/*<div className="PromoterMenu ThemeSolidBackground">*/}
                        {/*    {promoterButtons}*/}

                        {/*</div>*/}
                    </Padding>
                </div>

                {this.state.tab === "data" &&
                <PromoterData promoter={this.state.promoter} editorMode={this.state.editorMode}
                              onPromoterChange={this.promoterChanged} app={this.props.app}
                              user={this.props.user}/>}
                {this.state.tab === "location" &&
                <LocationData promoter={this.state.promoter} editorMode={this.state.editorMode}
                              onPromoterChange={this.promoterChanged} app={this.props.app}
                              user={this.props.user}/>}
                {this.state.tab === "location-events" &&
                <LocationEvents promoter={this.state.promoter} editorMode={this.state.editorMode}
                                onPromoterChange={this.promoterChanged} app={this.props.app}
                                user={this.props.user}/>}
                {this.state.tab === "my-events" &&
                <MyEvents promoter={this.state.promoter} editorMode={this.state.editorMode}
                          onPromoterChange={this.promoterChanged} app={this.props.app}
                          user={this.props.user}/>}
                {this.state.tab === "offers" &&
                <PromoterOffers promoter={this.state.promoter} editorMode={this.state.editorMode}
                                onPromoterChange={this.promoterChanged} app={this.props.app}
                                user={this.props.user}/>}
                {this.state.tab === "articles" &&
                <PromoterArticles promoter={this.state.promoter} editorMode={this.state.editorMode}
                                  onPromoterChange={this.promoterChanged} app={this.props.app}
                                  user={this.props.user}/>}
                {this.state.tab === "posts" &&
                <MyPosts editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}
                {this.state.tab === "festivals" &&
                <Festivals editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}
                {this.state.tab === "media" &&
                <MediaEditor editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}
                {this.state.tab === "location_admin" &&
                <LocationDataAdmin editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}
                {this.state.tab === "barq_admin" &&
                <BarqAdmin editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}
                {this.state.tab === "anomalies" &&
                <AnomaliesAdmin editorMode={this.state.editorMode} app={this.props.app} user={this.props.user}/>}

            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

class PromoterTabTitle extends Component {


    render() {
        const title = this.props.title;
        return <div>
            <Padding>
                <div className="TabHeader">
                    <div className="TabTitle">
                        {title}
                    </div>
                </div>
            </Padding>
        </div>
    }

}

class PromoterTabFooter extends Component {

    constructor(props, context) {
        super(props, context);
        this.onSave = this.props.onSave.bind(this);
    }

    render() {
        if (this.props.editorMode === true)
            return <div className="TabFooter">
                {this.props.editorMode === true && <div className="TabSavePanel">
                    <button className="barqButton ButtonFull" onClick={this.onSave}>Mentés</button>
                </div>}
            </div>
        else
            return "";
    }
}


export default EditorComponent;
export {PromoterTabTitle, PromoterTabFooter};