import React, {Component} from "react";
import ajax from "superagent";
import {BarqCategory} from "./BarqTags";

class SelectCategories extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {categories: [], selection: []};
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.props.onCancel.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
    }

    componentDidMount() {
        this.downloadCategories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadCategories() {
        ajax
            .post(this.props.app.server_url + '/tag/loadCategories')
            .query({"platform": "react",
                "lang": this.props.app.lang,
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({categories: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    onSave() {
        this.props.onSave(this.state.selection);
    }


    onCategoryChanged(category, event) {
        const selected = this.state.selection;
        const index = selected.findIndex((s) => s.tagId === category.tagId);
        if (index >= 0) {
            //Remove
            let newSelected = [...selected];
            newSelected.splice(index, 1)
            this.setState({selection: newSelected})
        } else {
            //Add
            let newSelected = [...selected];
            newSelected.push(category)
            this.setState({selection: newSelected})
        }
    }


    render() {

        const categoriesView = this.state.categories.map((cat) => {
            return <div key={"category" + cat.tagId} style={{marginLeft: -40}}>
                <BarqCategory category={cat} key={"category--" + cat.tagId} level={0}
                              selectedCategories={this.state.selection}
                              onCategoryChanged={this.onCategoryChanged}/>
            </div>
        });

        return <div className="InlineDialog ThemeBackground">
            <h2>Besorolás</h2>
            <p>
                Válaszd ki azokat a kategóriákat, amelyek a barqodra jellemzőek. <b>Legalább egy kategóriát ki kell
                választanod!</b>
            </p>
            <div>{categoriesView}</div>

            <button className="grayButton" onClick={this.onCancel}>Mégsem</button>
            <button className="grayButton" onClick={this.onSave}>OK</button>
        </div>
    }
}

export default SelectCategories;
