import React, {Component} from "react";
import ajax from "superagent";
import "./ModernDialog.css"

class ModernDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {spices: null};
    }


    render() {

        let overlayClass = "FullOverlay";
        let positionClass = "ModernDialogFirstLevel";
        if (this.props.level === 2) {
            overlayClass = "FullOverlaySecond";
            positionClass = "ModernDialogSecondLevel";
        }
        return <div className="FullOverlayParent"><div className={overlayClass}>
            <div className={positionClass + " ModernDialog " + (this.props.extraClass || "ModernDialogSizeNarrow")}>
                <div className="DialogTitle ThemeBackground ThemeBorder">
                    <button className="CloseButton" onClick={this.props.onClose}/> {this.props.title}
                    {(this.props.buttonText) && <button className="ActionButton" onClick={this.props.buttonAction}>{this.props.buttonText}</button>}
                </div>
                <div className="DialogContent">
                    <div>{this.props.children}</div>
                </div>
            </div>
        </div></div>
    }
}

export default ModernDialog;