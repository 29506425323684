import React, {Component} from "react";
import ajax from "superagent";
import './MediaEditor.css'
import EditorPopupDialog from "../../article/paragraph/EditorPopupDialog";

class MediaEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {media: null};
        this.onSelect = this.onSelect.bind(this);
        // this.downloadMedia = this.downloadMedia.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.copyMediaLink = this.copyMediaLink.bind(this);
        this.executeDelete = this.executeDelete.bind(this);
    }

    componentDidMount() {
        this.downloadMedia();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadMedia() {
        ajax
            .post(this.props.app.server_url + '/editor/media/list')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({media: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    handleFileUpload(event) {
        var formData = new FormData();
        var files = document.getElementById("uploadFile").files;
        formData.append("file", files[0]);

        ajax
            .post(this.props.app.server_url + '/editor/media/upload')
            .query({"platform": "react"})
            .send(formData)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({media: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }

    onSelect(media) {
        if (this.props.onSelect) {
            this.props.onSelect(media);
        }
    }


    executeDelete(media) {
        ajax
            .post(this.props.app.server_url + '/editor/media/delete')
            .query({"platform": "react", storageName: media.storageName})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.downloadMedia()
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }


    deleteMedia(media) {
        var result = window.confirm("Biztosan törölni szeretné?\r\n" + media.imageUrl);
        if (result) {
            this.executeDelete(media)
        }
    }

    copyMediaLink(media) {
        var textField = document.createElement('textarea');
        textField.innerText = media.mediaNameFull;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert("A link a vágólapra másolva!\r\n" + media.mediaNameFull);

    }

    render() {
        let mediaView;
        if (this.state.media != null) {
            mediaView = this.state.media.map((med) => {
                return <MediaPreview key={med.imageUrl} media={med} onSelect={this.onSelect} onDelete={this.deleteMedia}
                                     onCopyLink={this.copyMediaLink} showSelect={this.props.onSelect != null}/>
            });
        } else {
            mediaView = <div>Még nincsenek feltöltött média fájljaid!</div>
        }
        return <div className="MediaEditor">
            <div className="UploadFile ThemeBackground">
                Kattintson a gombra a képek felöltéséhez!<br/>
                <input id="uploadFile" type="file" onChange={this.handleFileUpload}/>
            </div>
            <div>
                {mediaView}
            </div>
        </div>
    }
}

class MediaPreview extends Component {

    constructor(props, context) {
        super(props, context);
        this.onSelect = this.props.onSelect.bind(this);
        this.onDelete = this.props.onDelete.bind(this);
        this.onCopyLink = this.props.onCopyLink.bind(this);
    }

    render() {
        const media = this.props.media;
        return <div className="MediaPreview ThemeBackground">
            <div className="MediaImage">
                <div className="Position">
                    <img src={media.imageUrlFull} alt=""/>
                </div>
            </div>
            <div className="MediaSizeData ThemeSolidBackground">{media.width}x{media.height}</div>
            {/*<button className="Open ThemeSolidBackground" onClick={(e) => this.onSelect(media)}>...</button>*/}
            <button className="Delete ThemeSolidBackground" onClick={(e) => this.onDelete(media)}>T</button>
            <button className="Copy ThemeSolidBackground" onClick={(e) => this.onCopyLink(media)}>L</button>

            {this.props.showSelect && <button className="Select" onClick={(e) => this.onSelect(media)}>Kiválaszt</button>}
        </div>
    }
}


class MediaSelectorComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {imageUrl: this.props.value || "", showPopup: false};
        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    showPopup() {
        this.setState({showPopup: true});
    }


    onCancel() {
        this.setState({showPopup: false});
        if (this.props.onCancel)
            this.props.onCancel();
    }

    onChange(event) {
        // alert("EVENT " +JSON.stringify(event));
        if (this.props.onChange)
            this.props.onChange(event);

    }

    onSelect(media) {
        this.setState({imageUrl: media.mediaNameFull, showPopup: false}, (e) => {
            this.onChange({target: {name: this.props.name, value: media.mediaNameFull}})

            // if (this.props.onChange)
            //     this.props.onChange(this.state.media.imageUrl);
        });
    }

    render() {
        return <div className="MediaSelectorComponent">
            <input id={this.props.id} className={this.props.className} type="text" name={this.props.name}
                   value={this.props.value} onChange={this.onChange}/>
            <button className="MediaButton ThemeSolidBackground" onClick={this.showPopup}>[...]</button>
            {this.state.showPopup &&
            <MediaSelectorPopup onSelect={this.onSelect} onCancel={this.onCancel} app={this.props.app}
                                user={this.props.user}/>}
        </div>
    }

}

class MediaSelectorPopup extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {title: this.props.title};
        this.onCancel = this.onCancel.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSelect(media) {
        if (this.props.onSelect)
            this.props.onSelect(media);
    }

    render() {

        return <EditorPopupDialog width={890} height={620} onCancel={this.onCancel}
                                  classes="MediaSelectorPopup">
            <div className="EditorPopupDialogContainer">
                <MediaEditor app={this.props.app} user={this.props.user} onSelect={this.onSelect}/>
            </div>
            <div className="EditorPopupDialogFooter ThemeBackground">
                <button className="grayButton" onClick={this.onCancel}>Bezár</button>
            </div>
        </EditorPopupDialog>
    }
}


export default MediaEditor;
export {MediaSelectorComponent};