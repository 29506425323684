import React, {Component} from "react";
import {Helmet} from "react-helmet";
import FestivalEditor from "../festival/FestivalEditor";
import AnomaliesUpload from "./AnomaliesUpload";

class AnomaliesAdmin extends Component {


    constructor(props, context) {
        super(props, context);

    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Anomáliák</title>
                <meta name="description" lang="hu" content="Anomáliák"/>
            </Helmet>

            <AnomaliesUpload app={this.props.app} user={this.props.user}/>

        </div>
    }
}

export default AnomaliesAdmin;