//TranslateMessage: completed
import React, {Component} from "react";
import moment from "moment";
import './Calendar.css';
import {translateMessage} from "../i18n/TranslateMessage";

class CalendarMonth extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {date: this.props.date || new Date()};
        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
        this.selectDate = this.selectDate.bind(this);

    }


    componentDidMount() {
    }


    prevMonth() {
        let d = this.state.date;
        var nextMonth = new Date(d.getFullYear(), d.getMonth() - 1, 1);
        this.setState({date: nextMonth});

    }

    nextMonth() {
        let d = this.state.date;
        var nextMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
        this.setState({date: nextMonth});
    }

    selectDate(date) {
        this.props.dateSelected(date);
    }

    addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    render() {
        let months = [translateMessage("date_month_long_jan"), translateMessage("date_month_long_feb"), translateMessage("date_month_long_mar"),
            translateMessage("date_month_long_apr"), translateMessage("date_month_long_may"), translateMessage("date_month_long_jun"),
            translateMessage("date_month_long_jul"), translateMessage("date_month_long_aug"), translateMessage("date_month_long_sep"),
            translateMessage("date_month_long_oct"), translateMessage("date_month_long_nov"), translateMessage("date_month_long_dec"),];
        let d = this.state.date;
        var date = new Date(d.getFullYear(), d.getMonth(), 1)

        var now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const k = firstDay.getDay();
        let mod = k - 1;
        if (k === 0) mod = 6;
        let l = [];
        for (let i = 0; i < mod; i++) {
            l.push({});
        }
        const compensateView = l.map((o, i) => {
            return <div key={"compensation_" + i} className="CalendarDay">&nbsp;</div>
        });

        let days = [];
        let i;
        for (i = 0; i <= 6 * 7 - l.length-1; i++) {
            days.push(i);
        }

        const calendarDaysTitleView = days.map((value, index) => {
            const thisDay = this.addDays(new Date(date.getFullYear(), date.getMonth(), 1), value);

            return <div key={"calendar_title" + index} className="CalendarDay">
                {(thisDay < now) && <span className="ThemeSolidColor CalendarPastDay">{thisDay.getDate()}</span>}
                {thisDay.getMonth() > now.getMonth() && <button className="CalendarDayNextMonthButton transparentButton"
                                           onClick={(e) => this.selectDate(thisDay)}>{thisDay.getDate()}</button>}

                { (thisDay >= now && thisDay.getMonth() <= now.getMonth()) && <button className="CalendarDayButton transparentButton"
                                           onClick={(e) => this.selectDate(thisDay)}>{thisDay.getDate()}</button>}

            </div>
        })


        const calendarDaysView = [translateMessage("date_day_short_mon"), translateMessage("date_day_short_tue"),
            translateMessage("date_day_short_wed"), translateMessage("date_day_short_thu"), translateMessage("date_day_short_fri"),
            translateMessage("date_day_short_sat"), translateMessage("date_day_short_sun")].map((i) => {
            return <div key={"day" + i} className="CalendarDay CalendarDayTitle">{i}</div>
        })

        return <div className={this.props.parentClass || "CalendarWidget ThemeBackground"}>
            <div className="CalendarHeaderSection ThemeBackground">
                <div className="ThemeSolidBackground">
                    <div className="Title">
                        <button className="HighlightColor CalendarPrev" onClick={this.prevMonth}/>
                        <span className="Month"> {months[date.getMonth()]}</span>
                        <button className="HighlightColor CalendarNext" onClick={this.nextMonth}/>
                    </div>
                    <div className="ThemeBackground">{calendarDaysView}</div>
                    <div style={{clear: 'both'}}/>
                </div>
                <div>{compensateView}{calendarDaysTitleView}</div>
                <div style={{clear: 'both'}}/>
            </div>
        </div>
    }
}

export default CalendarMonth;