import React, {Component} from "react";
import {Link} from "react-router-dom";


class BarqMeLink extends Component {

    render() {
        const action = this.props.action;
        let link = "/";
        let webLink = null;
        if (action.startsWith("search"))
            link = "/search/SEARCH:" + action.substring(7);
        else if (action.startsWith("page"))
            link = "/page/" + action.substring(5);
        else if (action.startsWith("article"))
            link = "/page/" + action.substring(8);
        else if (action.startsWith("http"))
            webLink = action;
        else if (action.startsWith("web:"))
            webLink = action.substring(4);

        if (webLink!=null) {
            return <a className={this.props.className} href={webLink}>{this.props.children}</a>
        } else{
            return <Link className={this.props.className} to={link}>{this.props.children}</Link>
        }

    }
}

export default BarqMeLink;