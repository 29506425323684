import React, {Component} from "react";
import "./MyPosts.css"
import {ViewArticlePanel} from "../../article/Article";
import {Link} from "react-router-dom";

var moment = require('moment-timezone');

class ShowPost extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {text: ""};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    render() {
        const post = this.props.post;
        return <div className="ShowPost">

            <div className="ShowPostImage">
                <div className="MyPostProfilePicture" style={{backgroundImage: 'url(' + post.promoter.imageLogo + ")"}}>&nbsp;</div>
            </div>

            <div className="ShowPostContent">
                {/*{type === "image" && <div><img src={post.imageUrl}/></div>}*/}
                <div>
                    <Link to={"/search/SEARCH:"+post.promoter.tag.tag+"#tab=posts"}><b>{post.promoter.name}</b></Link> publikálta:
                    {moment(post.publishedStamp).tz("Europe/Budapest").format("YYYY.MM.DD HH:mm")}

                    {this.props.editorMode===true && <button>Törlés</button>}
                </div>
                <ViewArticlePanel noPadding={true} article={post.article} app={this.props.app} user={this.props.user}
                                  editorMode={false}/></div>
            </div>
    }

}

export default ShowPost;