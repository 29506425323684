import React, {Component} from "react";
import ajax from "superagent";
import queryString from "query-string";
import {ViewArticlePanel, ViewContentArticle, ViewHeaderPanel} from "../article/Article";
import Loading from "../components/Loading";
import PubSub from "pubsub-js";
import "./ContentTabbed.css";
import SearchPanel from "../search/SearchPanel";
import {Link} from "react-router-dom";
import TabBarqs from "./TabBarqs";

class ContentTabbedPaged extends Component {


    constructor(props, context) {
        super(props, context);
        this.subscriptionChanged = this.subscriptionChanged.bind(this);
        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        this.switchTab = this.switchTab.bind(this);
        this.showPage = this.showPage.bind(this);
        const values = queryString.parse(window.location.hash);
        const tab = values["tab"] || "0";
        const page = values["page"] || "0";
        const limit = values["limit"] || "50";
        const link = window.location.href.split('#')[0];
        const baseLink = "/search" + link.split("/search")[1];

        const q = this.props.query;
        const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
        this.state = {
            searchText: searchText,
            tab: parseInt(tab),
            page: parseInt(page),
            limit: parseInt(limit),
            loading: false,
            latestPage: -1,
            showSearch: true,
            baseLink: baseLink,
            content: this.props.content
        }
    }


    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        PubSub.subscribe('SUBSCRIPTION_SUBSCRIBED', this.subscriptionChanged);
        PubSub.subscribe('SUBSCRIPTION_UNSUBSCRIBED', this.subscriptionChanged);
        if (this.props.content == null)
            this.downloadSearchResult();

    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
        PubSub.unsubscribe('SUBSCRIPTION_SUBSCRIBED');
        PubSub.unsubscribe('SUBSCRIPTION_UNSUBSCRIBED');
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const values = queryString.parse(window.location.hash);
        const tab = parseInt(values["tab"] || "0");
        const page = parseInt(values["page"] || "0");
        const limit = parseInt(values["limit"] || "50");
        const link = window.location.href.split('#')[0];
        const baseLink = "/search" + link.split("/search")[1];
        if (prevProps.query !== this.props.query) {
            const q = this.props.query;
            const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
            this.handleSearchChange(searchText);
        } else if (prevState.tab !== tab || prevState.page !== page || prevState.limit !== limit || prevState.baseLink !== baseLink) {
            this.setState({tab: tab, baseLink: baseLink, page: page, limit: limit});
        }
    }


    subscriptionChanged(msg, action) {
        if (this.state.content.settings.searchData.fullText === action.settings.searchData.fullText) {
            const content = this.state.content;
            content.settings = action.settings;
            this.setState({content: content})
        }
    }


    downloadSearchResult() {
        const page = this.state.page;
        const limit = this.state.limit;
        this.setState({loading: true}, (e) => {
            const q = this.props.query;
            ajax
                .post(this.props.app.server_url + '/barqboard/content')
                .query({
                    client_build: "100",
                    platform: "react",
                    lang: this.props.app.lang,
                    page: page,
                    limit: limit,
                    text: q,
                    sid: this.state.content?.sid
                })
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    console.log(res.body);
                    this.setState({content: res.body, loading: false}, () => {
                        window.scrollTo(0, 0);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        });

    }

    handleSearchChange(searchText) {
        const q = "SEARCH:" + searchText;
        this.props.history.push("/search/" + q);
        this.setState({q: q, searchText: null, content: null}, () => {
            this.downloadSearchResult();
        });
    }

    handleSearchTextChangedEvent(msg, searchText) {
        this.handleSearchTextChange(searchText);
    }


    linkRef = React.createRef();

    onSubmitSearch(text) {
        this.handleSearchChange(text);
    }

    switchTab(tab) {
        this.setState({tab: tab}, (e) => {
            this.buildHash();
        })
    }

    showPage(page) {
        //Jump to this page
        this.setState({page: page}, (e) => {
            this.buildHash();
            this.downloadSearchResult();
        })
    }

    buildHash() {
        let hash = "#page=" + this.state.page + "&tab=" + this.state.tab;
        if (this.state.limit !== 50)
            hash = hash + "&limit=" + this.state.limit;
        window.location.hash = hash;
    }


    render() {
        const content = this.state.content;
        if (content) {

            const searchPanel = ((content.settings.mode === "search" || content.settings.mode === "subscription" || content.settings.mode === "festival") && this.state.showSearch) &&
                <SearchPanel mode={!content.result.fullCount > 0 && content.settings.singleSection ? "full" : "compact"}
                             app={this.props.app}
                             user={this.props.user}
                             form={content.form}
                             text={content.settings.searchData.text}
                             dateAfter={content.settings.searchData.filter.dateAfter}
                             dateBefore={content.settings.searchData.filter.dateBefore}
                             onSearchTextChanged={this.props.onSearchTextChanged}
                             onSubmitSearch={this.onSubmitSearch}/>


            let article = content.details.article;
            if (this.state.tab > 0) article = content.details.tabs[this.state.tab - 1];

            let filterPlace = "header";

            return <div>
                {searchPanel}
                <ViewContentArticle app={this.props.app} user={this.props.user} article={article}
                                    filterPlace={filterPlace}
                                    switchTab={this.switchTab} tabSelected={this.state.tab}/>


                {this.state.tab === 0 && <TabBarqs app={this.props.app} user={this.props.user}
                                                   query={this.props.query}
                                                   content={content}
                                                   settings={content.settings}
                                                   paged={true}
                                                   showPage={this.showPage}/>}
            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}

export default ContentTabbedPaged;
