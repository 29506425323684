import React, {Component} from 'react';
import './Register.css';
import Container, {Padding} from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import {DownloadArticle} from "../article/Article";
import ajax from "superagent";
import {LoginForm} from "./LoginForm";
import {Helmet} from "react-helmet";
import {ArticleParagraphTitleView} from "../article/paragraph/ArticleParagraphTitle";
import ArticleParagraphText from "../article/paragraph/ArticleParagraphText";
import {Redirect} from "react-router-dom";

class PageRegister extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            userName: "",
            password: "",
            passwordRetry: "",
            email: "",
            firstName: "",
            lastName: "",
            user: null,
            // user: {userId: 53, firstName: "Robopeter"},
            errors: [],
            finished: false
        }
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }

    register() {
        const user = this.state;

        const send = {};

        send["platform"] = "react";
        send["userName"] = user.userName;
        send["email"] = user.userName;
        send["password"] = user.password;
        send["firstName"] = user.firstName;
        send["lang"] = this.props.app.lang;


        ajax
            .post(this.props.app.server_url + '/user/register')
            .query(send)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                const result = res.body;
                console.log(result);

                if (result.error != null) {
                    this.setState({errors: [{message: result.error.message}]});
                } else {
                    this.setState({user: result, finished: true});
                }

            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    submit() {
        const user = this.state;
        const errors = [];


        if (user.password.length < 6) errors.push({message: "A megadott jelszónak legalább 6 karakter hosszúnak kell lennie. Kérlek javíts a folytatáshoz."});
        if (user.password !== user.passwordRetry)
            errors.push({message: "A jelszó és jelszó újra mezők értéke különbözik. Kérlek javíts a folytatáshoz."});


        if (user.userName.trim().length === 0) errors.push({message: "Kérjük válassz egy felhasználónevet!"});
        if (user.firstName.trim().length === 0) errors.push({message: "Kérjük add meg a keresztneved!"});

        if (errors.length > 0) {
            this.setState({errors: errors});
        } else {
            this.register();
        }
    }

    render() {

        if (this.state.finished) {
            return <Redirect to={'/login-form'}/>;

        } else {
            return <div>
                <Helmet>
                    <title>barq.me | Regisztráció</title>
                </Helmet>
                <BarqMeCookies/>
                <Sidebar settings={this.props.settings}>
                    <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
                </Sidebar>
                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    <Padding>
                        <div>

                            <div className={"LoginInfoDialog LoginInfoDialogSize ThemeBackground"}>
                                <img className={"BarqmeBadge"} src={"/BarqMeBadgeModern.png"}/>
                                <div className={"BarqmeInfo"}>
                                    <ArticleParagraphTitleView paragraph={{title: "A barq.me üdvözöl!"}}
                                                               noPadding={true}/>
                                    <ArticleParagraphText
                                        paragraph={{
                                            text: "Segítünk neked érdekes programokat találni. Célunk, hogy gyorsan és hatékonyan szerezz " +
                                                "tudomást azokról az eseményekről, amelyek érdekelnek téged. Ehhez a barq.me megtanulja, " +
                                                "hogy milyen eseményeket szeretsz és a későbbiekben ezek alapján ajánl neked programokat! "
                                        }} noPadding={true}/>

                                </div>
                            </div>

                            <div className="LoginDialog ThemeBackground LoginDialogSize">
                                <ArticleParagraphTitleView paragraph={{title: "Regisztrálj és kezdj el böngészni"}}/>
                                <ArticleParagraphText
                                    paragraph={{text: "Kérjük a regisztrációhoz töltsd ki a következő formot:"}}/>

                                {(this.state.errors && this.state.errors.length > 0) && <div>
                                    <ul>
                                        {this.state.errors.map((err, i) => {
                                            return <li key={"err" + i}>{err.message}</li>
                                        })}
                                    </ul>
                                </div>}
                                <input className="field ThemeSolidBackground" type="text" name="userName"
                                       value={this.state.userName}
                                       placeholder="E-mail cím" onChange={this.onPropertyChanged}/>

                                <input className="field ThemeSolidBackground" type="password" name="password"
                                       value={this.state.password}
                                       placeholder="Jelszó" onChange={this.onPropertyChanged}/>
                                <input className="field ThemeSolidBackground" type="password" name="passwordRetry"
                                       value={this.state.passwordRetry}
                                       placeholder="Jelszó ismét" onChange={this.onPropertyChanged}/>

                                <input className="field ThemeSolidBackground" type="text" name="firstName"
                                       value={this.state.firstName}
                                       placeholder="Keresztnév" onChange={this.onPropertyChanged}/>
                                <input className="field ThemeSolidBackground" type="text" name="lastName"
                                       value={this.state.lastName}
                                       placeholder="Vezetéknév" onChange={this.onPropertyChanged}/>

                                <button className="button" onClick={this.submit}>Regisztráció</button>
                            </div>
                        </div>
                    </Padding>
                </Container>
            </div>
        }
    }
}

export default PageRegister;