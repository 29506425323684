import React, {Component} from 'react';
import './Login.css';
import Container, {Padding} from "../components/Components";
import {DownloadArticle} from "../article/Article";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import ErrorDialogHandler from "../ErrorDialog";
import GoogleLogin from 'react-google-login';
import ajax from "superagent";
import {Redirect, Route} from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import ArticleParagraphText from "../article/paragraph/ArticleParagraphText";
import {ArticleParagraphTitleView} from "../article/paragraph/ArticleParagraphTitle";
import {Helmet} from "react-helmet";


class PageLogin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {userName: '', password: ''};
        this.login = this.props.onLogin;
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseGoogleFailed = this.responseGoogleFailed.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    responseGoogle(response) {
        this.authWithGoogle(response.tokenObj.id_token)
    }

    responseGoogleFailed(response) {
        if (response.error === "popup_closed_by_user") {

        } else {
            alert("oh nooo: " + JSON.stringify(response));
        }
    }

    responseFacebook(response) {
        this.authWithFacebook(response.accessToken);
    }

    authWithGoogle(id_token) {
        ajax
            .post(this.props.app.server_url + '/user/auth_google')
            .query({"client_build": "100", "platform": "react",
                "lang": this.props.app.lang,
                "idToken": id_token})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                const userObj = res.body;
                this.login(userObj.userName, userObj.password);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    authWithFacebook(id_token) {
        ajax
            .post(this.props.app.server_url + '/user/auth_facebook')
            .query({"client_build": "100", "platform": "react",
                "lang": this.props.app.lang,
                "idToken": id_token})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                const userObj = res.body;
                this.login(userObj.userName, userObj.password);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    render() {
        const user = this.props.user;
        if (user.anonym) {
            return <div>
                <Helmet>
                    <title>barq.me | Bejelentkezés</title>
                </Helmet>
                <BarqMeCookies/>

                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    {/*<DownloadArticle app={this.props.app} user={this.props.user} article="web-login"/>*/}
                    {/*<div className={"LoginBackground"}>*/}

                    {/*</div>*/}
                    <Padding>
                        <div className={"LoginInfoDialog LoginInfoDialogSize ThemeBackground"}>

                            <img className={"BarqmeBadge"} src={"/BarqMeBadgeModern.png"}/>
                            <div className={"BarqmeInfo"}>
                                <ArticleParagraphTitleView paragraph={{title: "A barq.me üdvözöl!"}} noPadding={true}/>
                                <ArticleParagraphText
                                    paragraph={{
                                        text: "Segítünk neked érdekes programokat találni. Célunk, hogy gyorsan és hatékonyan szerezz " +
                                            "tudomást azokról az eseményekről, amelyek érdekelnek téged. Ehhez a barq.me megtanulja, " +
                                            "hogy milyen eseményeket szeretsz és a későbbiekben ezek alapján ajánl neked programokat! "
                                    }} noPadding={true}/>

                            </div>
                        </div>


                        <div className="LoginDialog LoginDialogSize ThemeBackground">

                            <ArticleParagraphTitleView paragraph={{title: "Jelentkezz be, iratkozz fel és kezdj el böngészni"}}/>
                            <ArticleParagraphText
                                paragraph={{text: "Kérjük válaszd ki, hogyan szeretnéd használni az alkalmazást:"}}/>

                            <GoogleLogin
                                clientId="134221966604-s49tohbtog6j38h96k46oo409qui2t04.apps.googleusercontent.com"
                                render={renderProps => (
                                    <button className="button" onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}>Folytatás Google fiókkal</button>
                                )}
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogleFailed}
                                cookiePolicy={'single_host_origin'}
                            />

                            <FacebookLogin
                                appId="183351258963033"
                                autoLoad={false}
                                redirectUri={"https://barq.me/"}
                                fields="name,email,picture"
                                scope="public_profile"
                                cssClass="button"
                                disableMobileRedirect={true}
                                callback={this.responseFacebook}/>

                            <Route render={({history}) => (
                                <button className="button" onClick={() => {
                                    history.push('/login-form')
                                }}>Folytatás email címmel</button>
                            )}/>
                            <Route render={({history}) => (
                                <button className="button" onClick={() => {
                                    history.push('/register')
                                }}>Regisztráció email címmel</button>
                            )}/>
                        </div>
                    </Padding>
                </Container>
                <ErrorDialogHandler/>
            </div>
        } else {
            return <Redirect to={'/'}/>;
        }
    }
}

export default PageLogin;
