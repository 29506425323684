import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import {Padding} from "../../components/Components";
import ajax from "superagent";
import {ArticleListElement} from "../../article/Articles";
import PageArticleEditor from "../../article/ArticleEditor";
import {PromoterTabTitle} from "../EditorComponent";
import queryString from "query-string";
import Loading from "../../components/Loading";
import {Link} from "react-router-dom";

const PubSub = require('pubsub-js');

class PromoterArticles extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const code = values["code"] || null;
        const group = values["group"] || null;
        let tab = code ? "editor" : "list";
        this.state = {tab: tab, group: group, article: {code: code}};
        this.changeTab = this.changeTab.bind(this);
        this.newArticle = this.newArticle.bind(this);
        this.editArticle = this.editArticle.bind(this);
        this.listArticles = this.listArticles.bind(this);
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const values = queryString.parse(window.location.hash);
        const code = values["code"] || null;
        const oldCode = this.state.code;
        const group = values["group"] || null;
        const oldGroup = this.state.group;

        if ((code != null && oldCode != null && oldCode !== code) || (group != null &&  oldGroup !== group)) {
            this.setState({code: code, group: group}, () => {

            });
        }
    }

    changeTab(tab) {
        this.setState({tab: tab}, () => {
        });
    }

    newArticle() {
        this.setState({article: null});
        this.changeTab("editor");
    }

    editArticle(article) {
        const hash = '#tab=articles&code=' + article.code;
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }

        this.setState({tab: "editor", article: article})
    }

    listArticles() {
        const hash = '#tab=articles';
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }
        this.changeTab("list")
    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Cikkek</title>
                <meta name="description" lang="hu" content="Cikkek"/>
            </Helmet>
            {/*<Padding>*/}

            {/*</Padding>*/}
            {this.state.tab === "list" &&
            <PromoterArticleList group={this.state.group} editorMode={this.props.editorMode} app={this.props.app}
                                 onEditArticle={this.editArticle} onNewArticle={this.newArticle}/>}
            {this.state.tab === "editor" &&
            <PromoterArticleEditor editorMode={this.props.editorMode} app={this.props.app}
                                   article={this.state.article} onListArticle={this.listArticles}/>}
        </div>
    }
}


class PromoterArticleList extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            result: [],
            groups: [
                {key: "promoter", title: "Felhasználói cikk"},
                {key: "webpage", title: "Weboldal"},
                {key: "article", title: "Cikk"},
                {key: "fragment", title: "Weboldal fragment"},
                {key: "web-fragment-home", title: "Főoldal fragment"},
                {key: "web-content-home", title: "Főoldal tartalom"}
            ]
        };
        this.editArticle = this.props.onEditArticle;
        this.newArticle = this.props.onNewArticle.bind(this);
        this.reloadCache = this.reloadCache.bind(this);
    }


    componentDidMount() {
        this.downloadMyArticles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.group !== this.props.group) {
            this.downloadMyArticles();
        }
    }


    downloadMyArticles() {
        ajax
            .post(this.props.app.server_url + '/editor/article/my-articles')
            .query({"platform": "react", group: this.props.group || "promoter"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    reloadCache() {
        ajax
            .post(this.props.app.server_url + '/article/reload')
            .query({"platform": "react",
                "lang": this.props.app.lang,
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("CACHE újratöltve!")
                this.downloadMyArticles();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        const result = this.state.result;
        let contentView;
        if (result.articles == null) {
            contentView = <Loading loading={true}/>
        } else if (result.articles.length === 0) {
            contentView = <Padding>Nincsenek cikkeid</Padding>
        } else {
            const group = this.props.group;
            const hideArticleImages = (group === "fragment" || group === "web-fragment-home" || group === "web-content-home");
            const articlesContent = result.articles.map((article, i) => {
                return <ArticleListElement className={(i % 2 === 0 ? "ThemeBackground" : "ThemeSolidBackground")}
                                           key={'article' + article.code} article={article} hideImage={hideArticleImages}
                                           editorMode={this.props.editorMode} onEditArticle={this.editArticle}/>

            });
            contentView = <div className="ViewBarqCollection">{articlesContent}</div>
        }

        return <div>
            <PromoterTabTitle title="Cikkek"/>
            <Padding>
                <div>
                    {this.state.groups.map((group) => {
                        return <Link key={"group-"+group.key} className="grayButton" to={"/editor/#tab=articles&group=" + group.key}> {group.title} </Link>
                    })}
                </div>

                <p>Itt találod azokat a cikkeket, amelyeket te írtál. <button className="grayButton"
                                                                              onClick={this.newArticle}>Új cikk
                    írása</button>
                    <button className="grayButton" onClick={this.reloadCache}>Cache újratöltése</button>
                </p>
                {contentView}
            </Padding>
        </div>
    }
}


class PromoterArticleEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {article: null};
        this.updateArticle = this.updateArticle.bind(this);
        this.listArticles = this.props.onListArticle.bind(this);
    }


    componentDidMount() {
        if (this.props.article == null) {
            this.newArticle();
        } else {
            this.downloadArticle();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    newArticle() {
        ajax
            .post(this.props.app.server_url + '/editor/article/new')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({article: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    downloadArticle() {
        ajax
            .post(this.props.app.server_url + '/article/get')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                code: this.props.article.code, editorMode: this.props.editorMode})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({article: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    save(event) {
        const article = this.state.article;
        let paragraphs = article.paragraphs.filter(function (p) {
            return p != null;
        });
        article.paragraphs = paragraphs;
        ajax
            .post(this.props.app.server_url + '/editor/article/save')
            .query({...{"platform": "react"}})
            .send(article)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("MENTVE!");
                this.setState({article: null}, () => {
                    this.setState({article: res.body});
                })
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    updateArticle(article) {

        console.log(article);


        this.setState({article: article}, (e) => {
            this.save()
        })
    }

    render() {
        const article = this.state.article;
        if (article != null) {
            return <div>
                <PromoterTabTitle title={"Cikk: " + this.state.article.title}/>
                <Padding>
                    <button className="grayButton" onClick={this.listArticles}>Vissza a cikkek listájához</button>
                </Padding>
                <PageArticleEditor article={article} onArticleChanged={this.updateArticle} onCancel={this.listArticles}
                                   app={this.props.app} user={this.props.user} editorMode={this.props.editorMode}/>
            </div>
        } else {
            return <Loading loading={true}/>
        }
    }
}


export default PromoterArticles;