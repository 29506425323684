import React, {Component} from "react";
import ajax from "superagent";

class BarqBatchUpload extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {sheetId: "1OJG471Vd9J29NhMmzS47ClAuFmi-03C2Ter1m40dWwE", sheetName: "SMALL"};
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.executeImport = this.executeImport.bind(this);
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }


    executeImport() {
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/import_sheet')
            .query({"platform": "react", "sheetId": this.state.sheetId, sheetName: this.state.sheetName})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                console.log(res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }


    render() {
        return <div className="BarqBatchUpload">
            <div className="ThemeBackground Padding">
                Add meg a betölteni kívánt google sheet azonosítóját és a sheet nevét!<br/>
                Google Sheet azonosító: <input type="text" name="sheetId" value={this.state.sheetId}
                                               onChange={this.onPropertyChanged}/><br/>
                Google Sheet neve: <input type="text" name="sheetName" value={this.state.sheetName}
                                          onChange={this.onPropertyChanged}/><br/>
                <button onClick={this.executeImport}>Importálás</button>
            </div>
        </div>
    }
}

export default BarqBatchUpload;