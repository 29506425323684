import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import './BarqAdmin.css';
import ajax from "superagent";
import queryString from "query-string";
import BarqEditor from "./BarqEditor";
import {PromoterTabTitle} from "../EditorComponent";
import {Padding} from "../../components/Components";
import {Link} from "react-router-dom";
import SelectTags from "./SelectTags";
import SelectCategories from "./SelectCategories";
import Loading from "../../components/Loading";
// import {SearchRelatedTags} from "../../search/SearchTags";

class BarqAdmin extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const barqId = values["barq"] || null;
        let tab = values["mode"] || "list";
        if (barqId != null) tab = "editor";

        this.state = {tab: tab, barq: {barqId: barqId}};
        this.changeTab = this.changeTab.bind(this);
        this.newBarq = this.newBarq.bind(this);
        this.editBarq = this.editBarq.bind(this);
        this.closeEditor = this.closeEditor.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    changeTab(tab) {

        let barqId = this.state.barq.barqId;
        if (tab === "list") barqId = null;
        let hash = '#tab=my-events';
        if (barqId != null) {
            hash = hash + "&mode=editor&barq=" + barqId;
        } else if (tab === "editor") {
            hash = hash + "&mode=editor";
        }
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }
        this.setState({tab: tab}, () => {
        });
    }

    closeEditor() {
        this.changeTab("list");
    }

    newBarq() {
        this.setState({tab: "editor", barq: {barqId: null}}, (e) => {
            this.changeTab("editor");
        })

    }

    editBarq(barq) {
        this.setState({tab: "editor", barq: barq}, (e) => {
            this.changeTab("editor");
        })

    }


    render() {
        return <div>
            <Helmet>
                <title>barq.me | Események</title>
                <meta name="description" lang="hu" content="Események"/>
            </Helmet>

            {this.state.tab === "list" &&
            <BarqsList mode="admin" editorMode={this.props.editorMode} app={this.props.app} onNewBarq={this.newBarq}
                       onEditBarq={this.editBarq}/>}
            {this.state.tab === "editor" &&
            <BarqEditor editorMode={this.props.editorMode} app={this.props.app} onClose={this.closeEditor}
                        barq={this.state.barq}/>}
        </div>
    }
}


class BarqsList extends Component {


    constructor(props, context) {
        super(props, context);

        const values = queryString.parse(window.location.hash);
        const text = values["text"] || null;
        const filter = values["filter"] || null;


        this.state = {
            result: {barqs: null},
            loading: false,
            selectAll: false,
            selection: [],
            text: text || "",
            showOffer: false,
            filter: filter || "",
            filters: [
                {key: "", value: "Minden"},
                {key: "nonpublic", value: "Nem publikált"},
                {key: "public", value: "Publikált"},
                {key: "incomplete", value: "Hiányos"},
                {key: "uncategorized", value: "Kategória nélkül"},
                {key: "noimage", value: "Kép nélkül"},
                {key: "image_generated", value: "Generált képekkel"},
                {key: "cancelled", value: "Elmaradó események"},
                {key: "kept", value: "Megrendezett események"},

            ],
            batch: "",
            batches: [
                {key: "", value: "Válassz"},
                {key: "publish", value: "Publikálás"},
                {key: "draft", value: "Elrejtés"},
                {key: "cancel", value: "Elmarad"},
                {key: "keep", value: "Mégsem marad el"},
                {key: "delete", value: "Törlés"},
                {key: "add_image", value: "Képek hozzáadása"},
                {key: "set_categories", value: "Kategóriák beállítása"},
                {key: "add_tag", value: "Címke hozzáadása"},
                {key: "remove_tag", value: "Címke törlése"},
                {key: "complete", value: "Kiegészítés"}
            ]
        };
        this.editBarq = this.props.onEditBarq.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
        this.batchChanged = this.batchChanged.bind(this);
        this.textChanged = this.textChanged.bind(this);
        this.textValueChanged = this.textValueChanged.bind(this);
        this.searchValue = this.searchValue.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.selectionModeChanged = this.selectionModeChanged.bind(this);
        this.reloadReadyToOffers = this.reloadReadyToOffers.bind(this);
        this.executeAction = this.executeAction.bind(this);
        this.addTags = this.addTags.bind(this);
        this.removeTags = this.removeTags.bind(this);
        this.saveCategories = this.saveCategories.bind(this);
        this.hideTagsDialog = this.hideTagsDialog.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.reloadCache = this.reloadCache.bind(this);

    }


    componentDidMount() {
        this.downloadBarqs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadBarqs() {
        if (this.props.mode === "admin") {
            this.downloadAdminEvents();
        } else {
            this.downloadMyEvents();
        }
    }

    downloadAdminEvents() {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/barqs')
            .query({"platform": "react", filter: this.state.filter, text: this.state.text})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body, loading: false});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    downloadMyEvents() {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq/my-events')
            .query({"platform": "react", filter: this.state.filter, text: this.state.text})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body, loading: false});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    setUrlState() {
        let hash = '#tab=my-events&text=' + this.state.text + "&filter=" + this.state.filter;
        if (this.props.mode === "admin")
            hash = '#tab=barq_admin&text=' + this.state.text + "&filter=" + this.state.filter;
        if (window.history.pushState) {
            window.history.pushState(null, null, hash);
        } else {
            window.location.hash = '#' + hash;
        }
    }

    filterChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({filter: value}, () => {
            this.setUrlState();
            this.downloadBarqs();
        })
    }

    batchChanged(event) {
        const target = event.target;
        const value = target.value;
        this.setState({batch: value}, () => {
            this.downloadBarqs();
        })
    }


    textChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.textValueChanged(value);
    }

    textValueChanged(value) {
        this.setState({text: value, showOffer: true})
    }

    searchValue(value) {
        this.setState({text: value, showOffer: false}, () => {
            this.setUrlState();
            this.downloadBarqs();
        })
    }


    selectionModeChanged() {
        this.setState({selectionMode: !this.state.selectionMode});
    }

    selectionChanged(barq, selected) {
        let selection = [...this.state.selection];
        if (selected) {
            selection.push(barq);
        } else {
            const index = selection.findIndex((b) => b.barqId === barq.barqId);
            selection.splice(index, 1);
        }
        this.setState({selection: selection});
    }

    reloadReadyToOffers() {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/reload-ready-to-offers')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("readyToOffer adatbázis frissítve!");
                this.downloadBarqs();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    executeAction() {

        const executeNow = ["publish", "draft", "cancel", "keep", "complete", "delete", "add_image"];
        if (this.state.selection.length > 0 && executeNow.includes(this.state.batch)) {
            this.setState({loading: true});
            ajax
                .post(this.props.app.server_url + '/editor/barq_admin/batch')
                .query({"platform": "react", filter: this.state.filter, action: this.state.batch})
                .send(this.state.selection)
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    this.downloadBarqs();
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        }
    }

    addTags(tags) {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/add_tags')
            .query({"platform": "react"})
            .send({barqs: this.state.selection, tags: tags})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({batch: ""});
                this.downloadBarqs();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    removeTags(tags) {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/remove_tags')
            .query({"platform": "react"})
            .send({barqs: this.state.selection, tags: tags})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({batch: ""});
                this.downloadBarqs();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    hideTagsDialog() {
        this.setState({batch: ""});
    }

    saveCategories(categories) {
        this.setState({loading: true});
        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/set_categories')
            .query({"platform": "react"})
            .send({barqs: this.state.selection, categories: categories})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({batch: ""});
                this.downloadBarqs();
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    selectAll() {

        const selectAll = !this.state.selectAll;

        if (!selectAll) {
            this.setState({selectAll: selectAll, selection: []})
        } else {
            this.setState({selectAll: selectAll, selection: this.state.result.barqs})
        }
    }

    reloadCache() {
        ajax
            .post(this.props.app.server_url + '/admin/system/reloadCache')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                alert("full CACHE újratöltve!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }
    render() {
        const result = this.state.result;


        let filtersView = null;
        if (this.state.filters) {
            const options = this.state.filters.map((opt) => {
                return <option key={"filter" + opt.key} value={opt.key}>{opt.value}</option>
            })
            filtersView =
                <select name="filter" value={this.state.filter || ""} onChange={this.filterChanged}>{options}</select>
        }

        let batchesView = null;
        if (this.state.batches) {

            const options = this.state.batches.map((opt) => {
                return <option key={"batch-" + opt.key} value={opt.key}>{opt.value}</option>
            })
            batchesView =
                <select name="batch" value={this.state.batch || ""} onChange={this.batchChanged}>{options}</select>
        }

        return <div>
            {this.props.mode === "admin" && <PromoterTabTitle title="Minden esemény"/>}
            {this.props.mode !== "admin" && <PromoterTabTitle title="Eseményeim"/>}

            <Padding>
                <div className="Toolbar">
                    {this.props.onNewBarq &&
                    <div  className="ToolbarBlock"><button className="barqButton" onClick={this.props.onNewBarq}>Új
                        esemény</button><button className="barqButton"
                                                onClick={this.props.onBatchUploadBarq}>
                        Események tömeges feltöltése
                    </button><button className="barqButton" onClick={this.props.onUploadImages}>
                        Kapcsolódó képek feltöltése
                    </button><button className="barqButton HighlightBackground" onClick={this.reloadCache}>
                        CACHE RELOAD
                    </button></div>}


                    <input className="Field" type="text" name="text" value={this.state.text}
                           onChange={this.textChanged}/>
                    <button className="barqButton" onClick={() => this.searchValue(this.state.text)}>Keres</button>

                    {/*{this.state.showOffer &&*/}
                    {/*<SearchRelatedTags search={this.state.text} app={this.props.app} user={this.props.user}*/}
                    {/*            onTagChanged={this.searchValue}/>}*/}

                    {filtersView && <div className="ToolbarBlock">Szűrés: {filtersView}</div>}
                    <div className="ToolbarBlock">Feladat: {batchesView}
                        <button className="barqButton" onClick={this.executeAction}>Akció</button>
                    </div>
                    {this.props.mode === "admin" && <span>| <button className="grayButton"
                                                                    onClick={this.reloadReadyToOffers}>readyToOffer újragenerálása</button></span>}
                </div>

                {this.state.batch === "add_tag" &&
                <SelectTags onSave={this.addTags} onCancel={this.hideTagsDialog} app={this.props.app}/>}

                {this.state.batch === "remove_tag" &&
                <SelectTags onSave={this.removeTags} onCancel={this.hideTagsDialog} app={this.props.app}/>}

                {this.state.batch === "set_categories" &&
                <SelectCategories onSave={this.saveCategories} onCancel={this.hideTagsDialog} app={this.props.app}/>}


                {result?.barqs != null && result.barqs.length > 0 && <div className="BarqAdminList">
                    <div className={"Row"}>
                        <div className="Col Col1">
                            <input type="checkbox" name="checked" checked={this.state.selectAll}
                                   onChange={this.selectAll}/>
                        </div>
                        <div className="Col Col2">Kép</div>
                        <div className="Col Col3">Megnevezés</div>
                        <div className="Col Col4">Kezdet</div>
                        <div className="Col Col5">Helyszín</div>
                        <div className="Col Col6">Kitöltött</div>
                        <div className="Col Col7">Publikált</div>
                        <div className="Col Col8">

                        </div>

                    </div>
                    {result.barqs.map((barq, i) => {
                        const index = this.state.selection.findIndex((b) => b.barqId === barq.barqId);
                        const selected = index >= 0;

                        return <div key={"barq-" + barq.barqId}
                                    className={"Row " + (i % 2 === 0 ? "ThemeSolidBackground" : "")}>
                            <div className="Col Col1">
                                <input type="checkbox" name="checked" checked={selected}
                                       onChange={(e) => this.selectionChanged(barq, !selected)}/>
                            </div>
                            <div className="Col Col2"><img src={barq.imageUrl}/></div>
                            <div className="Col Col3"><Link to={"/barq/" + barq.barqId}>{barq.title}</Link></div>
                            <div className="Col Col4">{barq.startDate}</div>
                            <div className="Col Col5">{barq.location.name}</div>
                            <div className="Col Col6">{barq.readyToOffer ? "rendben" : "hiányos"}</div>
                            <div className="Col Col7">{barq.published ? barq.published : "nincs publikálva"}</div>
                            <div className="Col Col8">
                                <button className="barqButton" onClick={(e) => this.editBarq(barq)}>Szerk.</button>
                            </div>

                        </div>
                    })}
                </div>}
            </Padding>
            <Loading loading={this.state.loading}/>

        </div>
    }
}

export default BarqAdmin;
export {BarqsList};