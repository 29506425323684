import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./BarqItemTile.css";
import Moment from "react-moment";
import TimeToGoString, {ambiguousTime, timeToGo} from "./TimeToGoCalculator";
import {displayBarqStartTime, translateMessage} from "../i18n/TranslateMessage";
import BarqBadges from "./BarqBadges";

class BarqItemTile extends Component {

    constructor(props, context) {
        super(props, context);
        this.editBarq = this.editBarq.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    editBarq(event) {
        this.props.onEditBarq(this.props.barq);
        event.preventDefault();
    }

    onSelectionChanged(event) {
        const target = event.target;
        const value = target.checked;
        this.props.onSelectionChanged(this.props.barq, value);
        event.stopPropagation();
        return false;
    }

    render() {
        const barq = this.props.barq;
        const location = barq.location;

        const useColorClasses = false && this.props.index != null;
        let colorClass = "HighlightBackground";
        if (useColorClasses) {
            const random = 1 + this.props.index % 12;
            colorClass = "BackgroundHighlight" + random;
        }

        let sizeClass= this.props.sizeClass || "BarqItemTileSize";
        let link = '/barq/' + barq.barqId+"/"+barq.titleUrl+ (this.props.sid ? "#sid="+this.props.sid : "")

        return <div className={"BarqItemTile ThemeSolidBackground ShadowBottom "+sizeClass} style={{marginBottom: "20px"}}>
            <Link to={link}>


                <div className="BarqItemTileImage">
                    <div className="BarqItemTileImageOverlayParent">
                        <div className="BarqboardBarqOverlay BarqItemTileImageOverlay"
                             style={{backgroundImage: "url('/barqme-no-image.png')"}}/>

                        <div className="BarqboardBarqOverlay BarqItemTileImageOverlay"
                             style={{backgroundImage: `url(${barq.imageUrl || '/barqme-no-image.png'})`}}/>

                        {barq.fullBadge && <div className="BarqItemTileImageOverlay fullBadge"
                                                style={{backgroundImage: `url(${barq.fullBadge.imageUrl || ''})`}}/>}


                    </div>
                    <div className="BarqItemTileImageOverlayParent">
                        <BarqBadges badges={barq.badges} extraCss="BarqBadgesGrandeSize"/>
                    </div>

                </div>
                <div className="BarqItemTileImageDetails">
                    <div className="BarqItemTileImageTitle">
                        {barq.title}
                    </div>
                    <div className="BarqItemTileImageText">
                        <div className="BarqItemTileImageTextTime">
                            {displayBarqStartTime(barq)}
                        </div>
                        <div className="BarqItemTileImageTextLocation">
                            {location.name}
                        </div>
                        <div className="BarqItemTileImageTextTimeToGo">
                            <TimeToGoString barq={barq} extraCss="HighlightColor"/>
                        </div>
                    </div>

                </div>

            </Link>
        </div>
    }
}


class BarqItemTileShowAll extends Component {

    render() {
        return <div className="BarqItemTile BarqItemTileShowAll ThemeSolidBackground">
            <Link to={this.props.to} className="ThemeStaticLink BarqItemTileShowAllContent">
                {this.props.children}
            </Link>
        </div>

    }
}


export {BarqItemTileShowAll};
export default BarqItemTile;