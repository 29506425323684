import React, {Component} from "react";
import ajax from "superagent";
import "./SearchTextDialog.css"
import ModernDialog from "../popup/ModernDialog";

class SearchTextDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            text: this.props.textWithoutSpices,
            spices: this.props.spices,
            textWithoutSpices: this.props.textWithoutSpices,
            suggestions: null
        };
        this.closeMe = this.closeMe.bind(this);

        this.suggestSearchText = this.suggestSearchText.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.onSelectText = this.onSelectText.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentDidMount() {
    }

    suggestSearchText() {
        let searchText = this.state.textWithoutSpices;
        if (this.state.spiceValues?.length > 0) {
            searchText = searchText + " " + this.state.spiceValues.map(s => s.tag).join(' ');
        }
        ajax
            .post(this.props.app.server_url + '/search/searchRelatedTags')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "text": searchText})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({suggestions: res.body.relatedSearches});
                console.log(res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onTextChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value}, (e) => {
            if (this.state.textWithoutSpices.length >= 3) {
                this.suggestSearchText()
            } else {
                this.setState({suggestions: null})
            }
        });
    }

    onSelectText(text) {
        this.setState({textWithoutSpices: text+" "}, (e) => {
            if (this.state.textWithoutSpices.length >= 3) {
                this.suggestSearchText()
                this.textInput.focus()
            } else {
                this.setState({suggestions: null})
            }
        });
    }

    submitSearch() {
        this.props.searchTextChanged(this.state.textWithoutSpices);
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.submitSearch();
        }
    }

    closeMe() {
        this.props.onClose();
    }

    render() {

        if (this.props.display) {
            const suggestions = this.state.suggestions;
            let suggestionsView = null;
            if (suggestions) {
                suggestionsView = suggestions.map((suggestion, index) => {
                        return <SuggestionElement key={'suggestion-' + index} suggestion={suggestion}
                                                  onSelect={this.props.searchTextChanged}
                                                  onSelectText={this.onSelectText}
                                                  app={this.props.app} index={index}/>
                    }
                );
            }
            return <ModernDialog title="Keresés" level={1} extraClass="ModernDialogSizeWide ThemeBackground" onClose={this.closeMe}>
                <div className="ThemeBackground SearchTextFieldContainer">
                    <div className="">
                        <input ref={(input) => { this.textInput = input; }} autoFocus={true} id="textWithoutSpices" autoComplete="false" className="SearchTextField ThemeSolidBackground" type="text" name="textWithoutSpices" value={this.state.textWithoutSpices} placeholder={"Írj be legalább 3 karaktert a kereséshez!"} onChange={this.onTextChanged} onKeyDown={this._handleKeyDown} /> <button className="redButton SearchTextButton" onClick={this.submitSearch}>OK</button>
                    </div>
                    <div className="SuggestionElementContainer">
                        {suggestionsView != null && suggestionsView}
                        {(suggestionsView == null && suggestions == null ) && <div className="SearchTextMessage">Írj be legalább 3 karaktert a kereséshez!</div>}
                        {(suggestions?.length === 0) && <div className="SearchTextMessage"><button className="barqButton NoResultSearchButton" onClick={this.submitSearch}>KERESÉS</button></div>}
                    </div>
                </div>
            </ModernDialog>
        } else {
            return null;
        }
    }
}

class SuggestionElement extends Component {

    render() {
        const suggestion = this.props.suggestion;
        return <div className="ThemeBackground SuggestionElement">
            <button className="SearchSuggestionButton ThemeBackground ThemeColor" onClick={(e) => this.props.onSelect(suggestion.text)}>{suggestion.text}</button>
            <button className="SearchCompleteButton ThemeBackground ThemeColor SearchPutText" onClick={(e) => this.props.onSelectText(suggestion.text)}/>
        </div>
    }


}

export default SearchTextDialog;