import React, {Component} from "react";
import ajax from "superagent";
import {Link} from "react-router-dom";
import "./SpicesDialog.css"
import ModernDialog from "../popup/ModernDialog";

class SelectSpiceDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {spices: null, filter: ""};
        this.closeMe = this.closeMe.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
    }

    closeMe() {
        this.props.oncClose();
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }

    render() {

        if (this.props.display) {
            const filter = this.state.filter;
            const spice = this.props.spice;
            const spices = spice.spices;
            let spicesView = "";
            if (spices) {
                spicesView = spices.map((spice, index) => {
                        if (filter.length == 0 || spice.tag.includes(filter) || spice.display.includes(filter)) {
                            return <SpiceElement key={'spice-value-' + index} spice={spice}
                                                 onSelect={this.props.onSelect} onSearch={this.props.onSearch}
                                                 app={this.props.app} index={index}/>
                        }
                    }
                );
            }
            return <ModernDialog title={spice.title} level={2} onClose={this.closeMe}
                                 extraClass="SelectSpiceDialogSize ThemeBackground">
                <div className="SearchFilter ThemeSolidBackground">
                    <input name="filter" value={this.state.filter} onChange={this.onPropertyChanged} placeholder="Szűrés..."/>
                </div>
                {spicesView}
            </ModernDialog>
        } else {
            return "";
        }
    }
}

class SpiceElement extends Component {

    render() {
        const spice = this.props.spice;
        return <div>
            <button onClick={(e) => this.props.onSelect(spice)}
                    className="SpiceButtonValue ThemeBackground ThemeBorder">{spice.display} ({spice.actionInfoSummary.numberOfBarqs} db)
            </button>
            <button onClick={(e) => this.props.onSearch(spice)}
                    className="SpiceButtonExtra SpiceButtonSearch ThemeBackground ThemeBorder">&nbsp;</button>
            <div className={"SpiceButtonSeparator ThemeBackground"}/>
        </div>
    }


}

export default SelectSpiceDialog;