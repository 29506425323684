import React, {Component} from "react";
import {Link} from "react-router-dom";

class ActionManager extends Component {

    render() {
        const action = this.props.action;
        if (action != null) {
            if (action.startsWith("article:")) {
                const article = action.substring(8);
                return <Link to={'/page/' + article} className={this.props.className}>{this.props.children}</Link>;
            } else if (action.startsWith("web:")) {
                const link = action.substring(4);
                return <a href={link} className={this.props.className}>{this.props.children}</a>;
            } else if (action.startsWith("search:") || action.startsWith("SEARCH:")) {
                const text = action.substring(7);
                return <Link to={'/search/SEARCH:' + text} className={this.props.className}>{this.props.children}</Link>;
            } else if (action.startsWith("id:") || action.startsWith("ID:")) {
                const text = action.substring(3);
                return <Link to={'/search/id:' + text} className={this.props.className}>{this.props.children}</Link>;

            } else if (!this.props.failOnEmpty) {
                return <div className={this.props.className}>{this.props.children}</div>
            }
        }
        return null;
    }

}

export default ActionManager;