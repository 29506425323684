import {Component} from 'react';

import Hungarian from "./hu.json";
import English from "./en.json";
import {ambiguousTime} from "../barq/TimeToGoCalculator";
import moment from "moment-timezone";

class TranslateMessage extends Component {

    render() {
        const id = this.props.id;
        const defaultMessage = this.props.defaultMessage;
        const data = this.props.data;
        return translateMessage(id, defaultMessage, data)
    }
}

export function translateMessage(id, defaultMessage, data) {
    const lang = window.barqLang;
    let dictionary = Hungarian;
    if (lang === "en")
        dictionary = English;
    const value = dictionary[id];
    if (value) {
        let text = "" + value;
        //data is a map
        if (data != null) {
            Object.keys(data).map(function (keyName, keyIndex) {
                text = text.replaceAll("%" + keyName, data[keyName])
                return null;
            });
        }

        return text; //"!" + text + "!";
    } else if (defaultMessage)
        return "+" + defaultMessage + "+";
    else
        return "<<" + id + ">>"

}

export function displayTime(timestamp) {
    const month = translateMonth(timestamp);
    const day = moment(timestamp).tz("Europe/Budapest").format("D")
    const time = moment(timestamp).tz("Europe/Budapest").format("HH:mm")
    return month + " " + day + ", " + time;
}


export function displayBarqStartTime(barqObj) {
    const ambTime = ambiguousTime(barqObj);
    const month = translateMonth(barqObj.startDateStamp);
    const day = moment(barqObj.startDateStamp).tz("Europe/Budapest").format("D")
    const time = moment(barqObj.startDateStamp).tz("Europe/Budapest").format("HH:mm")
    if (ambTime) {
        return month + " " + day + ".";
    } else {
        return month + " " + day + ", " + time;
    }
}

export function translateMonth(timeStamp) {
    const date = new Date(timeStamp)
    const monthIndex = date.getMonth()+1
    if (monthIndex === 1) return translateMessage("date_month_long_jan")
    else if (monthIndex === 2) return translateMessage("date_month_long_feb")
    else if (monthIndex === 3) return translateMessage("date_month_long_mar")
    else if (monthIndex === 4) return translateMessage("date_month_long_apr")
    else if (monthIndex === 5) return translateMessage("date_month_long_may")
    else if (monthIndex === 6) return translateMessage("date_month_long_jun")
    else if (monthIndex === 7) return translateMessage("date_month_long_jul")
    else if (monthIndex === 8) return translateMessage("date_month_long_aug")
    else if (monthIndex === 9) return translateMessage("date_month_long_sep")
    else if (monthIndex === 10) return translateMessage("date_month_long_okt")
    else if (monthIndex === 11) return translateMessage("date_month_long_nov")
    else if (monthIndex === 12) return translateMessage("date_month_long_dec")
}


export function translateShortMonth(timeStamp) {
    const date = new Date(timeStamp)
    const monthIndex = date.getMonth()
    if (monthIndex === 1) return translateMessage("date_month_short_jan")
    else if (monthIndex === 2) return translateMessage("date_month_short_feb")
    else if (monthIndex === 3) return translateMessage("date_month_short_mar")
    else if (monthIndex === 4) return translateMessage("date_month_short_apr")
    else if (monthIndex === 5) return translateMessage("date_month_short_may")
    else if (monthIndex === 6) return translateMessage("date_month_short_jun")
    else if (monthIndex === 7) return translateMessage("date_month_short_jul")
    else if (monthIndex === 8) return translateMessage("date_month_short_aug")
    else if (monthIndex === 9) return translateMessage("date_month_short_sep")
    else if (monthIndex === 10) return translateMessage("date_month_short_okt")
    else if (monthIndex === 11) return translateMessage("date_month_short_nov")
    else if (monthIndex === 12) return translateMessage("date_month_short_dec")
}

export {TranslateMessage};