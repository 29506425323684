import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import {Padding} from "../../components/Components";
import ajax from "superagent";
import PageArticleEditor from "../../article/ArticleEditor";
import {PromoterTabFooter, PromoterTabTitle} from "../EditorComponent";
import {MediaSelectorComponent} from "../media/MediaEditor";
import Cancellations from "./Cancellations";

class LocationData extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {promoter: this.props.promoter};
        this.updateArticle = this.updateArticle.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
        this.save = this.save.bind(this);
    }

    save(event) {
        const promoter = this.state.promoter;
        console.log(promoter.location);
        ajax
            .post(this.props.app.server_url + '/editor/location/save')
            .query({...{"platform": "react"}})
            .send(promoter)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({promoter: res.body});
                this.props.onPromoterChange(res.body);
                alert("MENTVE!")
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
        event.preventDefault();
    }

    updateArticle(article) {
        const promoter = this.state.promoter;
        promoter.location.article = article;
        this.setState({promoter: promoter})
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const location = {...this.state.promoter.location, ...{[name]: value}}
        this.setState({promoter: {...this.state.promoter, ...{location: location}}});
    }


    render() {
        const location = this.state.promoter.location;
        return <div>
            <Helmet>
                <title>barq.me | {location.name}</title>
                <meta name="description" lang="hu" content="Promóter oldal"/>
            </Helmet>
            <PromoterTabTitle title={"Helyszín: "+ (location.name || "Névtelen")}/>

            {!this.props.editorMode && <div>
                {location.imageDoubleHeaderUrl && <div className="PromoterImageDoubleHeader" style={{backgroundImage: 'url(' + location.imageDoubleHeaderUrl + ")"}}>
                </div>}
                {location.imageHeaderUrl && <div className="PromoterImageHeader" style={{backgroundImage: 'url(' + location.imageHeaderUrl + ")"}}>
                </div>}


                <div style={{clear: 'both'}}/>

                {location.imageUrl1 && <img className="LocationImage" src={location.imageUrl1} alt={location.name}/>}
                {location.imageUrl2 && <img className="LocationImage" src={location.imageUrl2} alt={location.name}/>}
                {location.imageUrl3 && <img className="LocationImage" src={location.imageUrl3} alt={location.name}/>}
                {location.imageUrl4 && <img className="LocationImage" src={location.imageUrl4} alt={location.name}/>}

                <Padding>
                    <div style={{clear: 'both'}}/>

                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Elérhetőség:</div>
                        <div className="DisplayRowValue">{location.postalCode}, {location.city} {location.address}</div>
                    </div>
                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">GPS:</div>
                        <div className="DisplayRowValue">({location.latitude}, {location.longitude})</div>
                    </div>


                    <div style={{clear: 'both'}}/>


                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Weboldal:</div>
                        <div className="DisplayRowValue">{location.web || "----"}</div>
                    </div>

                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Facebook:</div>
                        <div className="DisplayRowValue">{location.facebook || "----"}</div>
                    </div>

                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Telefonszám:</div>
                        <div className="DisplayRowValue">{location.phone || "----"}</div>
                    </div>
                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Email:</div>
                        <div className="DisplayRowValue">{location.email || "----"}</div>
                    </div>
                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Google webcím:</div>
                        <div className="DisplayRowValue">{location.googleUrl || "----"}</div>
                    </div>
                    <div className="DisplayRow ThemeBackground">
                        <div className="DisplayRowKey">Google Helyek azonosító:</div>
                        <div className="DisplayRowValue">{location.googlePlaceId || "----"}</div>
                    </div>

                </Padding>
            </div>}


            <Padding>
                {this.props.editorMode && <div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_name">Megnevezés</label> <input
                        id="form_name" type="text" name="name"
                        value={location.name || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div style={{clear: 'both'}}/>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (desktop):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderDesktop"
                                                value={location.imageHeaderDesktop} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (tablet):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderTablet"
                                                value={location.imageHeaderTablet} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_header">Fejléc kép (mobile):</label>
                        <MediaSelectorComponent id="form_header" name="imageHeaderMobile"
                                                value={location.imageHeaderMobile} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_title_on_offer">Cím megjelenítése ajánlásoknál:</label>

                        <select id="form_title_on_offer" name="titleOnOffer" value={location.titleOnOffer} onChange={this.onPropertyChanged}>
                            <option value={false}>Nem</option>
                            <option value={true}>Igen</option>
                        </select>
                    </div>
                    <div style={{clear: 'both'}}/>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_image1">Kép 1:</label>
                        <MediaSelectorComponent id="form_image1" name="imageUrl1" value={location.imageUrl1} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_image2">Kép 2:</label>
                        <MediaSelectorComponent id="form_image2" name="imageUrl2" value={location.imageUrl2} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_image3">Kép 3:</label>
                        <MediaSelectorComponent id="form_image3" name="imageUrl3" value={location.imageUrl3} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_image4">Kép 4:</label>
                        <MediaSelectorComponent id="form_image4" name="imageUrl4" value={location.imageUrl4} onChange={this.onPropertyChanged}
                                                app={this.props.app} user={this.props.user}/>
                    </div>
                    <div style={{clear: 'both'}}/>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_zip">Irányítószám:</label> <input
                        id="form_zip" type="text" name="postalCode"
                        value={location.postalCode || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_city">Város:</label> <input
                        id="form_city" type="text" name="city"
                        value={location.city || ""} onChange={this.onPropertyChanged}/>
                    </div>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_address">Cím:</label> <input
                        id="form_address" type="text" name="address"
                        value={location.address || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_latitude">GPS Latitude:</label> <input
                        id="form_latitude" type="text" name="latitude"
                        value={location.latitude || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_longitude">GPS Longitude:</label> <input
                        id="form_longitude" type="text" name="longitude"
                        value={location.longitude || ""} onChange={this.onPropertyChanged}/>
                    </div>

                    <div style={{clear: 'both'}}/>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_web">Weboldal:</label> <input
                        id="form_web" type="text" name="web"
                        value={location.web || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_facebook">Facebook:</label> <input
                        id="form_facebook" type="text" name="facebook"
                        value={location.facebook || ""} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_phone">Telefonszám:</label> <input
                        id="form_phone" type="text" name="phone"
                        value={location.phone} onChange={this.onPropertyChanged}/>
                    </div>
                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_email">Email:</label> <input
                        id="form_email" type="text" name="email"
                        value={location.email || ""} onChange={this.onPropertyChanged}/>
                    </div>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_google_url">Google webcím:</label> <input
                        id="form_google_url" type="text" name="googleUrl"
                        value={location.googleUrl || ""} onChange={this.onPropertyChanged}/>
                    </div>

                    <div className="EditorRow ThemeBackground">
                        <label htmlFor="form_google_place_id">Google Helyek azonosító:</label> <input
                        id="form_google_place_id" type="text" name="googlePlaceId"
                        value={location.googlePlaceId || ""} onChange={this.onPropertyChanged}/>
                    </div>


                    <Cancellations app={this.props.app} user={this.props.user} location={location}/>
                </div>}


            </Padding>

            <PageArticleEditor article={location.article} onArticleChanged={this.updateArticle}
                               app={this.props.app} user={this.props.user} editorMode={this.props.editorMode}
                               hideArticleData={true}/>
            <Padding>
                <PromoterTabFooter onSave={this.save} editorMode={this.props.editorMode}/>
            </Padding>

        </div>
    }
}

export default LocationData;