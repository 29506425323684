import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import Container from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";

import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import './PageAbout.css';

class PageAbout extends Component {


    constructor(props, context) {
        super(props, context);
    }


    render() {

        const serverType = this.props.app.server_type;

        const serverVersion = this.props.user.version || "----"
        const serverBuild = this.props.user.build || "----"
        return <div>
            <Helmet>
                <title>barq.me | Rólunk</title>
                <meta name="description" lang="hu" content={'barq.me rólunk oldala'}/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <div className="PageAboutContent">
                    <div className="PageAboutLogo"/>
                    <h1 className="PageAboutTitle">Barq.me</h1>
                    <p className="PageAboutText">
                        webpage version {this.props.app.version}<br/>
                        build: {this.props.app.build}
                    </p>
                    <p className="PageAboutText">
                        SERVER<br/>
                        {serverType} {serverVersion}<br/>
                        build {serverBuild}<br/>
                    </p>
                </div>
            </Container>
        </div>
    }
}

export default PageAbout;