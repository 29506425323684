import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./BarqItemList.css";
import BarqItemCalendarOrMore from "./BarqItemCalendar";
import TimeToGoString from "./TimeToGoCalculator";
import BarqBadges from "./BarqBadges";
import {displayBarqStartTime} from "../i18n/TranslateMessage";

var moment = require('moment-timezone');

class BarqItemList extends Component {

    constructor(props, context) {
        super(props, context);
        this.editBarq = this.editBarq.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    editBarq(event) {
        this.props.onEditBarq(this.props.barq);
        event.preventDefault();
    }

    onSelectionChanged(event) {
        const target = event.target;
        const value = target.checked;
        this.props.onSelectionChanged(this.props.barq, value);
        event.stopPropagation();
        return false;
    }

    render() {
        const barq = this.props.barq;
        const location = barq.location;

        let link = '/barq/' + barq.barqId +"/"+barq.titleUrl+ (this.props.sid ? "#sid="+this.props.sid : "")
        return <div
            className={"BarqsSectionItemSmall " + ((this.props.index % 2 === 1) ? "ThemeBackground" : "ThemeBackground")}>
            <Link to={link}>
                <div className="image HighlightBackground">

                    <div className="BarqboardBarqOverlay BarqboardBarqOverlayImage"
                         style={{backgroundImage: "url('/barqme-no-image.png')"}}>
                        <div className="BarqboardBarqOverlay BarqboardBarqOverlayImage"
                             style={{backgroundImage: `url(${barq.imageUrl || '/barqme-no-image.png'})`}}/>

                    </div>


                    {barq.fullBadge && <div className="fullBadge"
                                            style={{backgroundImage: `url(${barq.fullBadge.imageUrl || ''})`}}></div>}
                </div>
                <div className="BarqData">
                    <div className="BarqTitle">
                        <div className="text ThemeColor"> {barq.title}</div>
                    </div>
                    <div className="BarqDetails">
                        <div className="text ThemeColor">
                            {displayBarqStartTime(barq)} {location.name}
                        </div>
                        <div className="text TimeToGoString ThemeColor">
                            <TimeToGoString barq={barq} extraCss="HighlightColor"/>
                        </div>
                        <div className="text ThemeColor">
                            <BarqBadges badges={barq.badges} extraCss="BarqBadgesListSize"/>
                        </div>

                    </div>
                </div>
                <BarqItemCalendarOrMore barq={barq}/>
            </Link>
        </div>
    }


}

class BarqItemListShowAll extends Component {
    render() {
        return <div
            className="ThemeSolidBackground ThemeColor ArticleParagraphSearchEmpty BarqItemListShowAll">
            <Link to={this.props.to}>{this.props.children}</Link></div>

    }
}

export default BarqItemList;
export {BarqItemListShowAll};