import React, {Component} from 'react';
import Container, {Padding} from "../components/Components";
import Sidebar, {SidebarBlock} from "../sidebar/Sidebar";
import {Link, Redirect} from "react-router-dom";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import ajax from "superagent";
import "./MyInterests.css";
import {toast, ToastContainer} from "react-toastify";
import PubSub from "pubsub-js";

class PageMyInterests extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {subscriptions: null, dragId: null}
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.rename = this.rename.bind(this);
    }

    componentDidMount() {
        this.downloadInterests();
    }

    downloadInterests() {
        ajax
            .post(this.props.app.server_url + '/subscription/list')
            .query({"platform": "react",
                "lang": this.props.app.lang,
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {

                const subscriptions = res.body.subscriptions.map((subscription, index) => {
                    return subscription;
                })

                this.setState({subscriptions: subscriptions});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    saveOrder() {
        const order = this.state.subscriptions.map((subscribtion)=> {
            return subscribtion.code;
        }).join(",");

        ajax
            .post(this.props.app.server_url + '/section/saveOrder')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                order: order})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Az új sorrend elmentve!", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }



    handleDrag(event) {
        const id = event.currentTarget.id;
        console.log("drag id: " + id);
        this.setState({dragId: id});
    }

    handleDrop(event) {
        let subscriptions = [...this.state.subscriptions];

        const dragSubscriptionIndex = subscriptions.findIndex((subscription) => parseInt(subscription.subscriptionId) === parseInt(this.state.dragId));
        const dragSubscription = subscriptions[dragSubscriptionIndex];
        subscriptions.splice(dragSubscriptionIndex, 1);

        const dropSubscriptionIndex = subscriptions.findIndex((subscription) => parseInt(subscription.subscriptionId) === parseInt(event.currentTarget.id));


        console.log("Replace " + dragSubscriptionIndex + " with " + dropSubscriptionIndex + " yo: "+event.currentTarget.id);


        subscriptions = [...subscriptions.slice(0, dropSubscriptionIndex), dragSubscription, ...subscriptions.slice(dropSubscriptionIndex)];


        this.setState({subscriptions: subscriptions, dragId: null}, (e)=> {
            this.saveOrder();
        });


    }

    unsubscribe(subscription) {
        if (window.confirm('Biztonsan le szeretnél iratkozni erről a tartalomról:\r\n'+subscription.name+"?")) {

            ajax
                .post(this.props.app.server_url + '/subscription/unsubscribe')
                .query({"platform": "react",
                    "lang": this.props.app.lang,
                    text: subscription.text})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    toast("Sikeresen leiratkoztál!", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    this.downloadInterests();
                    PubSub.publish("SUBSCRIPTION_UNSUBSCRIBED", res.body);
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });



        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    rename(subscription) {
        var name = prompt('Ha nem tetszik a renszer által adott név, itt átnevezheted:', subscription.name);
        if (name!=null) {
            ajax
                .post(this.props.app.server_url + '/subscription/rename')
                .query({"platform": "react",
                    "lang": this.props.app.lang,
                    text: "id:"+subscription.code, name: name})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    toast("Sikeresen átnevezted az érdeklüdésedet!", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    this.downloadInterests();
                    PubSub.publish("SUBSCRIPTION_UNSUBSCRIBED", res.body);
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });

        }
    }

    render() {
        const subscriptions = this.state.subscriptions;
        let interestsView = null;
        if (subscriptions)
            interestsView = subscriptions.map((subscription) => {
                return <MyInterestItem key={"myinterest" + subscription.subscriptionId} subscription={subscription}
                                       handleDrag={this.handleDrag} handleDrop={this.handleDrop}
                                       onUnsubscribe={this.unsubscribe}
                                       onRename={this.rename}
                                       app={this.props.app} user={this.props.user}/>
            });

        return <div>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>

                <SidebarBlock title="Linkek">
                    <ul>
                        <li><Link to="/my-profile">Profilom</Link></li>
                    </ul>
                </SidebarBlock>

            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <Padding>
                    <h1 className=""><Link className="ColorLink" to={"/my-profile"}>Profilom</Link> &gt; Feliratkozásaim</h1>
                    <p>Itt átrendezheted, valamint kitörölheted a korábban elmentett érdeklődéseidet.</p>
                    {subscriptions && <div className="MyInterestItemContainer">{interestsView}</div>}
                </Padding>
                <ToastContainer />

            </Container>
        </div>
    }
}

const MyInterestItem = ({subscription, handleDrag, handleDrop, onUnsubscribe, onRename}) => {
    return <div className={"MyInterestItem ThemeBackground"}
                id={subscription.subscriptionId}
                draggable={true}
                onDragOver={(ev) => ev.preventDefault()}
                onDragStart={handleDrag}
                onDrop={handleDrop}>

        <div className="MyInterestItemTitle">
            {subscription.name}
        </div>
        <div className="MyInterestItemButtons">
            <button className="ThemeSolidBackground MyInterestItemUnsubscribeButton ButtonRename" onClick={(e)=> onRename(subscription)}/>
            <button className="ThemeSolidBackground MyInterestItemUnsubscribeButton ButtonDelete" onClick={(e)=> onUnsubscribe(subscription)}/>
        </div>


    </div>
}


export default PageMyInterests;