import {translateMessage} from "../i18n/TranslateMessage";
import React, {Component} from "react";



export function ambiguousTime(barq) {
    return ambiguousBadge(barq)
}


export function ambiguousBadge(barq) {
    let ambBadge =null;
    if (barq.fullBadge?.special === "ambigous_time") {
        ambBadge = barq.fullBadge;
    } else {
        barq.badges.map((badge) => {
            if (badge.special === "ambigous_time") {
                ambBadge = badge
            }
        })

    }
    return ambBadge;
}


class TimeToGoString extends Component {

    render() {
        const barq = this.props.barq;
        const ambBadge = ambiguousBadge(barq)
        let extraCss = "";
        if (this.props.extraCss)
            extraCss = this.props.extraCss;

        let timeToGoString = null;
        if (barq.multipleEventCounter > 1) {
            const multiple =
                <span>{translateMessage("barq_multiple_events").replace("%u", "" + (barq.multipleEventCounter - 1))}</span>
            timeToGoString = <span className={extraCss}>{multiple}</span>
        } else if (ambBadge) {
            timeToGoString = <span className={extraCss}><b>{ambBadge.info}</b></span>
        } else if (barq.fullBadge) {
            timeToGoString = <span className={extraCss}>{barq.fullBadge.info}</span>
        } else {
            timeToGoString = <span>{timeToGo(barq.startDateStamp)}</span>
        }
        return timeToGoString;
    }
}


export function timeToGo(date) {
    const now = Date.now();
    let diff = Math.abs(now - date)


    // alert("now: "+ now+"  date: "+date+" diff: " + diff);

    const _sec = 1000
    const _min = 60 * _sec
    const _hour = 60 * _min
    const _day = 24 * _hour

    const daysRemain = Math.floor(diff / _day)
    diff -= daysRemain * _day

    const hourRemain = Math.floor(diff / _hour)
    diff -= hourRemain * _hour

    const minRemain = Math.floor(diff / _min)
    diff -= minRemain * _min

    const secRemain = Math.floor(diff / _sec)
    diff -= secRemain * _sec

    if (daysRemain > 0) {
        if (hourRemain > 0)
            return "" + daysRemain + "nap" + hourRemain + "óra múlva";
        else
            return "" + daysRemain + "nap múlva";
    } else if (hourRemain > 0) {
        if (minRemain > 0)
            return "" + hourRemain + "óra" + minRemain + "p múlva";
        else
            return "" + hourRemain + "óra múlva";
    } else if (minRemain > 0) {
        return "" + minRemain + "perc múlva";
    } else if (secRemain > 0) {
        return "" + secRemain + "mp múlva";
    } else {
        return translateMessage("text_past_time")
    }

}

export default TimeToGoString;