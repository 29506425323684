import React, {Component} from "react";
import Container, {ScrollToTop} from "../components/Components";
import {Helmet} from "react-helmet";
import BarqMeCookies from "../BarqMeCookies";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import BarqMeTopbar from "../topbar/Topbar";
import "./Content.css";
import ContentTabbed from "./ContentTabbed";
import SearchPanel from "../search/SearchPanel";
import {DownloadArticle} from "../article/Article";
import ContentTabbedPaged from "./ContentTabbedPaged";

class PageContent extends Component {


    constructor(props, context) {
        super(props, context);
        const q = this.props.match.params.q;
        const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
        const sectionText = ((q != null && q.startsWith('id:')) && q.substring(3)) || '';
        this.state = {q: q, searchText: searchText, section: sectionText}
        this.updateSearch = this.updateSearch.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const q = this.props.match.params.q;
        if (this.state.q == null || prevProps.match.params.q !== q) {
            this.updateSearch(q);
        }
    }

    updateSearch(q) {
        const searchText = ((q != null && q.startsWith('SEARCH:')) && q.substring(7)) || '';
        const sectionText = ((q != null && q.startsWith('id:')) && q.substring(3)) || '';
        this.setState({q: q, searchText: searchText, section: sectionText});
    };

    render() {
        const q = this.state.q;
        return <div>
            <Helmet>
                {this.state.cont != null && <title>barq.me | Keresési eredmény</title>}
                {q != null && <title>barq.me | Keresési eredmény</title>}
                {q == null && <title>barq.me | Keresés</title>}
                <meta name="description" lang="hu"
                      content="barq.me kereső. Használd a keresőt, hogy eseményeket keress!. A barq.me célja segítséget nyújtani neked olyan szabaidős programokat találni, amikről amúgy könnyen lemaradnál vagy elfelejtenéd őket. Események összegyűjtése, Érdekes események ajánlása, Események böngészése, Ha megtaláltad, ne is felejtsd el, Közös program barátokkal"/>
            </Helmet>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>
                <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home} hideSearch={true}
                          search={this.state.searchText}/>
            <Container app={this.props.app} user={this.props.user}>
                {q != null && <div className="SearchBarqs">
                    <ContentTabbedPaged query={q} onSearchTextChanged={this.updateSearch} app={this.props.app}
                                   user={this.props.user} {...this.props}/>
                </div>}
                {q == null && <div>
                    <SearchPanel mode={"full"} form={{text:""}} app={this.props.app} user={this.props.user} onSearchTextChanged={this.updateSearch} recommendations={true}/>
                    <DownloadArticle app={this.props.app} user={this.props.user} article="search-fragment"/>

                </div>}
                <ScrollToTop app={this.props.app}/>
            </Container>
        </div>
    }

}

export default PageContent;
