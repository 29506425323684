import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import "./ArticleParagraphButton.css";
import {Link} from "react-router-dom";

const PubSub = require('pubsub-js');

class ArticleParagraphTabs extends Component {


    render() {
        const paragraph = this.props.paragraph;
        const tabViews = paragraph.tabs.map((tab, i) => {
            return <ContentTab key={"contentTab"+i} title={tab} tab={i} selected={this.props.tabSelected === i}
                               switchTab={this.props.switchTab}/>
        })

        return <ContentTabContainer>{tabViews}</ContentTabContainer>
    }

}

class ContentTabContainer extends Component {

    render() {
        return <div className="ContentTabContainer">
            {this.props.children}
        </div>
    }

}

class ContentTab extends Component {

    render() {
        const selected = this.props.selected;
        let classes = "ContentTab ContentTabNotSelected";
        if (selected) classes = "ContentTab ContentTabSelected";
        return <span className={classes} onClick={(e)=>this.props.switchTab(this.props.tab)}>{this.props.title}</span>
    }
}


export default ArticleParagraphTabs;