import React, {Component} from "react";
import ajax from "superagent";

class BarqBatchUpload extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    componentDidMount() {

    }


    handleFileUpload(event) {
        var formData = new FormData();
        var files = document.getElementById("uploadFile").files;
        formData.append("file", files[0]);

        ajax
            .post(this.props.app.server_url + '/editor/barq_admin/import_tsv')
            .query({"platform": "react"})
            .send(formData)
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                console.log(res.body);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });

    }


    render() {
        return <div className="BarqBatchUpload">
            <div className="UploadFile ThemeBackground">
                Kattintson a gombra egy .tsv állomány feltöltéséhez!<br/>
                <input id="uploadFile" type="file" onChange={this.handleFileUpload}/>
            </div>
        </div>
    }
}

export default BarqBatchUpload;