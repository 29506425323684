import React, {Component} from "react";
import ajax from "superagent";
import "./SelectCityDialog.css"
import ModernDialog from "../popup/ModernDialog";

class SelectCityDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {countries: null, message: null};
        this.closeMe = this.closeMe.bind(this);
    }

    componentDidMount() {
        this.downloadData();
    }

    downloadData() {
        ajax
            .post(this.props.app.server_url + '/city/countries')
            .query({"platform": "react", "lang": this.props.app.lang})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({countries: res.body.countries, message: res.body.message});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    closeMe() {
        this.props.onClose();
    }

    render() {

        if (this.props.display) {
            const countries = this.state.countries;
            let countriesView = "";
            if (countries) {
                countriesView = countries.map((country, index) => {
                        return <CountryElement key={'country-' + index} country={country}
                                               onSelect={this.props.selectCity}
                                               app={this.props.app} index={index}/>
                    }
                );
            }
            return <ModernDialog title="Válassz várost" level={1} extraClass="ModernDialogSizeWide"
                                 buttonText={"Mindenhol"} buttonAction={(e) => this.props.selectCity(null)}
                                 onClose={this.closeMe}>
                <div className="ThemeBackground">
                    {this.state.message && <div className="ModernDialogInfo">{this.state.message}</div>}
                    {countriesView}
                </div>
            </ModernDialog>
        } else {
            return "";
        }
    }
}

class CountryElement extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        const country = this.props.country;

        const citiesView = country.cities.map((city, index) => {
                return <CityElement key={'city-' + index} city={city}
                                    onSelect={this.props.onSelect}
                                    app={this.props.app} index={index}/>
            }
        );


        return <div className="CountryElement">
            <h2>{country.name}</h2>
            <div className={" SpiceButtonSeparator"}/>
            <div>
                {citiesView}
            </div>
        </div>
    }


}


class CityElement extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        const city = this.props.city;
        return <div className="HandCursor" onClick={(e) => this.props.onSelect(city)}>
            <div className=" CityElement">
                <div className=" CityElementImage">
                    <img src={city.imageUrl}/>
                </div>
                <div className=" CityElementDetails">
                    <span className=" CityElementDetailsCityName">{city.name}</span> ({city.numberOfBarqs} esemény)
                    <p className=" ThemeSolidColor">{city.info}</p>
                </div>

            </div>
            <div className={" SpiceButtonSeparator ThemeBackground"}/>
        </div>
    }

}

export default SelectCityDialog;