import React, {Component} from "react";
import ajax from "superagent";
import {toast} from 'react-toastify';
import './BarqActions.css';

class BarqActions extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {actionInfo: this.props.barq.actionInfo};
        this.submit = this.submit.bind(this);
        this.share = this.share.bind(this);
    }

    share() {
        const shareData = {
            title: this.props.barq.title,
            text: 'Esemény megosztása',
            url: window.location
        }

        try {
            navigator.share(shareData)
        } catch(err) {

        }


    }

    submit(action) {

        let service = null;
        let message = null;
        if (action === "like") {
            service = "/barq/like";
            message = "Köszönjük a visszajelzésedet!";
        } else if (action === "dislike") {
            service = "/barq/dislike";
            message = "Köszönjük a visszajelzésedet!";
        } else if (action === "flag") {
            service = "/barq/flag";
            message = "Könyvjelző hozzáadva!";
        }


        if (service) {
            ajax
                .post(this.props.app.server_url + service)
                .query({"platform": "react",
                    "lang": this.props.app.lang,
                    id: this.props.barq.barqId})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    this.setState({actionInfo: res.body});
                    toast(message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        }
    }

    render() {
        let likeClass = "LikeOff";
        let dislikeClass = "DislikeOff";
        let flagClass = "FlagOff";

        const info = this.state.actionInfo;
        if (info.liked)
            likeClass = "LikeOn";
        if (info.disliked)
            dislikeClass = "DisLikeOn";
        if (info.flagged)
            flagClass = "FlagOn";

        const showShare = navigator.share;

        return <div className="ViewBarqActions ThemeBackground">
            <div className="Buttons">
                <button className={"Button "+likeClass} onClick={(e) => this.submit("like")}/>
                <button className={"Button "+dislikeClass} onClick={(e) => this.submit("dislike")}/>
                <button className={"Button "+flagClass} onClick={(e) => this.submit("flag")}/>
                {showShare && <button className="Button Share" onClick={this.share}/>}
            </div>
        </div>

    }


}

export default BarqActions;