import React, {Component} from "react";
import "./DateDialog.css";
import CalendarMonth from "./Calendar";
import ModernDialog from "../popup/ModernDialog";
import moment from "moment";

class DateDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {date: this.props.date};
        this.closePopup = this.closePopup.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.selectDate = this.selectDate.bind(this);

        this.applyDate = this.applyDate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.date !== prevProps.date) {
            this.setState({date: this.props.date});
        }
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    }

    closePopup() {
        this.props.closePopup();
    }

    selectDate(date) {
        this.props.selectDate(date);
    }


    applyDate() {
        this.props.selectDate(this.state.date);
    }

    onDateChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value});
    }

    render() {
        if (this.props.display) {
            return <ModernDialog title={this.props.title || "Válaszd ki a dátumot"} level={1} app={this.props.app} user={this.props.user}
                                 // buttonText={"OK"} buttonAction={this.applyDate}
                                 extraClass={"DateDialogSize ModernDialogSizeNarrow ThemeSolidBackground"}
                                 onClose={this.closePopup}>

                <div className="DateDialog ThemeBorder">
                    <CalendarMonth parentClass="CalendarWidget ThemeBackground CalendarWidgetLeft"
                                   dateSelected={this.selectDate}/>
                </div>
            </ModernDialog>
        } else {
            return null;
        }
    }
}


export default DateDialog;