import React, {Component} from "react";
import "./ErrorDialog.css";

const PubSub = require('pubsub-js');

class ErrorDialogHandler extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {showMessage: null};
        this.showMessage = this.showMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
    }

    componentDidMount() {
        PubSub.subscribe('EVENT_SHOW_MESSAGE', this.showMessage);
    }

    componentWillUnmount() {
        PubSub.unsubscribe('EVENT_SHOW_MESSAGE');
    }

    showMessage(m, message) {
        this.setState({showMessage: message});
    }

    hideMessage() {
        this.setState({showMessage: null});
    }

    render() {

        const message = this.state.showMessage;
        if (message) {
            return <ErrorDialog type={message.type} title={message.title} message={message.message}
                                onClose={this.hideMessage}/>
        } else {
            return null;
        }
    }
}

class ErrorDialog extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {visible: true}
    }


    render() {
        if (this.state.visible) {
            return <div className="ErrorDialogFullOverlay">
                <div className="ErrorDialogContainer ThemeBackground">
                    <div className="ErrorDialog SectionWidthContainer ThemeBackground ThemeColor">
                        <h1>{this.props.title}</h1>
                        <p>{this.props.message}</p>
                        <div className="CloseButtonContainer">
                            <button className="BorderButton" onClick={this.props.onClose}>Bezár</button>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return null;
        }
    }
}

export default ErrorDialogHandler;