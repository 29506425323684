import '../Barqboard.css';
import React, {Component} from "react";
import BarqMeLink from "../../components/BarqMeLink";
import {OfferTitle} from "../../components/ImageWithTitle";


class OfferGroupSection extends Component {

    componentDidMount() {
    }

    render() {
        const section = this.props.section;
        if (section != null) {
            return <div className={'Section ModernSection SectionWidthContainer InfoSection '}>
                {section.offers.map((offer, i) => {
                    return <Offer offer={offer} i={i}/>
                })}
            </div>
        } else {
            return null;
        }


    }

}

class OfferSection extends Component {

    render() {
        const section = this.props.section;
        if (section != null) {
            return <div className={'Section SectionWidthContainer InfoSection '}>
                return <Offer offer={section.offer} i={0}/>
            </div>
        } else {
            return null;
        }
    }

}

class Offer extends Component {

    render() {
        const offer = this.props.offer;
        const i = this.props.i;
        return <div key={"offerGroup" + i + "-" + offer.action}>
            <BarqMeLink action={offer.action}>
                <div className={'OfferGroupSection'} style={{
                    backgroundImage: `url(${offer.image.imageMedium.imageUrl})`
                }}>
                    <OfferTitle title={offer.title} visible={offer.showTitle}/>
                </div>
            </BarqMeLink>
        </div>

    }

}

export default OfferGroupSection;
export {OfferSection};