import '../Barqboard.css';
import React, {Component} from "react";
import SearchPanel from "../../search/SearchPanel";


class SearchSection extends Component {

    constructor(props, context) {
        super(props, context);
        this.search = this.search.bind(this);
    }


    search(search) {
        window.location = this.props.app.app_url + "/search/" + search;
    }

    render() {
        return <SearchPanel mode={"full"} section={this.props.section} app={this.props.app} user={this.props.user} embedded={true} onSearchTextChanged={this.search}/>
    }

}

export default SearchSection;