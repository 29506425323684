import '../Barqboard.css';
import React, {Component} from "react";
import ajax from "superagent";
import "./ArticlesSection.css"
import {Link} from "react-router-dom";
import SectionHeader from "./SectionHeader";
import {translateMessage} from "../../i18n/TranslateMessage";

var moment = require('moment-timezone');

class ArticlesSection extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {articles: props.section.articles};
    }

    render() {
        // const section = this.props.section;
        const articles = this.state.articles?.articles;
        if (articles != null) {
            return (
                <div className="PaddingDependsOnWidth">
                    <div
                        className="Section ModernSection SectionWidthContainer ThemeBackground">

                        <SectionHeader title={this.props.section.title} button={null} link={null}/>
                        <div className="BarqboardArticleListContainer">
                            <div className="BarqboardArticleList">
                                {articles.map((article) =>
                                    <ArticleSectionItem key={'article-' + article.code} article={article}
                                                        app={this.props.app}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div
                className="Section SectionWidthContainer BarqboardBarqListContainer BarqboardBarqListContainerLoading"/>
        }

    }
}


class ArticleSectionItem extends Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        const article = this.props.article;
        return <Link to={"/page/" + article.code}>
            <div className="ArticleSectionItem ThemeBackground">
                <div className="ArticleSectionItemImage"
                     style={{backgroundImage: 'url(' + article.imageHeaderMobile + ")"}}>&nbsp;</div>
                <div className="ArticleSectionItemTitle">
                    {article.title}
                </div>
                <div className="ArticleSectionItemExcerpt">
                    {article.excerpt}
                </div>
            </div>
        </Link>
    }


}


export default ArticlesSection;
