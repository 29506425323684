import React, {Component} from "react";
import {Helmet} from "react-helmet";
import BarqMeCookies from "../BarqMeCookies";
import Container from "../components/Components";
import {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";
import "./PageBetaAccess.css";

class PageBetaAccess extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props, context) {
        super(props, context);
        this.state = {beta_code: ""};
        this.submitForm = this.submitForm.bind(this);
        this.onPropertyChanged = this.onPropertyChanged.bind(this);
    }

    onPropertyChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[name]: value});
    }

    submitForm() {
        this.props.setupBetaCode(this.state.beta_code);
    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Béta hozzáférés</title>
                <meta name="description" lang="hu" content={'barq.me beta hozzáférés'}/>
            </Helmet>
            <BarqMeCookies/>
            <Container app={this.props.app} user={this.props.user}>
                <div className="PaddingDependsOnWidt">
                    <div className="PageBetaAccess">
                        <h1>A hozzáférés a béta oldalhoz korlátozott</h1>
                        <p>A béta oldalhoz csak a béta programban résztvevők férnek hozzá. Ha részt veszel a béta
                            programban, kérlek add meg a béta hozzáférési kódodat:</p>
                        <p>
                            <input type="text" name="beta_code" value={this.state.beta_code}
                                   onChange={this.onPropertyChanged}/>
                            <button type="submit" onClick={this.submitForm}>Beállít</button>
                        </p>
                    </div>
                </div>
            </Container>
        </div>


    }
}

export default PageBetaAccess;