import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import '../PageEditor.css';
import ajax from "superagent";
import BarqEditor from "../barq/BarqEditor";
import BarqList from "../../barq/BarqList";
import {PromoterTabTitle} from "../EditorComponent";

class LocationEvents extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {result: {barqs: null}, barq: null};
        this.editBarq = this.editBarq.bind(this)
    }

    componentDidMount() {
        this.downloadLocationEvents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    downloadLocationEvents() {
        ajax
            .post(this.props.app.server_url + '/editor/location/events')
            .query({"platform": "react"})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({result: res.body});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }


    editBarq(barq) {
        this.setState({barq: {barqId: barq.barqId}});
    }


    render() {
        const barq = this.state.barq;
        return <div>
            <Helmet>
                <title>barq.me | {this.props.promoter.location.name} események</title>
                <meta name="description" lang="hu" content="Promóter oldal"/>
            </Helmet>
            <PromoterTabTitle title="Események nálam"/>
            {(barq == null || barq.barqId == null) && <BarqList barqsResult={this.state.result}
                                                                     info="Itt találod azokat az eseményeket, amelyek a te helyszíneden kerülnek megrendezésre. Ezeket az eseményeket módosíthatod, jóváhagyhatod vagy problémásnak jelölheted."
                                                                     editorMode={this.props.editorMode}
                                                                     onEditBarq={this.editBarq}/>}

            {(barq != null && barq.barqId != null) && <div>
                <button onClick={(e) => this.editBarq({barqId: null})}>+ Lista</button>
                <BarqEditor editorMode={this.props.editorMode} app={this.props.app}
                            barq={barq}/></div>}

        </div>
    }
}

export default LocationEvents;