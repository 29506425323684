import React, {Component} from "react";

class SelectTags extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {text: ""};
        this.onSave = this.onSave.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.onCancel = this.props.onCancel.bind(this);
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onSave() {
        this.props.onSave(this.state.text);
    }

    onTextChanged(event) {
        const target = event.target;
        const value = target.value;
        this.setState({text: value})
    }


    render() {
        return <div className="InlineDialog ThemeBackground">
            <div className="HeadingTitle">Címkék megadása</div>

            <label htmlFor="form_text">Adja meg a címkéket szóközzel vagy vesszővel elválasztva:</label>
            <input id="form_text" className="DialogInput DialogFieldFull" type="text" name="text"
                   value={this.state.text} onChange={this.onTextChanged}/>
            <button className="grayButton" onClick={this.onCancel}>Mégsem</button>
            <button className="grayButton" onClick={this.onSave}>OK</button>
        </div>
    }
}

export default SelectTags;
