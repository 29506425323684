//TranslateMessage: completed
import React, {Component} from 'react';
import './Components.css';
import PubSub from "pubsub-js";
import {Link} from "react-router-dom";
import {translateMessage, TranslateMessage} from "../i18n/TranslateMessage";

class Container extends Component {


    constructor(props, context) {
        super(props, context);
        const SHOW_SIDEBAR = window.showSidebar;
        this.state = {showSidebar: SHOW_SIDEBAR};
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        PubSub.subscribe('EVENT_TOGGLE_MENU', this.toggleMenu);
    }

    componentWillUnmount() {
        PubSub.unsubscribe('EVENT_TOGGLE_MENU');
    }

    toggleMenu(msg, data) {
        if (data != null) {
            this.setState({showSidebar: data.showSidebar});
        }
    }

    render() {
        let rootClass;
        if (this.state.showSidebar)
            rootClass = "RootContainer ";
        else
            rootClass = "RootContainerNoSidebar";
        return <div className={rootClass}>
            <div id="BarqMeContentContainer"
                 className="Container ThemeBackground FullHeightContent  SectionWidthContainer">
                <div className="content">
                    {this.props.children}
                </div>
                <div className="PageFooter ThemeBackground ThemeSolidColor">
                    {/*<a href="https://twitter.com/barq_me" className="ThemeSolidLink FooterLink"><img*/}
                    {/*    src="/social/social-twitter-light.png" width="25" height="25" alt="Twitter"/></a>*/}
                    <a href="https://www.facebook.com/barq.me" className="ThemeSolidLink FooterLink"><img
                        src="/social/social-facebook-light.png"
                        width="25" height="25" alt="Facebook"/></a>
                    {/*<a href="" className="ThemeSolidLink FooterLink"><img src="/social/social-youtube-light.png"*/}
                    {/*                                                      width="25" height="25" alt="YouTube"/></a>*/}
                    <a href="https://apps.apple.com/us/app/barq-me/id1577921290"
                       className="ThemeSolidLink FooterLink"><img
                        src="/social/social-apple-light.png" width="25" height="25" alt="Apple"/></a>
                    <a href="https://play.google.com/store/apps/details?id=me.barq.android" className="ThemeSolidLink FooterLink"><img
                        src="/social/social-android-light.png" width="25" height="25" alt="Android"/></a>
                    <br/>
                    &copy; {new Date().getFullYear()} The Barq Project
                    <Link className="ThemeSolidLink FooterLink" to="/page/privacy-policy">
                        <TranslateMessage id="page_privacy_policy" defaultMessage="Adatvédelmi szabályzat"/>
                    </Link>
                    <Link className="ThemeSolidLink FooterLink" to="/page/terms-of-service">
                        <TranslateMessage id="page_terms_of_service" defaultMessage="Felhasználási feltételek"/>
                    </Link>
                    <Link className="ThemeSolidLink FooterLink" to="/contact-us">
                        <TranslateMessage id="page_contact_us" defaultMessage="Kapcsolat"/>
                    </Link>
                    <Link className="ThemeSolidLink FooterLink" to="/about">
                        Névjegy
                    </Link><br/>

                    <span className="ThemeSolidColor">
                                                <TranslateMessage id="version" defaultMessage="Verzió"/>: {this.props.app.version} build {this.props.app.build}</span>
                </div>

            </div>
        </div>
    }
}

class Padding extends Component {

    render() {
        return <div className="PaddingDependsOnWidth">
            {this.props.children}
        </div>
    }
}

class InfoMessage extends Component {

    render() {
        return <div className="InfoMessage Padding ThemeSolidBackground">
            {this.props.children}
        </div>
    }
}

class InfoMessageSmall extends Component {

    render() {
        return <div className="ViewArticleParagraph ThemeSolidBackground ThemeSolidColor ViewArticleParagraphSmall">
            {this.props.children}
        </div>
    }
}


class PaddingEditor extends Component {

    render() {
        return <div className="PaddingEditor ThemeBackground">
            {this.props.children}
        </div>
    }
}

class FullOverlay extends Component {

    render() {
        return <div className="FullOverlay">{this.props.children}</div>
    }

}

class ScrollToTop extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.scrollToTop = this.scrollToTop.bind(this);
    }


    scrollToTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        PubSub.subscribe('EVENT_TOGGLE_MENU', this.toggleMenu);
    }

    componentWillUnmount() {
        PubSub.unsubscribe('EVENT_TOGGLE_MENU');
    }


    render() {
        const lang = this.props.app.lang;
        return <button className="ScrollToTop" title={translateMessage("scroll_to_top")} onClick={this.scrollToTop}>
            <img src="/button-scroll-to-top.png" width="40" height="40" alt={translateMessage("scroll_to_top")}/>
        </button>
    }

}

export default Container;
export {Padding, PaddingEditor, FullOverlay, InfoMessage, InfoMessageSmall, ScrollToTop};