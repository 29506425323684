import React, {Component} from "react";
import ModernDialog from "../popup/ModernDialog";
import {ViewArticlePanel} from "../article/Article";
import ajax from "superagent";
import "./ShowPostDialog.css";
class ShowPostDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            post: null
        };
    }


    componentDidMount() {
        this.downloadPost();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const postCode = this.props.postCode;
        const oldCode = prevProps.postCode;
        if (postCode !== oldCode) this.downloadPost()
    }

    downloadPost() {
        if (this.props.postCode) {
            ajax
                .post(this.props.app.server_url + '/post/read')
                .query({"platform": "react",
                    "lang": this.props.app.lang,
                    "code": this.props.postCode})
                .set('Authorization', 'Bearer ' + this.props.app.access_token)
                .accept('application/json')
                .then(res => {
                    this.setState({post: res.body});
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        }
    }

    render() {
        const post = this.state.post;
        if (this.props.display && post) {
            return <ModernDialog title={post.article.title} level={1}
                                 buttonText={"Töröl"} buttonAction={(e)=> this.props.onDelete(post)}
                                 extraClass="ModernDialogSizeWide PostDialogHeight ThemeBackground" onClose={this.props.onClose}>
                <ViewArticlePanel dialogMode={true} noPadding={true} article={post.article} app={this.props.app} user={this.props.user}
                                  editorMode={false}/>
            </ModernDialog>
        } else {
            return null;
        }
    }
}

export default ShowPostDialog;