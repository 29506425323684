import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import './Article.css';
import Container, {Padding} from "../components/Components";
import Sidebar, {DefaultSidebarBlock} from "../sidebar/Sidebar";
import ajax from "superagent";

import BarqMeCookies from "../BarqMeCookies";
import {ViewArticleParagraph} from "./paragraph/ParagraphBlock";
import BarqMeTopbar from "../topbar/Topbar";
import Loading from "../components/Loading";
import ArticleParagraphFeatures from "./paragraph/ArticleParagraphFeatures";

class PageFeatures extends Component {


    constructor(props, context) {
        super(props, context);
    }


    render() {
            return <div>
                <Helmet>
                    <title>barq.me | Funkciók</title>
                    <meta name="description" lang="hu" content={'barq.me funkciók bemutatása'}/>
                </Helmet>
                <BarqMeCookies/>
                <Sidebar>
                    <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
                </Sidebar>
                <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
                <Container app={this.props.app} user={this.props.user}>
                    <ArticleParagraphFeatures/>
                </Container>
            </div>
    }
}

export default PageFeatures;