//TranslateMessage: completed
import React, {Component} from 'react';
import './Sidebar.css';
import {Link} from 'react-router-dom'
import PubSub from "pubsub-js";
import {TranslateMessage} from "../i18n/TranslateMessage";
class Sidebar extends Component {

    constructor(props, context) {
        super(props, context);
        const SHOW_SIDEBAR = window.showSidebar;
        this.setupMenu = this.setupMenu.bind(this);
        this.state = {showSidebar: SHOW_SIDEBAR, windowWidth: 0};
    }

    componentDidMount() {
        PubSub.subscribe('EVENT_TOGGLE_MENU', this.setupMenu);
    }

    componentWillUnmount() {
        PubSub.unsubscribe('EVENT_TOGGLE_MENU');
    }

    setupMenu(msg, data) {
        if (data != null) {
            this.setState({showSidebar: data.showSidebar});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        if (this.state.showSidebar) {
            return <div className="Sidebar ThemeBackground ThemeSolidColor  SidebarWidth">
                <div className="content">
                    <div className="ShowOnMobileOnly"><SidebarMenu/></div>
                    {this.props.children}
                </div>
            </div>;
        } else {
            return null;
        }
    }
}

class SidebarMenu extends Component {

    render() {
        return <SidebarBlock title="Menü"><MenuElements/></SidebarBlock>
    }

}

class MenuElements extends Component {

    render() {
        return <ul>
            <li><Link to="/page/the-barq-project"><TranslateMessage id="webmenu_introduction" defaultMessage="Bemutatkozás"/></Link></li>
            <li><Link to="/page/features"><TranslateMessage id="webmenu_functions" defaultMessage="Funkciók"/></Link></li>
            <li><Link to="/page/community"><TranslateMessage id="webmenu_community" defaultMessage="Közösség"/></Link></li>
         </ul>
    }

}


class SidebarBarqboard extends Component {
    render() {
        return <div>
            <DefaultSidebarBlock app={this.props.app} user={this.props.user}/>
        </div>
    }
}

class DefaultSidebarBlock extends Component {

    render() {
        const user = this.props.user;
        return <SidebarBlock title="Linkek">
            <ul>
                {(!user.anonym) && <li><Link to="/"><TranslateMessage id="webmenu_barqboard" defaultMessage="Barqboard"/></Link></li>}
                {(user.anonym) &&  <li><Link to="/login"><TranslateMessage id="webmenu_login" defaultMessage="Bejelentkezés"/></Link></li>}
                {(user.anonym) &&  <li><Link to="/register"><TranslateMessage id="webmenu_register" defaultMessage="Regisztáció"/></Link></li>}
                {(user.superAdmin) && <li><Link to="/editor"><TranslateMessage id="webmenu_editor" defaultMessage="Szerkesztő"/></Link></li>}
                {(!user.anonym) && <li><Link to="/my-profile"><TranslateMessage id="webmenu_mypofile" defaultMessage="Profilom"/></Link></li>}
            </ul>
        </SidebarBlock>
    }
}


class SidebarBlock extends Component {

    render() {
        return <div className="SidebarListContainer SidebarLink">
            <div className="title">{this.props.title}</div>
            {this.props.children}
        </div>
    }
}

export default Sidebar;
export {DefaultSidebarBlock, SidebarBlock, SidebarBarqboard, MenuElements};