import React, {Component} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import ActionManager from "../../action/ActionManager";
import "./ArticleParagraphMediaImages.css";



class ArticleParagraphMediaImage extends Component {


    render() {
        const paragraph = this.props.paragraph;
        return <ArticleParagraphMediaImagesView paragraph={paragraph} noPadding={this.props.noPadding}/>
    }
}

class ArticleParagraphMediaImagesView extends Component {
    render() {
        const paragraph = this.props.paragraph;
        let containerClass = "PaddingDependsOnWidth";
        let imageClass = "RoundImage";
        if (paragraph.place === "header") {
            containerClass = "";
            imageClass = "HeaderImage";
        }
        if (this.props.noPadding)
            containerClass = "";

        const imagesView = paragraph.images.map((image)=> {
                if (image.image.imageMobile || image.image.imageTablet || image.image.imageDesktop) {
                    if (image.action) {
                        return <ActionManager action={image.action} failOnEmpty={false}>
                            <div>
                                <div className={imageClass + "Container"}>
                                    <img src={image.image.imageMobile} className={imageClass + " ShowOnlySmall"}
                                         alt=""/>
                                    <img src={image.image.imageTablet} className={imageClass + " ShowOnlyMedium"} alt=""/>
                                    <img src={image.image.imageDesktop} className={imageClass + " ShowOnlyLarge"} alt=""/>
                                    {image.showTitle && <div className={imageClass + "Title"}>
                                        {image.title}
                                    </div>}
                                </div>
                            </div>
                        </ActionManager>
                    } else {
                        return <div>
                            <div className={imageClass + "Container"}>
                                <img src={image.image.imageMobile} className={imageClass + " ShowOnlySmall"} alt=""/>
                                <img src={image.image.imageTablet} className={imageClass + " ShowOnlyMedium"} alt=""/>
                                <img src={image.image.imageDesktop} className={imageClass + " ShowOnlyLarge"} alt=""/>
                                {image.showTitle && <div className={imageClass + "Title"}>
                                    {image.title}
                                </div>}
                            </div>
                        </div>

                    }


                } else {
                    return null;
                }

            });


        return <div className={"ArticleParagraphMediaImagesView "+containerClass}>
            {imagesView}
        </div>

    }
}

export default ArticleParagraphMediaImage;
