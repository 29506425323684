import React, {Component} from "react";
import './Barqboard.css';
import BarqsSection from "./sections/BarqsSectionStatic";
import InfoSection from "./sections/InfoSection";
import SubscriptionsSection from "./sections/SubscriptionsSection";
import MenuSection from "./sections/MenuSection";
import TagsSection from "./sections/TagsSection";
import ArticleSection from "./sections/ArticleSection";
import OfferGroupSection, {OfferSection} from "./sections/OfferGroupSection";
import LocationsSection from "./sections/LocationsSection";
import PostsSection from "./sections/PostsSection";
import PromotersSection from "./sections/PromotersSection";
import SearchSection from "./sections/SearchSection";
import ImageSection from "./sections/ImageSection";
import ImageSectionGroup from "./sections/ImageSectionGroup";
import ArticlesSection from "./sections/ArticlesSection";

class BarqboardSections extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        console.log(this.props.data.sections);
        const sections = this.props.data.sections.map((section) => {

            if (section.resultType === "barqs")
                return <BarqsSection key={section.code} section={section} app={this.props.app}/>;
            else if (section.resultType === "info") return <InfoSection key={section.code} section={section}
                                                                        app={this.props.app}/>;
            else if (section.resultType === "subscriptions") return <SubscriptionsSection key={section.code}
                                                                                          section={section}
                                                                                          app={this.props.app}
                                                                                          user={this.props.user}/>;
            else if (section.display === "barqboard_menu") return <MenuSection key={section.code}
                                                                               all={this.props.source === "categories"}
                                                                               section={section}
                                                                               app={this.props.app}/>;
            else if (section.code === "program_view") return <MenuSection key={section.code}
                                                                          section={section}
                                                                          app={this.props.app}/>;
            else if (section.resultType === "tags") return <TagsSection key={section.code}
                                                                        section={section}
                                                                        app={this.props.app}/>;
            else if (section.resultType === "locations") return <LocationsSection key={section.code}
                                                                                  section={section}
                                                                                  app={this.props.app}/>;
            else if (section.resultType === "offer_group") return <OfferGroupSection key={section.code}
                                                                                     section={section}
                                                                                     app={this.props.app}/>;
            else if (section.resultType === "offers") return <OfferSection key={section.code}
                                                                           section={section}
                                                                           app={this.props.app}/>;
            else if (section.resultType === "article") return <ArticleSection key={section.code}
                                                                              section={section}
                                                                              app={this.props.app}/>;
            else if (section.resultType === "articles") return <ArticlesSection key={section.code}
                                                                                section={section}
                                                                                app={this.props.app}/>;
            else if (section.resultType === "posts") return <PostsSection key={section.code}
                                                                          section={section}
                                                                          app={this.props.app}/>;
            else if (section.resultType === "promoters") return <PromotersSection key={section.code}
                                                                                  section={section}
                                                                                  app={this.props.app}/>;
            else if (section.resultType === "search") return <SearchSection key={section.code}
                                                                            section={section}
                                                                            app={this.props.app}
                                                                            user={this.props.user}/>;
            else if (section.resultType === "image") return <ImageSection key={section.code}
                                                                          section={section}
                                                                          app={this.props.app}/>;
            else if (section.resultType === "image_group") return <ImageSectionGroup key={section.code}
                                                                                     section={section}
                                                                                     app={this.props.app}/>;
            else return null
        });
        return <div>{sections}</div>;
    }
}

export default BarqboardSections;
