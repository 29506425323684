import React, {Component, Fragment} from "react";
import '../Articles.css'
import '../ArticleEditor.css'
import {Padding, PaddingEditor} from "../../components/Components";
import ParagraphBlock, {NewParagraphButtons} from "./ParagraphBlock";

const PubSub = require('pubsub-js');


class ArticleParagraphText extends Component {
    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        let paragraphView = null;
        if (paragraph.decoration === "code") {
            paragraphView = <ArticleParagraphTextCode text={paragraph.text}/>
        } else if (paragraph.decoration === "info") {
            paragraphView = <ArticleParagraphInfo paragraph={paragraph}/>;
        } else if (paragraph.decoration === "small") {
            paragraphView = <ArticleParagraphSmall paragraph={paragraph}/>;
        } else if (paragraph.decoration === "intro") {
            paragraphView = <ArticleParagraphIntro paragraph={paragraph}/>;
        } else {
            paragraphView = <ArticleParagraphTextDefault paragraph={paragraph}/>
        }

        return <ArticleParagraphTextEditMode paragraph={paragraph} editorMode={editorMode}
                                             noPadding={this.props.noPadding}
                                             addParagraph={this.props.addParagraph}
                                             moveParagraph={this.props.moveParagraph}>{paragraphView}</ArticleParagraphTextEditMode>
    }
}


class ArticleParagraphTextDefault extends Component {
    render() {
        const paragraph = this.props.paragraph;
        return <ArticleParagraphTextDefaultView paragraph={paragraph}/>
    }
}


class ArticleParagraphTextDefaultView extends Component {
    render() {
        const paragraph = this.props.paragraph;

        let sizeClass = "";
        if (paragraph.width === "col3") {
            sizeClass = "ViewArticleParagraphCol3";
        }

        let l = -5;
        if (paragraph.text) {
            return <div className={"ViewArticleParagraph ViewArticleParagraphText " + sizeClass}>
                {paragraph.text.split(/\n|\\n/).map((line, index) => {
                    if (line.includes("<") && line.includes(">")) {
                        return <div>
                            <div dangerouslySetInnerHTML={{__html: line}}/>
                        </div>
                    }
                    if (line.length >= 90 && line.length <= 250 && l + 5 < index) {
                        l = index;
                        return <div key={"paragprah-" + index}
                                    className="ViewArticleParagraph ViewArticleParagraphIntro">{line}</div>
                    } else {
                        return <p key={"paragprah-" + index}>{line}</p>
                    }
                })}
            </div>
        } else {
            return null;
        }

    }
}


class ArticleParagraphTextEditMode extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            paragraph: this.props.paragraph,
            editorMode: this.props.editorMode,
            showEditor: this.props.paragraph.newbie === true
        };
        this.state.paragraph.newbie = false;
        this.showEditor = this.showEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.addParagraph = this.addParagraph.bind(this);
        this.moveParagraph = this.moveParagraph.bind(this);
    }

    showEditor() {
        this.setState({showEditor: true});
    }

    hideEditor() {
        this.setState({showEditor: false});
    }

    save(text, decoration) {
        this.setState({paragraph: {...this.state.paragraph, ...{text: text, decoration: decoration}}}, () => {
            PubSub.publish("UPDATE_PARAGRAPH", this.state.paragraph);
            this.hideEditor();
        });
    }

    addParagraph(whereTo) {
        this.props.addParagraph(whereTo, this.state.paragraph);
    }

    moveParagraph(whereTo) {
        this.props.moveParagraph(whereTo, this.state.paragraph);
    }

    remove() {
        PubSub.publish("REMOVE_PARAGRAPH", this.state.paragraph);
        this.hideEditor();
    }

    render() {
        const paragraph = this.props.paragraph;
        const showEditor = this.state.showEditor;
        const editorMode = this.props.editorMode === true;
        if (editorMode) {
            return <PaddingEditor>
                {!showEditor && <span>{this.props.children}
                    <button onClick={this.showEditor}>...</button></span>}
                {showEditor &&
                <ArticleParagraphTextDefaultEditor text={paragraph.text} decoration={paragraph.decoration}
                                                   buttons={<NewParagraphButtons onRemove={this.remove}
                                                                                 onAddParagraph={this.addParagraph}/>}
                                                   onCancel={this.hideEditor} onSave={this.save}/>}


            </PaddingEditor>
        } else {
            if (this.props.noPadding !== true)
                return <Padding>{this.props.children}</Padding>
            else
                return <div>{this.props.children}</div>

        }
    }
}

class ArticleParagraphTextDefaultEditor extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            text: this.props.text, decorations: [
                {code: "", name: "Egyszerű szöveg"},
                {code: "info", name: "Kiemelt szöveg"},
                {code: "small", name: "Kisbetűs szöveg"},
                {code: "code", name: "Beágyazott kód"}],
            decoration: this.props.decoration || ""
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.props.onSave(this.state.text, this.state.decoration);
    }

    onTextChanged(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value})
    }

    render() {

        const decorationOptions = this.state.decorations.map((decoration) => {
            return <option key={decoration.code} value={decoration.code}>{decoration.name}</option>
        });

        return <div>
            {/*<div className="HeadingTitle">Szöveg megadása</div>*/}

            <div className="ThemeSolidBackground BlockEditorTitle">
                {/*<label htmlFor="form_decoration">Szöveg megjelenítése:</label>*/}
                <select id="form_decoration" name="decoration" value={this.state.decoration}
                        onChange={this.onTextChanged}>
                    {decorationOptions}
                </select>
            </div>
            {/*<label htmlFor="form_text">Adja meg a megjelenítendő szöveget:</label>*/}
            <textarea id="form_text" className="ArticleParagraphTextDefaultEditorTextArea" name="text"
                      onChange={this.onTextChanged}>{this.state.text}</textarea>
            <div className="ThemeSolidBackground BlockEditorTitle">
                {this.props.buttons} | <span><button className="ParagraphButton ParagraphButtonCancel"
                                                     onClick={this.onCancel}>&nbsp;</button> <button
                className="ParagraphButton ParagraphButtonOk" onClick={this.onSave}>&nbsp;</button></span>
            </div>
        </div>
    }
}


class ArticleParagraphInfo extends Component {
    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        return <ParagraphBlock editorMode={editorMode}>
            <div className="ViewArticleParagraph ViewArticleParagraphInfo">{paragraph.text}</div>
        </ParagraphBlock>
    }
}

class ArticleParagraphIntro extends Component {
    render() {
        const paragraph = this.props.paragraph;
        const editorMode = this.props.editorMode === true;
        return <ParagraphBlock editorMode={editorMode}>
            <div className="ViewArticleParagraph ViewArticleParagraphIntro">{paragraph.text}</div>
        </ParagraphBlock>
    }
}

class ArticleParagraphSmall extends Component {
    render() {
        const paragraph = this.props.paragraph;
        return <div
            className="ViewArticleParagraph ThemeBackground ThemeSolidColor ViewArticleParagraphSmall">{paragraph.text}</div>

    }
}


class ArticleParagraphTextCode extends Component {
    render() {
        const lines = this.props.text.split("\n").length;
        const editorMode = this.props.editorMode === true;
        return <ParagraphBlock editorMode={editorMode}>
        <textarea style={{height: lines * 12}}
                  className="ViewArticleParagraphTextCode ThemeBackground ThemeSolidColor" value={this.props.text}
                  readOnly={true}/>
        </ParagraphBlock>
    }
}


export default ArticleParagraphText;