import React, {Component} from "react";
import ajax from "superagent";
import {Link} from "react-router-dom";
import {
    DownloadCategoryRecommendations,
    DownloadLocationRecommendations,
    DownloadTagRecommendations
} from "./SearchRecommendations";

class SearchTags extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {search: '', offers: [], barqs: [], locations: []};
        this.onTagSubmit = this.onTagSubmit.bind(this);
        this.onTagSelect = this.onTagSelect.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.search != null && prevProps.search !== this.props.search) {
            this.setState({search: this.props.search, response: null, offers: []}, () => {
                this.downloadOffers();
            });
        }
    }


    downloadOffers() {
        if (this.state.search.length >= 3) {
            this.downloadOffersWithText(this.state.search);
        }
    }

    downloadOffersWithText(text) {
        ajax
            .post(this.props.app.server_url + '/search/'+this.props.action)
            .query({"client_build": "100", "platform": "react", "limit": 10, "text": text})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                // const log = JSON.stringify(res.body);
                // console.log(log);
                this.setState({
                    response: res.body,
                    offers: res.body.tags.related,
                    barqs: res.body.barqs,
                    locations: res.body.locations
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    onTagSubmit(text, event) {
        if (this.props.onTagSubmit)
            this.props.onTagSubmit(text)
        event.preventDefault();
    }

    onTagSelect(text, event) {
        if (this.props.onTagSelect)
            this.props.onTagSelect(text)
        event.preventDefault();
    }

    render() {
        const offers = this.state.offers;
        const barqs = this.state.barqs;
        const locations = this.state.locations;
        if (offers.length > 0 || locations.length > 0 || barqs.length > 0) {
            return <div className="SearchOffers ThemeBackground CloseOverlayOver">
                    {offers.length > 0 && <div>
                        <div className="SearchResultTitle">Címkék</div>
                        <div className="ThemeBackground">{offers.map((offer, i) =>

                            <div
                                className={"SearchResult " + ((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}
                                key={'search-offer-' + offer.tag + "-" + i}>
                                <button onClick={(e) => this.onTagSubmit(offer.tag, e)}
                                        className="SubmitOffer ThemeColor">
                                    {offer.displayText}
                                </button>
                                <div className="counter">{offer.actionInfoSummary.numberOfBarqs} db</div>
                                <button onClick={(e) => this.onTagSelect(offer.tag, e)}
                                        className="SelectOffer ThemeColor">ME
                                </button>
                            </div>
                        )}</div>
                    </div>}

                    {locations.length > 0 && <div>
                        {/*<div className="SearchResultTitle">Helyszínek</div>*/}
                        <div className=" ThemeBackground">{locations.map((location, i) =>
                            <div
                                className={"SearchResult " + ((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}
                                key={'search-location-' + location.locationId}>

                                <button onClick={(e) => this.onTagSubmit(location.tag.tag, e)}
                                        className="SubmitOffer ThemeColor">
                                    <img src={location.imageUrl} width={35} height={35}
                                         alt={location.name}/> {location.name}
                                </button>
                                <div className="counter">{location.actionInfoSummary.numberOfBarqs} db</div>
                                <button onClick={(e) => this.onTagSelect(location.tag.tag, e)}
                                        className="SelectOffer ThemeColor">ME
                                </button>
                            </div>
                        )}</div>
                    </div>}

                    {barqs.length > 0 && <div>
                        <div className="SearchResultTitle">Barqok</div>
                        <div className=" ThemeBackground">{barqs.map((barq, i) =>
                            <div
                                className={"SearchResult " + ((i % 2 === 1) ? "ThemeBackground" : "ThemeSolidBackground")}
                                key={'search-location-' + barq.barqId}>
                                <Link to={"/barq/" + barq.barqId+"/"+barq.titleUrl+"/"}>
                                    <img src={barq.imageUrl} width={35} height={35} style={{contentFit: "cover"}}
                                         alt={barq.title}/>
                                    {barq.title}
                                </Link>
                            </div>
                        )}
                            <Link className="infoButton SearchResultSize"
                                  to={"/search/SEARCH:" + "\"" + this.state.search + "\""}>Fenti események
                                listázása</Link>
                        </div>
                    </div>}
            </div>

        } else if (this.state.response == null) {
            return <div className="SearchOffers ThemeBackground">
                <div className="SearchOfferText">
                    Írj be legalább három karaktert a javaslatok megjelenítéséhez!<br/>
                </div>
                {this.props.action === "searchPlaces" && <DownloadLocationRecommendations app={this.props.app} user={this.props.user} onTagSubmit={this.onTagSubmit}/>}
                {this.props.action === "searchCategories" && <DownloadCategoryRecommendations app={this.props.app} user={this.props.user} onTagSubmit={this.onTagSubmit}/>}
                {this.props.action === "searchRelatedTags" && <DownloadTagRecommendations app={this.props.app} user={this.props.user} onTagSubmit={this.onTagSubmit}/>}
            </div>
        } else if (offers.length === 0) {
            return <div className="SearchOffers ThemeBackground">Nincs találat!</div>
        } else {
            return <div className="SearchOffers ThemeBackground">Keresés...</div>
        }
    }

}

class SearchLocations extends Component {

    render() {
        return <SearchTags search={this.props.search} action="searchPlaces" app={this.props.app} user={this.props.user}
                           onTagSubmit={this.props.onTagSubmit} onTagSelect={this.props.onTagSelect}/>
    }
}

class SearchCategories extends Component {

    render() {
        return <SearchTags search={this.props.search} action="searchCategories" app={this.props.app} user={this.props.user}
                           onTagSubmit={this.props.onTagSubmit} onTagSelect={this.props.onTagSelect}/>
    }
}

class SearchRelatedTags extends Component {

    render() {
        return <SearchTags search={this.props.search} action="searchRelatedTags" app={this.props.app} user={this.props.user}
                           onTagSubmit={this.props.onTagSubmit} onTagSelect={this.props.onTagSelect}/>
    }
}



export {SearchRelatedTags, SearchLocations, SearchCategories};