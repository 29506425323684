import React, {Component} from 'react';
import Container, {Padding} from "../components/Components";
import Sidebar, {SidebarBlock} from "../sidebar/Sidebar";
import {Link, Redirect} from "react-router-dom";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import ajax from "superagent";
import "./MyMessages.css";
import {toast, ToastContainer} from "react-toastify";
import Moment from "react-moment";
import ShowPostDialog from "./ShowPostDialog";
import {TranslateMessage} from "../i18n/TranslateMessage";


class PageMyMessages extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {posts: null, post: null}
        this.showPost = this.showPost.bind(this);
        this.hidePost = this.hidePost.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.deleteAllPost = this.deleteAllPost.bind(this);
        this.readAllPost = this.readAllPost.bind(this);
    }

    componentDidMount() {
        this.downloadPosts();
    }

    downloadPosts() {
        ajax
            .post(this.props.app.server_url + '/post/list')
            .query({"platform": "react",
                "lang": this.props.app.lang
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                this.setState({posts: res.body.posts});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    showPost(post) {
        this.setState({post: post});
    }

    hidePost() {
        this.setState({post: null});
    }

    deletePost(post) {
        ajax
            .post(this.props.app.server_url + '/post/delete')
            .query({"platform": "react",
                "lang": this.props.app.lang,
                "code": post.code})
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Az üzenetet töröltük.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                this.setState({post: null}, (e)=> {
                    this.downloadPosts();
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    deleteAllPost() {
        ajax
            .post(this.props.app.server_url + '/post/deleteAll')
            .query({"platform": "react",
                "lang": this.props.app.lang,
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Az üzeneteket töröltük.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                this.setState({post: null}, (e)=> {
                    this.downloadPosts();
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    readAllPost() {
        ajax
            .post(this.props.app.server_url + '/post/readAll')
            .query({"platform": "react",
                "lang": this.props.app.lang
            })
            .set('Authorization', 'Bearer ' + this.props.app.access_token)
            .accept('application/json')
            .then(res => {
                toast("Az összes üzenetet megjelöltük olvasottként.", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                this.setState({post: null}, (e)=> {
                    this.downloadPosts();
                });
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    render() {
        const posts = this.state.posts;
        let postsView = null;
        if (posts)
            postsView = posts.map((post) => {
                return <PostItem key={"post" + post.postId} post={post}
                                 onShow={this.showPost}
                                 app={this.props.app} user={this.props.user}/>
            });

        return <div>
            <BarqMeCookies/>
            <Sidebar settings={this.props.settings}>

                <SidebarBlock title="Linkek">
                    <ul>
                        <li><Link to="/my-profile">Profilom</Link></li>
                    </ul>
                </SidebarBlock>

            </Sidebar>
            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <Padding>
                    <h1><Link className="ColorLink" to={"/my-profile"}>Profilom</Link> &gt; Üzeneteim</h1>

                    {(posts != null && posts.length==0) && <p><TranslateMessage id="posts_empty"/></p>}
                    {(posts != null && posts.length>0) && <p><TranslateMessage id="posts_info"/></p>}
                    {(posts != null && posts.length>0) && <p>
                        <button className="barqFrameButton" onClick={this.readAllPost}>Összes megjelölése olvasottként</button>
                        <button className="redFrameButton" onClick={this.deleteAllPost}>Összes törlése</button>
                    </p>}
                    {posts && <div className="PostsContainer">{postsView}</div>}
                </Padding>
                <ShowPostDialog app={this.props.app} user={this.props.user} display={this.state.post != null}
                                postCode={this.state.post?.code}
                                onDelete={this.deletePost}
                                onClose={this.hidePost}/>
                <ToastContainer/>

            </Container>
        </div>
    }
}

const PostItem = ({post, onShow}) => {
    return <div className={"PostItem ThemeBackground HandCursor"} onClick={(e) => onShow(post)}>
        <div className="PostItemAuthorImage">
            <img title="Felhasználói profilkép" alt="Felhasználói profilkép"
                 className="Image" src={post.promoter.imageLogo}/>

        </div>
        <div className="PostItemContent">
            {post.promoter.name}, <Moment format="MM.DD HH:mm">{post.publishedStamp}</Moment> <br/>
            {post.readStamp == null && <b>{post.article.title}</b>}
            {post.readStamp != null && post.article.title}
        </div>
    </div>
}


export default PageMyMessages;