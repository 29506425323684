import React, {Component} from 'react';
import './Login.css';
import Container, {Padding} from "../components/Components";
import {DownloadArticle} from "../article/Article";
import BarqMeCookies from "../BarqMeCookies";
import BarqMeTopbar from "../topbar/Topbar";
import ErrorDialogHandler from "../ErrorDialog";
import {Helmet} from "react-helmet";
import {ArticleParagraphTitleView} from "../article/paragraph/ArticleParagraphTitle";
import ArticleParagraphText from "../article/paragraph/ArticleParagraphText";

class PageLoginForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {userName: '', password: ''};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }


    handleSubmit() {
        this.props.onLogin(this.state.userName, this.state.password);
    }

    handleChangeUserName(event) {
        this.setState({userName: event.target.value});
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    render() {
        return <div>
            <Helmet>
                <title>barq.me | Bejelentkezés</title>
            </Helmet>
            <BarqMeCookies/>

            <BarqMeTopbar editor={false} app={this.props.app} user={this.props.user} home={this.props.home}/>
            <Container app={this.props.app} user={this.props.user}>
                <Padding>
                    <div className={"LoginInfoDialog LoginInfoDialogSize ThemeBackground"}>
                        <img className={"BarqmeBadge"} src={"/BarqMeBadgeModern.png"}/>
                        <div className={"BarqmeInfo"}>
                            <ArticleParagraphTitleView paragraph={{title: "A barq.me üdvözöl!"}} noPadding={true}/>
                            <ArticleParagraphText
                                paragraph={{
                                    text: "Segítünk neked érdekes programokat találni. Célunk, hogy gyorsan és hatékonyan szerezz " +
                                        "tudomást azokról az eseményekről, amelyek érdekelnek téged. Ehhez a barq.me megtanulja, " +
                                        "hogy milyen eseményeket szeretsz és a későbbiekben ezek alapján ajánl neked programokat! "
                                }} noPadding={true}/>

                        </div>
                    </div>
                    <LoginForm register={true} app={this.props.app} onLogin={this.props.onLogin}/>
                </Padding>
            </Container>
            <ErrorDialogHandler/>
        </div>
    }
}

class LoginForm extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {userName: '', password: ''};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }


    handleSubmit(event) {
        this.props.onLogin(this.state.userName, this.state.password);
        event.preventDefault();
    }

    handleChangeUserName(event) {
        this.setState({userName: event.target.value});
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    render() {

        let classes = "LoginDialog ThemeBackground LoginDialogSize";
        return <div className={classes}>

            <ArticleParagraphTitleView paragraph={{title: "Jelentkezz be és kezdj el böngészni"}}/>
            <ArticleParagraphText
                paragraph={{text: "Kérjük a belépéshez add meg a felhasználónevedet és jelszavadat."}}/>

            <input className="field ThemeSolidBackground" type="text" name="userName" value={this.state.userName}
                   placeholder="Felhasználói név" onChange={this.handleChangeUserName}/>
            <input className="field ThemeSolidBackground" type="password" name="password" value={this.state.password}
                   placeholder="Jelszó" onChange={this.handleChangePassword}/>
            <button className="button" onClick={this.handleSubmit}>Bejelentkezés</button>
        </div>
    }

}

export default PageLoginForm;
export {LoginForm};